import { Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Select, Tooltip } from "antd";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { FlexFieldData, FlexFieldModalProps, GenericSelection } from "../IFlexField";
import ConfirmationModal from './ConfirmationModal';
import { useEffect, useState } from "react";
import { selectProps } from "util/props/props"
import { ImageBox } from "components/imageBox/ImageBox";
import { useWatch } from "antd/es/form/Form";

export default function FlexFieldForm({
	form,
	handleSubmit,
	handleSave,
	handleCancel,
	handleModuleSelect,
	isModalVisible,
	isNewFlexField,
	listToSave,
	setListToSave,
	tableData,
	modules,
	flexFieldCodeList,
	filters,
	selectedFlexFieldId,
	selectedRows,
	loadFieldCodeOptions,
	availibleFieldCodeList
}: FlexFieldModalProps) {
	const [isDisabled, setIsDisabled] = useState(false);
	const [availableOrdenations, setAvailableOrdenations] = useState([{ value: 1, lebel: 1 }]);
	const [isConfirmLastLevelVisible, setIsConfirmLastLevelVisible] = useState(false);
	const [isConfirmResourceLinkVisible, setIsConfirmResourceLinkVisible] = useState(false);
	const isInformative: boolean = !!Form.useWatch("informative", form);
	const [detailOptions, setDetailOptions] = useState<GenericSelection[]>([]);
	const flexLink = tableData.find(data => data.isResourceLink);
	const flexOrganization = tableData.find(data => data.isOrganization);
	const isDetailDisabled = !isNewFlexField || tableData.filter(({ informative }) => informative).length === 30

	function getAvailableOrdenations() {
		if (tableData.length > 0) {
			const ordenations = [];
			const flexFields = [...tableData];
			const flexFieldWithHighestOrder = flexFields.sort((a, b) => b.ordenation - a.ordenation)[0]
			const highestOrder = availibleFieldCodeList.length <= 0 ? 1 : (flexFieldWithHighestOrder.ordenation + 1) + listToSave.length;
			for (var i = 1; i < highestOrder + 1; i++) {
				ordenations.push({ value: i, lebel: i });
			}
			if (isNewFlexField && ordenations.length > 0) {
				const selectedOrdenation = ordenations[ordenations.length - 1].value
				form.setFieldsValue({ "ordenation": selectedOrdenation })
			}
			setAvailableOrdenations(ordenations);
		}
	}

	useEffect(() => {
		if (availableOrdenations.length > 0 && isNewFlexField) {
			const selectedOrdenation = availableOrdenations[availableOrdenations.length - 1].value
			form.setFieldsValue({ "ordenation": selectedOrdenation })
		}
	}, [availableOrdenations])

	useEffect(() => {
		let flexFieldsWithInformative: GenericSelection[] = [];

		tableData.forEach(({ description, id, informative }) => {
			if (informative) {
				const stringId: string = id.toString();
				const flexFieldSelected = selectedRows[0];
				const disabled: boolean = !isNewFlexField && flexFieldSelected.details?.some((detailId) => stringId === detailId.toString());
				flexFieldsWithInformative.push({ label: description, value: stringId, disabled });
			}
		})
		setDetailOptions(flexFieldsWithInformative);
	}, [tableData, isModalVisible])

	function removeLineFromList(externalCode: string) {
		const index = listToSave.findIndex(c => c.externalCode === externalCode);
		const updatedCostCategoryList = [...listToSave];
		updatedCostCategoryList.splice(index, 1);
		setListToSave(updatedCostCategoryList);
	}

	function handleEditFlexField(flexField: FlexFieldData) {
		setIsDisabled(true);
		form.setFieldsValue({
			id: flexField.id,
			externalCode: flexField.externalCode,
			description: flexField.description,
			modules: flexField.modules,
			fieldCode: flexField.fieldCode,
			filters: flexField.filters,
			informative: flexField.informative,
			managementSecurity: flexField.managementSecurity,
			isLastLevel: flexField.isLastLevel,
			ordenation: flexField.ordenation,
			details: flexField.details,
			isOrganization: flexField.isOrganization,
			isRequired: flexField.isRequired
		})
		const index = listToSave.indexOf(flexField);
		const updatedListToSave = [...listToSave];
		updatedListToSave.splice(index, 1);
		setListToSave(updatedListToSave);
	}

	function validateExternalCode(value: string) {
		if (value === undefined) return Promise.reject();

		for (let index = 0; index < listToSave.length; index++) {
			const item = listToSave[index];
			if (item.externalCode.toLowerCase() === (value.toLowerCase())) {
				return Promise.reject(new Error(i18n.t("the_external_code_field_cannot_repeat")));
			}
		}

		for (let index = 0; index < tableData.length; index++) {
			const item = tableData[index];
			if (item.externalCode.toLowerCase() === value.toLowerCase()) {
				return Promise.reject(new Error(i18n.t("the_external_code_field_cannot_repeat")));
			}
		}

		return Promise.resolve();
	}

	useEffect(() => {
		if (isModalVisible) {
			loadFieldCodeOptions(listToSave);
			getAvailableOrdenations()
		}
	}, [isModalVisible, listToSave]);

	useEffect(() => {
		if (isModalVisible) {
			getAvailableOrdenations()
		}
	}, [availibleFieldCodeList]);

	function handleChangeIsLastLevel(value) {
		var lastLevel = tableData.filter(data => data.isLastLevel)[0]
		if (lastLevel && form.getFieldValue("isLastLevel") && lastLevel.id != form.getFieldValue("id")) {
			setIsConfirmLastLevelVisible(true);
		}
	}

	const setLastLevel = (value) => {
		form.setFieldsValue({ "isLastLevel": value })
	}

	function handleChangeIsResourceLink() {
		if (!form.getFieldValue("isResourceLink") && selectedRows[0].isResourceLink) {
			setIsConfirmResourceLinkVisible(true);
		}
	}

	const setIsResourceLink = (value) => {
		form.setFieldsValue({ "isResourceLink": value })
	}

	function handleConfirmOk() {
		setIsResourceLink(false);
		setIsConfirmResourceLinkVisible(false);
	}

	function handleConfirmCancel() {
		setIsResourceLink(true);
		setIsConfirmResourceLinkVisible(false);
	}

	return (
		<Modal
			width="70%"
			title={isNewFlexField ? i18n.t("flexField.new_flexible_field") : i18n.t("flexField.edit_flexible_field")}
			open={isModalVisible}
			okButtonProps={listToSave.length > 0 || !isNewFlexField ? { htmlType: "submit", form: "flex-field-form" } : { disabled: true }}
			onCancel={() => {
				setIsDisabled(false)
				handleCancel();
			}}
			onOk={() => {
				setIsDisabled(false)
				if (isNewFlexField) {
					handleSave(listToSave);
				}
			}}
			className="gs-modal flex-field-modal-container"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			centered
		>
			<ConfirmationModal
				isModalVisible={isConfirmLastLevelVisible}
				tableData={tableData}
				setConfirmLastLevel={setLastLevel}
				setIsModalVisible={setIsConfirmLastLevelVisible}
				customMessage={false}
			/>

			<ConfirmationModal
				isModalVisible={isConfirmResourceLinkVisible}
				tableData={tableData}
				setConfirmLastLevel={setIsResourceLink}
				customMessage={true}
				setIsModalVisible={setIsConfirmResourceLinkVisible}
				message={"Ao alterar este campo, os vínculos de conta de RH serão excluídos, tem certeza que deseja continuar?"}
				handleConfirmOk={handleConfirmOk}
				handleConfirmCancel={handleConfirmCancel}
			/>

			<Form
				form={form}
				name="flex-field-form"
				onFinish={(data) => {
					setIsDisabled(false)
					handleSubmit([data])
				}}
				layout="vertical"
				requiredMark={false}
			>
				<div className="first-container-input-content">
					<div className="first-input-container">
						<Form.Item
							label={i18n.t("external_code")}
							name="externalCode"
							rules={[{ required: true, message: i18n.t("required_field"), },
							() => ({
								validator(_, value: string) {
									return isNewFlexField ? validateExternalCode(value) : Promise.resolve();
								}
							}),
							]}
						>
							<Input id="flex-field-external-code" maxLength={250} />
						</Form.Item>
					</div>
					<div className="first-input-container">
						<Form.Item
							label={i18n.t("description")}
							name="description"
							rules={[{ required: true, message: i18n.t("required_field") }]}
						>
							<Input id="flex-field-description" />
						</Form.Item>
					</div>
					<div className="first-input-container">
						<Form.Item
							label={i18n.t<string>("order")}
							name="ordenation"
							rules={[{ required: true, message: i18n.t("required_field") }]}
						>
							<Select
								id="flex-ordination"
								options={availableOrdenations}
							/>
						</Form.Item>
					</div>
				</div>
				<div className="inputs-container-row">
					{!isInformative &&
						<Row gutter={8} className="input-selects-content">
							<Col span={8}>
								<Form.Item
									label={i18n.t("flexField.fieldCode")}
									name="fieldCode"
									rules={[{ required: true, message: i18n.t("required_field") }]}
								>
									<Select
										id="flex-field-fieldcode"
										disabled={!isNewFlexField}
										options={availibleFieldCodeList}
										{...selectProps}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label={i18n.t("flexField.module")}
									name="modules"
								>
									<Select
										id="flex-field-module"
										mode="multiple"
										options={modules}
										maxTagCount="responsive"
										onChange={handleModuleSelect}
										{...selectProps}
									></Select>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item
									label={i18n.t("flexField.linked_filters")}
									name="filters"
								>
									<Select
										id="flex-field-filter"
										mode="multiple"
										disabled={!isNewFlexField}
										options={filters.filter(({ value }) => value !== selectedFlexFieldId[0]?.toString() && !tableData.find(({ id }) => id === Number(value))?.informative)}
										maxTagCount="responsive"
										{...selectProps}
									></Select>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item
									label={i18n.t("detail")}
									name="details"
								>
									<Select
										id="details"
										options={detailOptions}
										mode="multiple"
										{...selectProps}
									/>
								</Form.Item>
							</Col>
						</Row>
					}
					<div className="footer-container">
						<div className="inputs-container-checkbox">
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-management-security-form-item" name="managementSecurity" valuePropName="checked">
										<Checkbox disabled={isInformative} id="flex-field-management-security" />
									</Form.Item>
									<label htmlFor="flex-field-management-security">{i18n.t("flexField.managementSecurity")}</label>
								</div>
							</div>
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-informative-form-item" name="informative" valuePropName="checked">
										<Checkbox disabled={isDetailDisabled} id="flex-field-informative"
											onChange={(e) => {
												if (e.target.checked) {
													form.setFieldsValue({ isRequired: false });
												}
											}}
										/>
									</Form.Item>
									<label htmlFor="flex-field-informative">{i18n.t("detail")}</label>
								</div>
							</div>
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-is-last-level-form-item" name="isLastLevel" valuePropName="checked">
										<Checkbox disabled={isInformative} id="flex-field-is-last-level" onChange={handleChangeIsLastLevel} />
									</Form.Item>
									<label htmlFor="flex-field-is-last-level">{i18n.t("flexField.isLastLevel")}</label>
								</div>
							</div>
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-is-last-level-form-item" name="isResourceLink" valuePropName="checked">
										<Checkbox id="flex-field-is-resource-link" disabled={flexLink || isInformative ? flexLink?.id !== Number(selectedFlexFieldId) : false} onChange={handleChangeIsResourceLink} />
									</Form.Item>
									<label htmlFor="flex-field-is-resource-link">{i18n.t("isResourceLink")}</label>
								</div>
							</div>
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-is-last-level-form-item" name="isOrganization" valuePropName="checked">
										<Checkbox id="flex-field-is-organization" disabled={flexOrganization || isInformative ? flexOrganization?.id !== Number(selectedFlexFieldId) : false} />
									</Form.Item>
									<label htmlFor="flex-field-is-organization">{i18n.t("organization")}</label>
								</div>
							</div>
							<div>
								<div className="input-content-checkbox-container">
									<Form.Item id="flex-field-is-last-level-form-item" name="isRequired" valuePropName="checked">
										<Checkbox id="flex-field-is-required"  disabled={form.getFieldValue("informative")} />
									</Form.Item>
									<label htmlFor="flex-field-is-required">{i18n.t("required")}</label>
								</div>
							</div>
						</div>
						{
							isNewFlexField &&
							<Button
								icon={<Icon icon="akar-icons:circle-plus-fill" />}
								htmlType="submit"
								className="gs-secondary-button"
							>
								{i18n.t<string>("addToList")}
							</Button>
						}
					</div>
				</div>
			</Form>
			{
				isNewFlexField &&
				<div className="flex-field-list-container">
					{
						listToSave.length > 0
							?
							<>
								<div className="flex-field-list-header">
									<Row align="middle" gutter={2}>
										<Col span={4}>{i18n.t("external_code")}</Col>
										<Col span={4}>{i18n.t("description")}</Col>
										<Col span={5}>{i18n.t("flexField.module")}</Col>
										<Col span={5}>{i18n.t("flexField.fieldCode")}</Col>
										<Col span={4}>{i18n.t("filter")}</Col>
										<Col span={1} />
										<Col span={1} />
									</Row>
								</div>
								<div className="flex-field-list-content">
									{
										listToSave.map((item) => {
											const moduleList = item.modules?.length ? modules.filter((mod) => item.modules.includes(mod.value)) : [];
											const filterList = item.filters?.length ? filters.filter((filter) => item.filters?.includes(filter.value)) : [];
											return (
												<Row style={{ paddingLeft: 10 }} align="middle" key={item.externalCode} gutter={2}>
													<Col span={4}>{item.externalCode}</Col>
													<Col span={4}>{item.description}</Col>
													<Col span={5}>
														{item.modules?.length &&
															modules.filter((mod) => mod.value === item.modules[0]).map((mod) => mod.label)
														}
														{
															item.modules?.length > 1 &&
															<Tooltip
																arrowPointAtCenter
																color={'rgba(0,0,0,0.9'}
																placement="right"
																title={moduleList.map(mod => <p style={{ margin: 0 }}>{i18n.t(mod.label)}</p>)}
															>
																<span className="additional-items">{`+${item.filters?.length - 1}`}</span>
															</Tooltip>
														}
													</Col>
													<Col span={5}>{flexFieldCodeList.find(field => field.value === item?.fieldCode)?.label}</Col>
													<Col span={4}>
														{
															filters.filter((fil) => item.filters && fil.value === item.filters[0]?.toString()).map((fil) => fil.label)
														}
														{
															item.filters?.length > 1 &&
															<Tooltip
																color={'rgba(0,0,0,0.9'}
																placement="right"
																title={filterList.map(fil => <p style={{ margin: 0 }}>{i18n.t(fil.label)}</p>)}
															>
																<span className="additional-items">{`+${item.filters?.length - 1}`}</span>
															</Tooltip>
														}
													</Col>
													<Col span={1} style={{ justifyContent: 'flex-end' }}>
														<Button
															disabled={isDisabled}
															onClick={() => handleEditFlexField(item)}
															icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
														/>
													</Col>
													<Col span={1} style={{ justifyContent: 'center' }}>
														<Icon
															className="delete-icon"
															onClick={() => removeLineFromList(item.externalCode)}
															icon="fa6-solid:trash"
														/>
													</Col>
												</Row>
											);
										})}
								</div>
							</>
							:
							<ImageBox styles={{ paddingTop: 25 }} />
					}
				</div>
			}
		</Modal >
	);
}