import {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import {
	MessageType,
	RequestType
} from "util/service/IServiceCaller";
import {
	UserInfo,
	UserSelection,
	UserContextData,
	UserProviderProps,
	ILocationFragment
} from "./IUserContext";

const userContext = createContext<UserContextData>({} as UserContextData);
export function UserProvider({ children }: UserProviderProps) {
	const [isBudgetModule, setIsBudgetModule] = useState(false)
	const [isSelectionScenarioModalOpen, setIsSelectionScenarioModalOpen] = useState(false)
	const [isEnvironmentModalOpen, setIsEnvironmentModalOpen] = useState(false)
	const [isStepModalOpen, setIsStepModalOpen] = useState(false)
	const [isMainMenuOpen, setIsMainMenuOpen] = useState(false)
	const [isFloatMenuOpen, setIsFloatMenuOpen] = useState(false)
	const openMenuBtnRef = useRef(null);
	const menuSearchRef = useRef(null);

	const [userInfo, setUserInfo] = useState<UserInfo>({
		id: null,
		clientId: null,
		name: "",
		languageTag: "pt-BR",
		localeId: null,
		currencyId: null,
		isLeaseProjectionModalEnabled: false,
		groupIds: [],
		selection: {
			scenarioId: null,
			scenarioName: "",
			organizationId: null,
			organizationName: "",
			businessUnitId: null,
			businessUnitName: "",
			year: 0
		},
		countryOrigin: null,
		plan: "",
		npsSurveyLink: ""
	});

	useEffect(() => {
		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/user/info"
		}, onLoadUserInfo.bind(this));

		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/menu/location-fragments"
		}, onLoadFragments);
	}, [])

	function setSelection(selection: UserSelection) {
		setUserInfo({
			...userInfo,
			selection: {
				...selection
			}
		});
	}

	function changeModal() {
		if (isBudgetModule) {
			setIsSelectionScenarioModalOpen(prevState => !prevState)
		} else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.CHANGE_SCENERY,
			});
		}
	};

	function changeEnvironmentModal() {
		if (isBudgetModule) {
			setIsEnvironmentModalOpen(prevState => !prevState)
		}
		else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.CHANGE_ENVIRONMENT_MODAL
			})
		}
	};

	function changeStepModal() {
		if (isBudgetModule) {
			setIsStepModalOpen(prevState => !prevState)
		}
		else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.CHANGE_STEP_MODAL
			})
		}
	};

	function onLoadUserInfo(data: UserInfo) {
		setUserInfo(data);
	};

	function onLoadFragments(fragments: ILocationFragment[]) {
		if(fragments.length > 0) {
			const isBudget = fragments[fragments.length - 1].link.includes('/mf/') || fragments[fragments.length - 1].link.includes('/module')
			setIsBudgetModule(isBudget)
		}
	};

	return (
		<userContext.Provider value={{
			changeModal,
			changeEnvironmentModal,
			isEnvironmentModalOpen,
			setSelection,
			userInfo,
			isBudgetModule,
			isSelectionScenarioModalOpen,
			isMainMenuOpen, 
			setIsMainMenuOpen, 
			isFloatMenuOpen, 
			setIsFloatMenuOpen,
			onLoadUserInfo,
			openMenuBtnRef,
			menuSearchRef,
			isStepModalOpen,
			changeStepModal,
		}}>
			{children}
		</userContext.Provider>
	)
}

export function useUserContext() {
	return useContext(userContext)
};
