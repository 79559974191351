import { 
    useEffect, 
    useState 
} from "react";

import { Icon } from "@iconify/react";
import { Button } from "antd";
import { MessageType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

export default function NpsButton({ userInfo }) {
	const [showButton, setShowButton] = useState<boolean>(false);

	useEffect(() => {
		setShowButton(!!userInfo.npsSurveyLink?.trim());
	}, [userInfo]);

	const clearNpsStatus = () => {

		try {
			ServiceCaller.doSendMessage({
                messageType: MessageType.REQUEST_NPS
			});

			setShowButton(false);
			window.open(userInfo.npsSurveyLink, "_blank", "noopener,noreferrer");
		} catch (error) {
			console.error(error);
		}
	};

	return showButton ? (
		<Button id="nps-button" onClick={clearNpsStatus}>
			<Icon icon="pepicons-pop:question" />
		</Button>
	) : null;
}
