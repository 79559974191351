import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from 'hooks/useCachedQuery';
import { RequestType } from "util/service/IServiceCaller";

type AccountAccountingLinkedOptions = {
    canEdit: boolean;
    value: number;
    label: string;
    lastSelected?: boolean;
};

export function useLinkedAccountingAccountOptions(costCenterId = '', queryProps = {} as UseQueryOptions<AccountAccountingLinkedOptions[]>) {
    const queryInfo = useCachedQuery<AccountAccountingLinkedOptions[]>(
        ['account-accounting-options', costCenterId.toString()],
        {
            type: RequestType.GET,
            url: `/expenses/accounting-account/find-all-by-cost-center-account-parameterization?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}${costCenterId ? `&costCenter=${costCenterId}` : ''}`,
        },
        {
            enabled: !!costCenterId,
        },
        (data: { id: number; name: string; lastSelected: boolean, canEdit: boolean }[]) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected, canEdit: item.canEdit }));
        }
    );

    return queryInfo;
}