import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { useNewReportContext } from "../context/ReportRegistrationContext";

export default function ReportRegistrationTable() {
    const { selectedRowKeys, isFetching, tableData, isNewProjection, setSelectedRowKeys } = useNewReportContext();

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys) => setSelectedRowKeys(keys),
    };

    const columns: ColumnsType = [
        {
            title: i18n.t("description"),
            width: "90%",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
		<main>
			<>
				{!isNewProjection && (
					<Table
						columns={columns}
						pagination={{ hideOnSinglePage: true, pageSize: 15 }}
						bordered
						loading={{
							spinning: isFetching,
							tip: `${i18n.t<string>("loading")}...`,
						}}
						className="gs-table"
						rowSelection={rowSelection}
						dataSource={tableData}
						rowKey={"id"}
					/>
				)}
			</>
		</main>
    );
}
