import { ITableData, ITableDataAccountingLedger } from "../IAccountingLedger";

export default function buildGridData(item: ITableData, isReorder: boolean = false): ITableDataAccountingLedger {

    const newLine: ITableDataAccountingLedger = buildLine(item, isReorder);

    if (item.accountHierarchy && item.accountHierarchy.length > 0) {
        item.accountHierarchy.forEach(({ description }, index) => newLine[`accountHierarchy-${index}`] = description);
    }

    if (item.costCenterHierarchy && item.costCenterHierarchy.length > 0) {
        item.costCenterHierarchy.forEach(({ description }, index) => newLine[`costCenterHierarchy-${index}`] = description);
    }

    if (item.flexFieldValueItems && item.flexFieldValueItems.length > 0) {
        item.flexFieldValueItems.forEach(({ description, externalCode }, index) => newLine[`flexFieldValueItens-${index}`] = `${externalCode} - ${description}`);
    }

    return newLine;
}

function buildLine(item: any, isReorder: boolean = false): ITableDataAccountingLedger {

    if (isReorder && item.children && item.children.length > 0) {
        return {
            description: item.description || '',
            key: item.description + " - " + Math.random().toString(),
            children: item.children.map(itemNew => buildGridData(itemNew, true)),
            groupColumn: item.groupColumn || '',
        }
    }

    return {
        description: item.description || '-',
        key: item.openingId ? item.openingId.toString() + " - " + Math.random().toString() : '-' + " - " + Math.random().toString(),
        creationDate: item.creationDate || '-',
        organization: item.organization?.name || '-',
        costCenter: item.costCenter?.name || '-',
        accountingAccount: item.accountingAccount?.name || '-',
        bussinesUnit: item.businessUnit?.name || '-',
        beneficiarySupplier: item.beneficiary ? `${item.beneficiary.externalCode} - ${item.beneficiary.name}` : null,
        productGroup: item.productGroup ? `${item.productGroup.externalCode} - ${item.productGroup.name}` : null,
        invoice: item.invoice || '-',
        serie: item.serie || '-',
        entryValue: item.entryValue?.toString() || '-',
        launchCode: item.entryId || '-',
        launch: item.entryDescription || '-',
        batch: item.batch || '-',
        children: item.children || null,
    };

}