import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import i18n from "util/base/i18n";
import { IDatePickerDefaultProps, IValuePeriodTableData } from "../../revenue/recurringContract/IRecurringContract";
import { Icon } from "@iconify/react";
import { FiltersTab } from "./FiltersTab";
import { IDistributionRuleData, IDistributionRuleForm, IDistributionRuleGrouper, IMonthValueResponse } from "../IDistributionRule";
import { useEffect, useMemo } from "react";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { IOption } from "../../revenue/attributeParameterization/IAttributeParameterization";
import ValuePeriodTable from "./ValuePeriodTable";

export default function DistributionRuleModal() {
	const defaultDatePickerProps: IDatePickerDefaultProps = {
		picker: "month",
		className: "replicate-scenario-month",
		suffixIcon: <Icon icon="fa6-solid:calendar" />,
		allowClear: true,
		format: "MMM/YYYY"
	}

	const [form] = Form.useForm<IDistributionRuleForm>();
	const startDateSelected = Form.useWatch<moment.Moment>('startDate', form);
	const finalDateSelected = Form.useWatch<moment.Moment>('endDate', form);
	const {
		isModalOpen,
		isSaving,
		setIsSaving,
		setIsModalOpen,
		setRuleForEdit,
		ruleForEdit,
		ruleConditionFilter,
		ruleConditionResult,
		setRuleConditionFilter,
		setRuleConditionResult,
		getTableData,
		hasEdited,
		setHasEdited,
		distributionGroupers,
		monthValuesData
	} = useDistributionRuleContext();
	const { userInfo: { id, selection: { scenarioId, businessUnitId, organizationId } } } = useUserContext();
	const groupersOptions: IOption[] = useMemo(() => {
		if (!distributionGroupers) return [];
		return Object.keys(distributionGroupers).map((key) => {
			const grouper: IOption = {
				value: key,
				label: distributionGroupers[key]
			}
			return grouper;
		})
	}, [distributionGroupers])

	const isOkDisabled: boolean = useMemo(() => !(ruleConditionFilter.length && ruleConditionResult.length && hasEdited), [ruleConditionFilter, ruleConditionResult, hasEdited]);
	
	const nextOrder = localStorage.getItem("nextOrder") ? Number(localStorage.getItem("nextOrder")) : 1;

	useEffect(() => {
		if (!ruleForEdit) return;

		form.setFieldsValue({
			...ruleForEdit,
			startDate: moment(ruleForEdit.startDate),
			endDate: moment(ruleForEdit.endDate),
		});
		setIsModalOpen(true);
	}, [ruleForEdit])

	const handleCloseModal = () => {
		setIsModalOpen(false);
		form.resetFields();
		setRuleForEdit(null);
		setIsSaving(false);
		setRuleConditionFilter([]);
		setRuleConditionResult([]);
		setHasEdited(false);
	}

	const disableDate = (date: moment.Moment, isInitialDate: boolean = true) => {
		const isDisable = isInitialDate
			? finalDateSelected && date.isSameOrAfter(finalDateSelected)
			: startDateSelected && date.isSameOrBefore(startDateSelected);
		return isDisable;
	}

	const handleSave = (formValues: IDistributionRuleForm) => {
		setIsSaving(true);
		const startDate: string = moment(formValues.startDate).startOf('month').format("YYYY-MM-DD");
		const endDate: string = moment(formValues.endDate).endOf('month').format("YYYY-MM-DD");
		const ruleConditionToSave: IDistributionRuleData = {
			...formValues,
			filters: ruleConditionFilter,
			results: ruleConditionResult,
			startDate,
			endDate,
			scenarioId,
			businessUnitId,
			organizationId,
			updatedByUserId: id,
			id: ruleForEdit?.id,
			grouper: IDistributionRuleGrouper[formValues.grouper],
			values: buildValuesByMonthValues(monthValuesData)
		}
		const requestType: RequestType = ruleForEdit ? RequestType.PUT : RequestType.POST
		
		ServiceCaller.doRequest({
			type: requestType,
			url: "/software-house/distribution-rule",
			params: ruleConditionToSave
		}, onSave, handleErrorRequest)
	}

	const onSave = () => {
		Notification({
			message: i18n.t("successfully_saved"),
			type: "success"
		})
		handleCloseModal();
		getTableData();
	}

	const onChangeFieldForm = () => {
		setHasEdited(true);
	}

	const buildValuesByMonthValues = (tableData: IValuePeriodTableData[]): IMonthValueResponse[] => {
		if (!tableData || tableData.length === 0) return [];

		const [monthValue] = tableData;
		return Object.entries(monthValue)
			.filter(([key]) => key.includes("/"))
			.map(([key, value]) => ({
				percentageValue: Number(value),
				period: moment(key).startOf('month').format("YYYY-MM-DD"),
			}));
	};

	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCloseModal}
			title={i18n.t("distribution_rules.title")}
			okButtonProps={{
				className: "gs-main-button",
				loading: isSaving,
				htmlType: "submit",
				form: "rule-modal-form",
				disabled: isOkDisabled
			}}
			okText={i18n.t<string>("save")}
			cancelButtonProps={{ type: "text" }}
			cancelText={i18n.t<string>("close")}
			className="gs-modal rule-distribution-modal attribute_parameterization-new-rule-modal"
			centered
			width={"100%"}
			maskClosable={false}
			keyboard={false}
		>
			<Form
				form={form}
				name="rule-modal-form"
				layout="vertical"
				onFinish={handleSave}
				onValuesChange={onChangeFieldForm}
			>
				<Row gutter={10}>
					<Col span={4}>
						<Form.Item
							label={i18n.t("external_code")}
							name="externalCode"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Input 
								maxLength={50}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label={i18n.t("description")}
							name="description"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Input 
								maxLength={255}
							/>
						</Form.Item>
					</Col>
					<Col span={2}>
						<Form.Item
							label={i18n.t("order")}
							name="order"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							initialValue={nextOrder}
						>
							<InputNumber
								min={1}
							/>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							label={i18n.t("grouper")}
							name="grouper"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							initialValue={"SOFTWARE_HOUSE"}
						>
							<Select options={groupersOptions} />
						</Form.Item>
					</Col>
					<Col span={2}>
						<Row className="checkbox-content">
							<Form.Item
								valuePropName="checked"
								name={"planned"}>
								<Checkbox>
									{i18n.t("planned")}
								</Checkbox>
							</Form.Item>
						</Row>
						<Row className="checkbox-content">
							<Form.Item
								valuePropName="checked"
								name={"accomplished"}>
								<Checkbox>
									{i18n.t("realized")}
								</Checkbox>
							</Form.Item>
						</Row>
					</Col>
					<Col span={8} className="date-content">
						<Row gutter={10}>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.beginning_term")}
									name={"startDate"}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<DatePicker
										{...defaultDatePickerProps}
										disabledDate={date => disableDate(date)}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.end_term")}
									name={"endDate"}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<DatePicker
										{...defaultDatePickerProps}
										disabledDate={date => disableDate(date, false)}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.multiplier")}
									name={"multiplier"}
									initialValue={1}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			<Row>
				<Col span={24}>
					<ValuePeriodTable
						initialDate={startDateSelected}
						finalDate={finalDateSelected}
					/>
				</Col>
			</Row>
			<Row >
				<Col id="attribute_parameterization-new-rules-modal-rules-section" span={24}>
					<FiltersTab />
				</Col>
			</Row>
		</Modal>
	)
}