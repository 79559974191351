import { FormInstance } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { IValuePeriodTableData } from "../revenue/recurringContract/IRecurringContract";
import { PaginationResponse } from "util/types/types";
import { TablePaginationConfig } from "antd/es/table";

export interface IDistributionRuleContext {
	tableData: IDistributionRuleData[];
	setTableData: React.Dispatch<React.SetStateAction<IDistributionRuleData[]>>;
	isTableDataLoading: boolean;
	setIsTableDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isSaving: boolean;
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
	ruleForEdit: IDistributionRuleData;
	setRuleForEdit: React.Dispatch<React.SetStateAction<IDistributionRuleData>>;
	ruleFilterOptions: IOptionRuleFilter[];
	setRuleFilterOptions: React.Dispatch<React.SetStateAction<IOptionRuleFilter[]>>;
	selectedTab: "filter" | "result";
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
	isOptionsLoading: boolean;
	setIsOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	filtersForm: FormInstance<IFilterFormValues>;
	ruleConditionFilter: IDistributionRuleFilter[];
	setRuleConditionFilter: React.Dispatch<React.SetStateAction<IDistributionRuleFilter[]>>;
	ruleConditionResult: IDistributionRuleResultFilter[];
	setRuleConditionResult: React.Dispatch<React.SetStateAction<IDistributionRuleResultFilter[]>>;
	rowSelection: TableRowSelection<IDistributionRuleData>;
	selectedRowKeys: number[];
	hasEdited: boolean;
	setHasEdited: React.Dispatch<React.SetStateAction<boolean>>;
	distributionGroupers: Record<IDistributionRuleGrouper, string>;
	selectedRows: IDistributionRuleData[];
	setSelectedRows: React.Dispatch<React.SetStateAction<IDistributionRuleData[]>>;
	monthValuesData: IValuePeriodTableData[];
	setMonthValuesData: React.Dispatch<React.SetStateAction<IValuePeriodTableData[]>>;
	distributionRuleTypeSelected: IDistributionRuleGrouper;
	setDistributionRuleTypeSelected: React.Dispatch<React.SetStateAction<IDistributionRuleGrouper>>;
	searchType: ISearchType;
	setSearchType: React.Dispatch<React.SetStateAction<ISearchType>>;
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	currentPageSize: number;
	setCurrentPageSize: React.Dispatch<React.SetStateAction<number>>;
	totalItems: number;
	setTotalItems: React.Dispatch<React.SetStateAction<number>>;
	pagination: TablePaginationConfig;
	getTableData: (page?: number, pageSize?: number, currentSearch?: string, currentSearchType?: ISearchType, currentRuleType?: string) => Promise<void>;
}

export interface IDistributionRuleData {
	businessUnitId?: number;
	organizationId?: number;
	updatedByUserId?: number;
	id?: number;
	scenarioId?: number;
	lastUserChangeName?: string;
	externalCode : string;
	description?: string;
	order?: number;
	planned?: boolean;
	accomplished?: boolean;
	multiplier?: number;
	startDate?: number | string;
	endDate?: number | string;
	lastUserChangeDate?: string;
	filters?: IDistributionRuleFilter[];
	results?: IDistributionRuleResultFilter[];
	grouper?: IDistributionRuleGrouper;
	children?: IDistributionRuleData[];
	grouperBy?: string;
	values?: IMonthValueResponse[];
}

export interface IMonthValueResponse {
	period: string;
	percentageValue: number | string;
	id?: number;
}

export interface ICommomDistributionRuleFilter {
	distributionRuleId?: number;
	filterOrder: number;
	fieldId: number;
	fieldValueId: number;
	operation: OperationType;
	fieldValueName: string;
	fieldName: string;
	type?: FilterType | FilterResultType;
}

export interface IDistributionRuleFilter extends ICommomDistributionRuleFilter {
	filter: FilterType
}

export enum OperationType {
	PLUS = "PLUS",
	LESS = "LESS",
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS"
}

export enum FilterType {
	FLEX_FIELD = "FLEX_FIELD",
	COST_CENTER = "COST_CENTER",
	ACCOUNTING_ACCOUNT = "ACCOUNTING_ACCOUNT",
	ACCOUNT_LEVEL = "ACCOUNT_LEVEL",
	COST_CENTER_LEVEL = "COST_CENTER_LEVEL",
	FLEX_FIELD_DETAIL = "FLEX_FIELD_DETAIL"
}

export enum FilterResultType {
	FLEX_FIELD = "FLEX_FIELD",
	COST_CENTER = "COST_CENTER",
	ACCOUNTING_ACCOUNT = "ACCOUNTING_ACCOUNT",
}

export interface IDistributionRuleResultFilter extends ICommomDistributionRuleFilter {
	result: FilterResultType
}

export interface IOptionRuleFilter {
	value: string;
	label: string;
	filter: FilterType;
}

export interface IOptionRuleResult {
	value: string;
	label: string;
	filter: FilterResultType;
}

export interface IFilterFormValues {
	fieldId: string;
	fieldValueId: number | number[];
	operation: OperationType;
}

export interface ICalculateRulesFormValues {
	startPeriod: string;
	endPeriod: string;
	planned: boolean;
	accomplished: boolean;
}

export interface IAccountNomenclatureLink {
	nomenclature: string;
	id: number;
}

export interface IAccountLevelResponse {
	[id: number]: string;
}

export interface IDistributionRuleForm {
	externalCode: string;
	description: string;
	order: number;
	planned: boolean;
	accomplished: boolean;
	startDate: moment.Moment;
	endDate: moment.Moment;
	multiplier: number;
	grouper: string;
}

export enum IDistributionRuleGrouper {
	SOFTWARE_HOUSE = "SOFTWARE_HOUSE",
	COMISSIONS = "COMISSIONS",
	TAX_COST = "TAX_COST",
	UNIT_ADJUSTMENT = "UNIT_ADJUSTMENT"
}

export enum ISearchType {
	DESCRIPTION = "DESCRIPTION",
	EXTERNAL_CODE = "EXTERNAL_CODE"
}
export interface IDistributionRuleResponse extends PaginationResponse {
	content: IDistributionRuleData[];
}