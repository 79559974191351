import i18n from "util/base/i18n";
import { useNewReportContext } from "../context/ReportRegistrationContext";

export default function ReportRegistrationTitle() {
    const { isNewProjection } = useNewReportContext();

    return (
        <>
            {!isNewProjection && (
                <div className="header-content">
                    <div className="page-title-content" style={{ maxHeight: 40, padding: 0 }}>
                        <h1>{i18n.t<string>("create_report_structure")}</h1>
                    </div>
                </div>
            )}
        </>
    );
}
