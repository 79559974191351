import { Col, DatePicker, Row, Space } from 'antd'
import { useAccountingBalancesContext } from '../context/AccountingBalancesContext';
import i18n, { languages } from 'util/base/i18n';
import BalanceDetailTable from './BalanceDetailTable';
import moment from "moment";
import { useEffect, useState } from 'react';
import { IBalanceMovementsDetail } from '../IAccountingBalances';

export default function BalanceMovementsDetail({
    detailTableData,
    loadingDetailTable,
    monthDetail,
    setMonthDetail
}: IBalanceMovementsDetail) {

    const { period } = useAccountingBalancesContext();

    const disabledDate = (date: moment.Moment) => {
        const [start, end] = period;
        return !date.isBetween(start, end, "month", "[]");
    };

    useEffect(() => {
        setMonthDetail(period[0]);
    }, [period]);

    return (
        <div>
            <Row wrap={false} align="middle" gutter={[0, 30]}>
                <div className="filter-container">
                    <div style={{ display: "flex" }}>
                        <Col className="period-filter-card">
                            <div
                                className="open-filter-button"
                            >
                                <Space direction="vertical" size={0}>
                                    <p className="filter-name">{i18n.t("period")}</p>

                                    <DatePicker
                                        onChange={value => setMonthDetail(value)}
                                        value={monthDetail}
                                        disabledDate={disabledDate}
                                        locale={languages[i18n.language]}
                                        format={"MM/YYYY"}
                                        suffixIcon={null}
                                        picker={'month'}
                                        allowClear={false}
                                    />
                                </Space>
                                <span></span>
                            </div>
                        </Col>
                    </div>
                </div>
            </Row >
            <BalanceDetailTable
                detailTableData={detailTableData}
                loadingDetailTable={loadingDetailTable}
            />
        </div >
    )
}
