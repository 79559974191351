import { Icon } from '@iconify/react';
import { Button, Checkbox, Input, Layout, Menu, Space, Spin } from "antd";
import { Notification } from 'components/notification/Notification';
import { usePermissionContext } from "context/PermissionContext";
import { useUserContext } from "context/UserContext";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { invalidadeQuery } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { DefaultPermissions, FlexFieldMenuItem, IFlexFieldValuesResponse, IPermissionSecurityArea, MenuKey, OrganizationFiscalYear, PermissionItem, PermissionSecurityData, TableData } from "../IPermissionSecurity";
import { PermissionSecurityContent } from "./PermissionSecurityContent";

const { Search } = Input;
const { Sider, Footer } = Layout;

export function PermissionSecurityArea({ selectedUser, selectedTab, selectedValue, setSelectedValue }: IPermissionSecurityArea) {
	const [isCostCenterLinked, setIsCostCenterLinked] = useState<boolean>(true);
	const [searchValue, setSearchValue] = useState("");
	const [costCenterPermissions, setCostCenterPermissions] = useState<PermissionSecurityData[]>([]);
	const [accountingPermissions, setAccountingPermissions] = useState<PermissionSecurityData[]>([]);
	const [fiscalYearPermissions, setFiscalYearPermissions] = useState<PermissionSecurityData[]>([]);
	const [salesHierarchyPermissions, setSalesHierarchyPermissions] = useState<PermissionSecurityData[]>([]);
	const [selectedPermissions, setSelectedPermissions] = useState<PermissionSecurityData[]>([]);
	const [contractTypePermission, setContractTypePermission] = useState<PermissionSecurityData[]>([]);
	const [isFetchingFiscalYear, setIsFetchingFiscalYear] = useState(true);
	const [isFetchingCostCenters, setIsFetchingCostCenters] = useState(true);
	const [isFetchingContractType, setIsFetchingContractType] = useState(true);
	const [isFetchingAccountingAccounts, setIsFetchingAccountingAccounts] = useState(false);
	const [isFetchingSelectedPermissions, setIsFetchingSelectedPermissions] = useState(true);
	const [organizationOptions, setOrganizationOptions] = useState<Options[]>([]);
	const [isFetchingItems, setIsFetchingItems] = useState(true);
	const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(false);
	const [selectedPermissionItem, setSelectedPermissionItem] = useState<PermissionItem>({} as PermissionItem);
	const [selectedMenuKey, setSelectedMenuKey] = useState<MenuKey>(MenuKey.FISCALYEAR);
	const [isSavingPermission, setIsSavingPermission] = useState(false);
	const [isMenuDisabled, setIsMenuDisabled] = useState(false);
	const [costCenterSelectedValue, setCostCenterSelectedValue] = useState(0);
	const [initialStatePermission, setInitialStatePermission] = useState<PermissionSecurityData[]>([]);
	const menuKeyArray = [MenuKey.COSTCENTER, MenuKey.ACCOUNT, MenuKey.FISCALYEAR]
	const [tableData, setTableData] = useState<TableData[]>([]);
	const { functionalityPermissions } = usePermissionContext();
	const [replicatePermissionsToAllBusinessUnit, setReplicatePermissionsToAllBusinessUnit] = useState(true);
	const [isFlexFields, setIsFlexFields] = useState(true);
	const [menuItems, setMenuItems] = useState([
		{ key: MenuKey.FISCALYEAR, label: i18n.t<string>("fiscal_year") },
		{ key: MenuKey.COSTCENTER, label: i18n.t<string>("budget.permissions.cost_center") },
		{ key: MenuKey.ACCOUNT, label: i18n.t<string>("budget.permissions.accounting") },
		{ key: MenuKey.SALESHIERARCHY, label: i18n.t<string>("sales_hierarchy") },
		{ key: MenuKey.CONTRACT, label: i18n.t<string>("contract_type") },
	]);
	const { userInfo } = useUserContext()

	const permissions: DefaultPermissions = {
		account: {
			key: 'accountingAccountId',
			permissions: accountingPermissions,
			setPermissions: setAccountingPermissions,
			setIsFetching: setIsFetchingAccountingAccounts,
			isFetching: isFetchingAccountingAccounts,
		},
		costCenter: {
			key: 'costCenterId',
			permissions: costCenterPermissions,
			setPermissions: setCostCenterPermissions,
			isFetching: isFetchingCostCenters,
			setIsFetching: setIsFetchingCostCenters,
		},
		fiscalYear: {
			key: 'fiscalYearId',
			permissions: fiscalYearPermissions,
			setPermissions: setFiscalYearPermissions,
			isFetching: isFetchingFiscalYear,
			setIsFetching: setIsFetchingFiscalYear,
		},
	}

	const [permissionItems, setPermissionItems] = useState({
		account: {
			key: 'accountingAccountId',
			url: `/budget-base/account-security?client={client}&organization={organization}&businessUnit={businessUnit}&replicateSecurityToAllBusinessUnit=${replicatePermissionsToAllBusinessUnit}`,
			label: i18n.t<string>("budget.permissions.accounting"),
			selectedUser: selectedUser,
			setPermissions: setAccountingPermissions,
			subtitle: i18n.t("budget.permissions.select_permission.account"),
			searchText: i18n.t<string>("budget.permissions.search_by_accounts"),
		},
		costCenter: {
			key: 'costCenterId',
			url: `/budget-base/cost-center-security?client={client}&organization={organization}&businessUnit={businessUnit}&replicateSecurityToAllBusinessUnit=${replicatePermissionsToAllBusinessUnit}`,
			label: i18n.t<string>("budget.permissions.cost_center"),
			selectedUser: selectedUser,
			setPermissions: setCostCenterPermissions,
			subtitle: i18n.t("budget.permissions.select_permission.cost_center"),
			searchText: i18n.t<string>("budget.permissions.search_by_cost_centers"),
		},
		fiscalYear: {
			key: 'fiscalYearId',
			url: "/budget-base/year-security?scenario={scenario}&locale={locale}&client={client}&user={user}",
			label: i18n.t<string>("fiscal_year"),
			selectedUser: selectedUser,
			setPermissions: setFiscalYearPermissions,
			subtitle: i18n.t("fiscal_year"),
			searchText: i18n.t<string>("search_for_fiscalYear"),
		},
		contractType: {
			key: 'contractTypeId',
			url: "/budget-base/year-security?scenario={scenario}&locale={locale}&client={client}&user={user}",
			label: i18n.t<string>("contract_type"),
			selectedUser: selectedUser,
			setPermissions: setFiscalYearPermissions,
			subtitle: i18n.t("contract_type"),
			searchText: i18n.t<string>("contract_type"),
		},
	})

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flex-field-security?locale={locale}&client={client}&user=0&bu={businessUnit}&flexField=0&group=0`
		}, onLoadFlexFieldList);
	}, [])

	function onLoadFlexFieldList(data: FlexFieldMenuItem[]) {
		if (data.length > 0) {
			const updatedPermissionItems = { ...permissionItems }
			let flexFields = data.map((res) => {
				return {
					[res.description]: {
						key: res.flexFieldId,
						label: res.description,
						url: "/budget-base/flex-field-security",
						blockedtitle: res.description,
						titleSectionUnlocked: res.description,
						subtitle: `Selecione a permissão desejada para cada ${res.description}`,
						subtitleSectionUnlocked: res.description,
						secondModalText: res.description,
						searchText: res.description,
					}
				}
			})
			const flexFieldMenuItems = data.map((res) => {
				return {
					key: res.flexFieldId.toString(),
					label: res.description,
				}
			}) as { key: MenuKey, label: string }[]

			flexFields.forEach((res) => {
				const key = Object.values(res)[0].key.toString();
				updatedPermissionItems[key] = Object.values(res)[0];
			});

			const updatedMenuItems = [...menuItems]
			updatedMenuItems.push(...flexFieldMenuItems)
			setMenuItems(updatedMenuItems)
			setPermissionItems(updatedPermissionItems)
		}
		setIsFetchingItems(false);
	}

	useEffect(() => {
		setIsMenuDisabled(true)
		setSelectedMenuKey(MenuKey.FISCALYEAR);

		if (selectedUser?.value) {

			const userId = selectedTab === 'user' ? selectedUser.value : 0
			const groupId = selectedTab === 'group' ? selectedUser.value : 0
			setSelectedPermissionItem({} as PermissionItem)

			setIsFetchingSelectedPermissions(true);

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/cost-center-security?locale={locale}&client={client}&user=${userId}&bu={businessUnit}&organization={organization}&group=${groupId}`,
			}, (data) => onLoadPermissionSecurityData(data, MenuKey.COSTCENTER));

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/year-security?scenario={scenario}&locale={locale}&client={client}&userLogged={user}&user=${userId}&group=${groupId}`,
			}, (data: OrganizationFiscalYear[]) => {
				const orgOptions = data.reduce((acc, val) => {
					if (!acc.find(item => item.value === val.organizationId)) {
						acc.push({
							label: val.organizationName,
							value: val.organizationId
						})
					}
					return acc
				}, [])
				setOrganizationOptions(orgOptions)
				onLoadPermissionSecurityData(data, MenuKey.FISCALYEAR)
				setSelectedPermissionItem(permissionItems[MenuKey.FISCALYEAR])
			});

		}

	}, [selectedUser, selectedTab]);

	useEffect(() => {
		if (selectedMenuKey === MenuKey.ACCOUNT) {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/monolith/client/is-cost-center-link-to-accounting-account?client={client}`,
			}, (data) => loadIsCostCenterLinked(data));
		};
		if (selectedMenuKey !== MenuKey.CONTRACT) return;

	}, [selectedMenuKey]);

	function loadIsCostCenterLinked(data) {
		if (!data) {
			onChangeSelect(-1);
			setIsCostCenterLinked(data);
			//setIsFetchingAccountingAccounts(true);
			//loadAccountOptions(-1);
		}
	}

	function onLoadPermissionSecurityData(
		data: PermissionSecurityData[],
		itemKey: MenuKey,
		isAccount = false
	) {
		const updatedPermissionData = data.map(res => {
			return {
				id: res.id,
				itemId: itemKey === MenuKey.FISCALYEAR ? res.year : res[permissions[itemKey].key],
				description: itemKey === MenuKey.FISCALYEAR ? res.year.toString() : res.description,
				clientId: res.clientId,
				userId: res.userId,
				groupId: res.groupId,
				businessUnitId: res.businessUnitId,
				organizationId: res.organizationId,
				permissionSecurityLevel: res.permissionSecurityLevel,
				costCenterId: res.costCenterId
			}
		})

		permissions[itemKey].setPermissions(updatedPermissionData)

		permissions[itemKey].setIsFetching(false)

		if (itemKey === MenuKey.FISCALYEAR) {
			setSelectedPermissions(updatedPermissionData)
			setInitialStatePermission(updatedPermissionData);
			setIsFetchingSelectedPermissions(false)
			setSelectedPermissionItem(permissionItems['fiscalyear'])
			setIsMenuDisabled(false)
		}

		if (itemKey === MenuKey.ACCOUNT) {
			setSelectedPermissions(updatedPermissionData)
			setIsFetchingSelectedPermissions(false)
			if (isAccount) {
				setInitialStatePermission(updatedPermissionData);
			}
		}
	}

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
	}

	function loadAccountOptions(costCenterId) {
		if (!costCenterId) return;
		const userId = selectedTab === 'user' ? selectedUser.value : 0
		const groupId = selectedTab === 'group' ? selectedUser.value : 0

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/account-security?locale={locale}&client={client}&user=${userId}&bu={businessUnit}&organization={organization}&group=${groupId}&costCenterId=${costCenterId}`,
		}, (data) => onLoadPermissionSecurityData(data, MenuKey.ACCOUNT, true));
	}

	function onChangeSelectedPermissionItem(e) {
		setIsFlexFields(isFlexField(e));

		if (e.key !== MenuKey.COSTCENTER &&
			e.key !== MenuKey.ACCOUNT &&
			e.key !== MenuKey.FISCALYEAR &&
			e.key !== MenuKey.SALESHIERARCHY &&
			e.key !== MenuKey.CONTRACT
		) {
			setIsFetchingFlexFields(true);
			setSelectedPermissions([])
			const userId = selectedTab === 'user' ? selectedUser.value : 0
			const groupId = selectedTab === 'group' ? selectedUser.value : 0

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/flex-field-security?locale={locale}&client={client}&bu={businessUnit}&user=${userId}&group=${groupId}&flexField=${e.key}`
			}, onLoadFlexFieldPermissions.bind(this));
		} else if (e.key !== MenuKey.SALESHIERARCHY && e.key !== MenuKey.CONTRACT) {
			if (e.key === MenuKey.ACCOUNT || e.key === MenuKey.FISCALYEAR) {
				setSelectedPermissions([]);
				setInitialStatePermission([]);
			} else {
				setSelectedPermissions(permissions[e.key].permissions);
				setInitialStatePermission(permissions[e.key].permissions);
			}
			setCostCenterSelectedValue(0);
			setIsFetchingSelectedPermissions(permissions[e.key].isFetching);
		}
		setSelectedPermissionItem(permissionItems[e.key])
		setReplicatePermissionsToAllBusinessUnit(true)
		setSelectedMenuKey(e.key);
	};

	function isFlexField(e): boolean {
		return e.key !== MenuKey.COSTCENTER &&
			e.key !== MenuKey.ACCOUNT &&
			e.key !== MenuKey.FISCALYEAR &&
			e.key !== MenuKey.SALESHIERARCHY &&
			e.key !== MenuKey.CONTRACT
	}

	const onLoadFlexFieldPermissions = (data: IFlexFieldValuesResponse[]) => {
		const flexFieldValues = data[0].flexFieldValues;
		const userPermissionData: PermissionSecurityData[] = flexFieldValues.map(permission => {
			const description: string = `${permission.externalCode} - ${permission.description}`;
			return ({
				description,
				id: permission.id,
				clientId: permission.clientId,
				userId: permission.userId,
				groupId: permission.groupId,
				itemId: permission.flexFieldId,
				businessUnitId: permission.businessUnitId,
				permissionSecurityLevel: permission.permissionSecurityLevel,
			})
		})
		setSelectedPermissions(userPermissionData);
		setInitialStatePermission(userPermissionData);
		setIsFetchingFlexFields(false)
	}

	function handleSavePermissions() {
		if (isSavingPermission) {
			return
		}
		const userId = selectedTab === 'user' ? selectedUser.value : 0
		const groupId = selectedTab === 'group' ? selectedUser.value : 0

		const dataToSave = tableData.reduce((acc, item) => {
			const itemPermission = initialStatePermission.find(permissionItem => permissionItem.itemId === item.itemId)
			if (itemPermission.permissionSecurityLevel !== item.permissionSecurityLevel) {
				if (!menuKeyArray.includes(selectedMenuKey)) {
					acc.push({ ...item, flexFieldId: item.itemId, costCenterId: costCenterSelectedValue })
				} else if (selectedMenuKey === MenuKey.ACCOUNT) {
					acc.push({ ...item, [permissions[selectedMenuKey].key]: item.itemId, costCenterId: costCenterSelectedValue })
				} else if (selectedMenuKey === MenuKey.FISCALYEAR) {
					acc.push({
						...item,
						year: item.itemId,
						clientId: userInfo.clientId,
						userId: userId,
						groupId: groupId,
					})
				} else {
					acc.push({ ...item, [permissions[selectedMenuKey].key]: item.itemId })
				}
			}
			return acc
		}, [])

		if (dataToSave.length === 0) {
			Notification({
				type: "warning",
				message: "Não há alterações para salvar.",
			})
			return
		}
		setIsSavingPermission(true)

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: selectedPermissionItem.url,
			params: dataToSave
		}, (data: PermissionSecurityData[]) => {
			const updatedPermissions = initialStatePermission.map(permissionItem => {
				const updated = data.find(item => {
					if (!menuKeyArray.includes(selectedMenuKey)) {
						return item['flexFieldId'] === permissionItem.itemId
					} else if (selectedMenuKey === MenuKey.FISCALYEAR) {
						return (item.year === permissionItem.itemId && item.organizationId === permissionItem.organizationId)
					} else {
						return item[permissions[selectedMenuKey].key] === permissionItem.itemId
					}
				})

				if (updated) {
					return { ...permissionItem, id: updated.id, permissionSecurityLevel: updated.permissionSecurityLevel }
				}
				return permissionItem
			})
			if (selectedMenuKey === MenuKey.ACCOUNT) {
				invalidadeQuery(['account-accounting-options', costCenterSelectedValue.toString()])

			}
			if (menuKeyArray.includes(selectedMenuKey)) {
				permissions[selectedMenuKey].setPermissions(updatedPermissions)
			}
			if (selectedMenuKey === MenuKey.FISCALYEAR) {
				setSelectedPermissions(updatedPermissions.filter(item => item.organizationId === data[0].organizationId))
				invalidadeQuery(['budget-period-dates'])

			} else {
				setSelectedPermissions(updatedPermissions)
			}
			setInitialStatePermission(updatedPermissions)
			setIsSavingPermission(false)
			Notification({
				type: "success",
				message: "Permissão salva com sucesso.",
			})
		}, handleErrorRequest)
	};

	function handleCancelChanges() {
		if (isSavingPermission) return
		setTableData(initialStatePermission.map(permission => ({ ...permission, key: permission.itemId })))
	}

	function onChangeSelect(value) {
		if (selectedMenuKey === MenuKey.ACCOUNT) {
			setCostCenterSelectedValue(value);
			setIsFetchingSelectedPermissions(true);
			loadAccountOptions(value);
		} else {
			const yearList = fiscalYearPermissions.filter(item => item.organizationId === Number(value))
			setSelectedPermissions(yearList)
			setInitialStatePermission(fiscalYearPermissions)
		}
	}

	function handleChangeCheckedReplicate(checked: boolean) {
		switch (selectedMenuKey) {
			case MenuKey.ACCOUNT:
				setSelectedPermissionItem(prevState => ({
					...prevState,
					url: `/budget-base/account-security?client={client}&organization={organization}&businessUnit={businessUnit}&replicateSecurityToAllBusinessUnit=${checked}`
				}))
				break;
			case MenuKey.COSTCENTER:
				setSelectedPermissionItem(prevState => ({
					...prevState,
					url: `/budget-base/cost-center-security?client={client}&organization={organization}&businessUnit={businessUnit}&replicateSecurityToAllBusinessUnit=${checked}`
				}))
				break;
			default:
				break;
		}
		setReplicatePermissionsToAllBusinessUnit(checked);
	}

	return (
		<Layout className="permission-security-layout">
			{!isFetchingItems ? (
				<>
					<Layout hasSider={true} style={{ background: "#fff" }}>
						<Sider theme="light" className="permission-security-area-list">
							<Space direction="vertical">
								<Search
									placeholder={i18n.t("search")}
									allowClear
									onChange={handleSearch}
									className="permissions-list-input"
								/>

								<Menu
									disabled={isMenuDisabled}
									mode="vertical"
									items={menuItems
										.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
										.map((item) => {
											return {
												label: (
													<div className="permissions-item-menu">
														{item.label}
														<Icon
															icon="ion:chevron-forward-circle"
															style={{ fontSize: 25, color: "#0065b3" }}
														/>
													</div>
												),
												key: item.key,
											};
										})}
									onClick={onChangeSelectedPermissionItem}
									selectedKeys={[selectedMenuKey]}
								/>
							</Space>
						</Sider>
						<PermissionSecurityContent
							selectedPermissionItem={selectedPermissionItem}
							permissionData={selectedPermissions}
							isFetching={isFetchingSelectedPermissions}
							isFlexFields={isFlexFields}
							selectedUser={selectedUser}
							isFetchingFlexFields={isFetchingFlexFields}
							tableData={tableData}
							setTableData={setTableData}
							permissionsCostCenter={permissions.costCenter.permissions.filter(
								(item) =>
									item.permissionSecurityLevel !== "BLOCKED" && item.permissionSecurityLevel !== null
							)}
							selectedMenuKey={selectedMenuKey}
							functionalityPermissions={functionalityPermissions}
							onChangeSelect={onChangeSelect}
							organizationOptions={organizationOptions}
							selectedTab={selectedTab}
							isCostCenterLinked={isCostCenterLinked}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
						/>
					</Layout>
					{functionalityPermissions.edit &&
						selectedMenuKey !== MenuKey.SALESHIERARCHY &&
						selectedMenuKey !== MenuKey.CONTRACT && (
							<Footer className="permission-security-footer">
								{(selectedMenuKey === "account" || selectedMenuKey === "costCenter") && (
									<Checkbox
										onChange={(e) => handleChangeCheckedReplicate(e.target.checked)}
										checked={replicatePermissionsToAllBusinessUnit}
									>
										{i18n.t("budget.permissions.replicate_permission_for_all_units")}
									</Checkbox>
								)}
								<Button type="link" onClick={handleCancelChanges}>
									{i18n.t<string>("cancel")}
								</Button>
								<Button
									className="gs-main-button"
									onClick={handleSavePermissions}
									style={{ padding: "0 45px" }}
									loading={isSavingPermission}
								>
									{isSavingPermission ? i18n.t<string>("saving") : i18n.t<string>("save")}
								</Button>
							</Footer>
						)}
				</>
			) : (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 200 }}>
					<Spin size="default" tip={`${i18n.t<string>("loading")}...`} />
				</div>
			)}
		</Layout>
	);
}