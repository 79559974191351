import { createContext, useContext, useEffect, useState } from "react";
import { ICostCenterAssignor, ICostCenterLinked, ICostCenterRequester, IFlexFieldFreeze, IManagementTransferContext, ITransferRequest, IValuesMonths } from "../IManagementTransfer";
import { IOptionsForSearch } from "components/input/search/IMultipleSearch";
import { Form } from "antd";
import { useSelectedCostCenterOptions } from "../../fixedExpense/context/useSelectedCostCenterOptions";
import { useBudgetDates } from "hooks/useBudgetDates";
import { FlexFilter, ModuleType } from "util/types/types";
import moment from "moment";
import { buildFlexField } from "../../accountingLedger/function/buildFlexField";
import { BudgetOpeningCachedData } from "../../fixedExpense/IFixedExpense";
import { useUserContext } from "context/UserContext";
import { queryClient } from "util/queryClient";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import i18n from "util/base/i18n";
import { FlexFieldOptions } from "components/modalFilter/IModalFilter";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";
import { useLinkedAccountingAccountOptions } from "./UseLinkedAccountingAccountOptions";
import { useSelectedAccountAccountingOptions } from "../../fixedExpense/context/useSelectedAccountAccountingOptions";
import { useSelectedCostCenterAssignorOptions } from "./useSelectedCostCenterAssignorOptions";

export const ManagementTransferContext = createContext<IManagementTransferContext>({} as IManagementTransferContext);
export function ManagementTransferProvider({ children }) {
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [costCenterRequester, setCostCenterRequester] = useState<ICostCenterRequester[]>([]);
    const [costCenterAssignor, setCostCenterAssignor] = useState<ICostCenterAssignor[]>([]);
    const [openBudgetYears, setOpenBudgetYears] = useState<number[]>([]);
    const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilter[]>([]);
    const [flexFieldsFiltersAssignor, setFlexFieldsFiltersAssignor] = useState<FlexFilter[]>([]);
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([
        [moment().startOf("year"), moment().month(11).endOf("month")],]);
    const [transferData, setTransferData] = useState<ITransferRequest[]>([]);
    const [searchType, setSearchType] = useState<string>("business_unit");
    const [inputValue, setInputValue] = useState<string>("");
    const [dataSource, setDataSource] = useState<IValuesMonths[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editingRecord, setEditingRecord] = useState<ITransferRequest>({} as ITransferRequest);
    const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment] | null>(null);
    const [totals, setTotals] = useState<number[]>([]);
    const [filteredData, setFilteredData] = useState<ITransferRequest[]>([]);
    const [flexFieldValues, setFlexFieldValues] = useState<FlexFilter[]>([]);
    const [selectedFlexField, setSelectedFlexField] = useState<{ flexId: number; childId: number } | null>(null);
    const [year, setYear] = useState<[moment.Moment, moment.Moment] | null>(null);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
    const [dataFlexFieldFreeze, setDataFlexFieldFreeze] = useState<IFlexFieldFreeze[]>([]);
    const [dataCostCenterLiked, setDataCostCenterLiked] = useState<ICostCenterLinked[]>([]);
    const { data: costCenterAssignorOptions } = useSelectedCostCenterAssignorOptions();
    const { data: budgetDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);
    const { data: costCenterOptions } = useSelectedCostCenterOptions();
    const { data: accountAccountingOptionsRequester, isLoading: isFetchingAccountOptionsRequester } = useLinkedAccountingAccountOptions(
        costCenterRequester[0]?.value,
        {
            enabled: !!costCenterRequester[0]?.value,
        }
    );
    const { data: accountAccountingAllOptions } = useSelectedAccountAccountingOptions();
    const { data: accountAccountingOptionsAssignor, isLoading: isFetchingAccountOptionsAssignor } = useLinkedAccountingAccountOptions(
        costCenterAssignor[0]?.value,
        {
            enabled: !!costCenterAssignor[0]?.value,
        }
    );

    const defaultSearchOptions: IOptionsForSearch[] = [
        {
            description: "requester_cost_center",
            i18nString: `${i18n.t("management_transfer.requester")} - ${i18n.t("cost_center")}`,
        },
        {
            description: "assignor_cost_center",
            i18nString: `${i18n.t("management_transfer.assignor")} - ${i18n.t("cost_center")}`,
        },
        {
            description:"lastUpdateUserName",
            i18nString: `${i18n.t("management_transfer.changed_by")} - ${i18n.t("user")}`,
        }
    ];

    useEffect(() => {
        getFlexFieldFilters();
        getFlexFieldFiltersAssignor();
        getFlexiFieldFreezedValues();
        getCostCenterLinked();
    }, []);

    useEffect(() => {
        if (!budgetDates) {
            return;
        }
        const openedPeriods = budgetDates.period?.reduce((acc, val) => {
            const period = [moment(val.expensesResourcesBudgetStartDate), moment(val.expensesResourcesBudgetEndDate)];
            acc.push(period);
            return acc;
        }, []);
        setOpenBudgetYears(budgetDates.period?.map((period) => period.year));
        setOpenedCalendarPeriod(openedPeriods);
    }, [budgetDates]);

    useEffect(() => {
        if (year && year.length === 2) {
            getManagementTransfer();
        }
    }, [year]);

    function getCostCenterLinked() {

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/expenses/cost-center/find-all-with-parameterization-status?client={client}&locale={locale}&user={user}&organization={organization}&bu={businessUnit}",
        }, (data) => {
            setDataCostCenterLiked(data);
        });
    }

    function getFlexiFieldFreezedValues() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/expenses/flexFieldSelection",
        },
            (responseData) => {
                setDataFlexFieldFreeze(responseData)
            })
    }

    function getManagementTransfer() {
        setIsLoading(true);

        const startDate = year[0].startOf('month');
        const endDate = year[1].startOf('month');

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/expenses/managementTransfer/getTransfer/{scenario}/{organization}/{user}/{businessUnit}/${startDate.format("DD-MM-YYYY")}/${endDate.format("DD-MM-YYYY")}`,
            },
            (responseData) => {
                setIsLoading(false);

                setTransferData(responseData);
                setFilteredData(responseData);
                setIsDataLoaded(true);
            },
            (error) => {
                setIsLoading(false);

                handleErrorRequest(error);
            }
        );
    }

    async function onLoadFlexField(data: FlexFieldOptions[], budgetOpeningCachedData?: BudgetOpeningCachedData) {
        setFlexFieldsFilters(buildFlexField(data, userInfo, budgetOpeningCachedData));
        setIsFetchingFlexFields(false);
    }

    async function getFlexFieldFilters(budgetOpeningCachedData?: BudgetOpeningCachedData) {
        let flexfields: any = queryClient.getQueryData(["all-flex-field-by-module", "managementTransfer"], {});
        if (!flexfields) {
            flexfields = await ServiceCaller.doAsyncRequest({
                type: RequestType.GET,
                url: "/expenses/flex-field-opening?user={user}",
            });
            queryClient.setQueryData(["all-flex-field-by-module", "managementTransfer"], flexfields, {});
        }

        onLoadFlexField(flexfields.data, budgetOpeningCachedData);
    }
interface FlexFieldResponse {
    data: FlexFieldOptions[];
}

async function getFlexFieldFiltersAssignor(budgetOpeningCachedData?: BudgetOpeningCachedData) {
    let flexfields = queryClient.getQueryData<FlexFieldResponse>(["all-flex-field-by-module", "managementTransfer"], {});
    
    if (!flexfields) {
        const response = await ServiceCaller.doAsyncRequest<FlexFieldResponse>({
            type: RequestType.GET,
            url: `/budget-base/flex-field/find-all-flexfield-with-values-complete-bean?modules=EXPENSES`,
        });

        if (response.success && Array.isArray(response.data)) {
            queryClient.setQueryData(["all-flex-field-by-module", "managementTransfer"], response);
            await onLoadFlexFieldAssignor(response.data, budgetOpeningCachedData);
        } else {
            console.error("Invalid response structure:", response);
        }
    } else {
        await onLoadFlexFieldAssignor(flexfields.data, budgetOpeningCachedData);
    }
}
    
    async function onLoadFlexFieldAssignor(data:FlexFieldOptions[], budgetOpeningCachedData) {
    
        if (!Array.isArray(data)) {
            setFlexFieldsFiltersAssignor([]); 
            return;
        }
    
        const transformedData = transformFlexFieldAssignor(data);
        setFlexFieldsFiltersAssignor(transformedData);
        setIsFetchingFlexFields(false);
    }
    
    const transformFlexFieldAssignor = (data) => {
    
        return data.map((item) => ({
            label: item.description, 
            value: item.id,
            linkedFilters: null,
            ordenation: item.ordenation,
            fieldCode: item.fieldCode,
            selectedOption: [],
            children: item.linkedFlexFields.map((child) => ({
                value: child.id, 
                label: `${child.externalCode} - ${child.description}`, 
                linkedParentFlexFieldId: null,
            })),
            allValues: item.linkedFlexFields.map((child) => ({
                fieldValueId: child.id, 
                externalCode: child.externalCode,
                description: child.description,
                fitler: {
                    businessUnitFilters: null,
                    costCenterFilters: null,
                    organizationFilters: null,
                    flexFieldFilters: null,
                },
                theLastSelected: false,
            })),
        }));
    };

    const handleOpenModal = () => {

        setIsEditing(false);
        setEditingRecord(null);
        form.resetFields();
        setDateRange(null);
        setDataSource([]);
        setIsModalOpen(true);
    };

    const handleDelete = () => {

        const realizedPeriods = budgetDates.period
            .filter(period => period.expensesResourcesRealizedPeriod)
            .map(period => new Date(period.expensesResourcesRealizedPeriod));

        const realizedMonthsYears = realizedPeriods.map(date => ({
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        }));

        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('-').map(Number);
            return new Date(year, month - 1, day);
        };

        const canDelete = (transferData, selectedKeys) => {
            const filteredTransfers = transferData.filter(transfer => selectedKeys.includes(transfer.id));

            return filteredTransfers.every(transfer => {
                return transfer.values.every(value => {
                    const transferDate = parseDate(value.period);
                    const transferMonth = transferDate.getMonth() + 1;
                    const transferYear = transferDate.getFullYear();

                    return !realizedMonthsYears.some(realized =>
                        realized.month === transferMonth && realized.year === transferYear
                    );
                });
            });
        };
        if (!canDelete(transferData, selectedRowKeys)) {
            Notification({
                type: "warning",
                message: i18n.t("period_realized_impossible_delete"),
            })

            return
        }
        setIsLoading(true);

        const selectedItems = transferData.filter((item) => selectedRowKeys.includes(item.id));

        const idsToDelete = selectedItems.map((item) => item.id).join(",");

        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/expenses/managementTransfer/delete/${idsToDelete}/${userInfo.clientId}`,
        }, () => {
            Notification({
                type: "success",
                message: i18n.t("successfully_deleted"),
            });
            setSelectedRowKeys([]);
            getManagementTransfer();
        }, handleErrorRequest);
    };

    const handleEdit = () => {
        if (selectedRowKeys.length === 1) {
            const selectedRecord = transferData.find((record) => record.id === selectedRowKeys[0]);
            if (selectedRecord) {

                const startDate = selectedRecord.startDate
                    ? moment(selectedRecord.startDate, "DD-MM-YYYY").isValid()
                        ? moment(selectedRecord.startDate, "DD-MM-YYYY")
                        : null
                    : null;

                const endDate = selectedRecord.endDate
                    ? moment(selectedRecord.endDate, "DD-MM-YYYY").isValid()
                        ? moment(selectedRecord.endDate, "DD-MM-YYYY")
                        : null
                    : null;

                form.setFieldsValue({
                    date_range: [
                        startDate ? startDate : null,
                        endDate ? endDate : null,
                    ],
                    requester_cost_center: selectedRecord.origin.costCenterId,
                    requester_account_accounting: selectedRecord.origin.accountId,
                    assignor_cost_center: selectedRecord.destination[0].costCenterId,
                    assignor_account_accounting: selectedRecord.destination[0].accountId,
                });

                setCostCenterRequester([{
                    value: String(selectedRecord.origin.costCenterId),
                    id: selectedRecord.origin.costCenterId,
                    name: costCenterOptions.find((costCenter) => costCenter.value === selectedRecord.origin.costCenterId)?.label
                }]);

                setCostCenterAssignor([{
                    value: String(selectedRecord.destination[0].costCenterId),
                    id: selectedRecord.destination[0].costCenterId,
                    name: costCenterAssignorOptions.find((costCenter) => costCenter.value === selectedRecord.destination[0].costCenterId)?.label
                }])

                setDateRange([startDate, endDate]);
                setEditingRecord(selectedRecord);
                setIsEditing(true);
                setIsModalOpen(true);
            }
        }
    };

    const isDataReady =
        Array.isArray(flexFieldsFilters) &&
        Array.isArray(flexFieldsFiltersAssignor) &&
        Array.isArray(costCenterOptions) &&
        Array.isArray(costCenterAssignorOptions) &&
        Array.isArray(accountAccountingOptionsRequester) &&
        Array.isArray(accountAccountingOptionsAssignor);

    const formattedData: ITransferRequest[] = isDataReady
        ? filteredData.map((item) => {

            const requesterFlexFields = flexFieldsFilters
                .filter((flexField) => {
                    return item.origin.flexFieldValueIds.some((id) => flexField.children.some((child) => child.value === id));
                })
                .map((flexField) => {
                    const matchedChildren = flexField.children.filter((child) =>
                        item.origin.flexFieldValueIds.includes(child.value)
                    );
                    return {
                        label: flexField.label,
                        values: matchedChildren.map((child) => child.label),
                    };
                });

            const assignorFlexFields = flexFieldsFiltersAssignor
                .filter((flexField) => {
                    return item.destination[0]?.flexFieldValueIds.some((id) => flexField.children.some((child) => child.value === id));
                })
                .map((flexField) => {
                    const matchedChildren = flexField.children.filter((child) =>
                        item.destination[0]?.flexFieldValueIds.includes(child.value));
                    return {
                        label: flexField.label,
                        values: matchedChildren.map((child) => child.label),
                    };
                });

            return {
                ...item,
                id: item.id,
                date_range: `${item.startDate} - ${item.endDate}`,
                requester_cost_center: costCenterOptions.find((option) => option.value === item.origin.costCenterId)?.label,
                requester_account_accounting: accountAccountingAllOptions.find((option) => option.value === item.origin.accountId)?.label,
                assignor_cost_center: costCenterAssignorOptions.find((option) => item.destination[0]?.costCenterId === option.value)?.label,
                assignor_account_accounting: accountAccountingAllOptions.find((option) => item.destination[0]?.accountId === option.value)?.label,
                requester_flex_fields: requesterFlexFields,
                assignor_flex_fields: assignorFlexFields,
                total: item.totalValue,
                lastUpdateUserName: item.lastUpdateUserName,
                lastUpdateDate: item.lastUpdateDate,
            };
        })
        : [];

    const getFlexFieldValues = (flexFieldsFilters, flexFieldsFiltersAssignor, fieldLabel, flexFieldValueIds, isRequester = true) => {
        const flexField = flexFieldsFilters.find(f => f.label.toLowerCase() === fieldLabel.toLowerCase());
        const flexFieldAssignor = flexFieldsFiltersAssignor.find(f => f.label.toLowerCase() === fieldLabel.toLowerCase());

        const result = [];

        if (flexField) {
            const childrenValues = flexField.children
                .filter(child => flexFieldValueIds.includes(child.value))
                .map(child => child.label);
            result.push(...childrenValues);
        }

        if (flexFieldAssignor) {
            const assignorValues = flexFieldAssignor.children
                .filter(child => flexFieldValueIds.includes(child.value))
                .map(child => child.label);
            result.push(...assignorValues);
        }

        return result;
    };

    const handleSearch = (option: IOptionsForSearch, searchValue: string) => {
        const searchOption = option.description;

        if (!searchValue) {
            setFilteredData(transferData);
            return;
        }

        const filtered = formattedData.filter((item) => {
            let fieldValue;

            if (searchOption.startsWith("requester_flex_fields") || searchOption.startsWith("assignor_flex_fields")) {
                const flexFieldLabel = searchOption.replace(/(requester_flex_fields_|assignor_flex_fields_)/, "").replace(/_/g, " ");

                const isRequester = searchOption.startsWith("requester_flex_fields");
                const flexFieldValueIds = isRequester ? item.origin.flexFieldValueIds : item.destination[0]?.flexFieldValueIds;

                fieldValue = getFlexFieldValues(flexFieldsFilters, flexFieldLabel, flexFieldValueIds, isRequester);
            } else {
                fieldValue = item[searchOption];
            }

            if (!fieldValue) return false;

            const match = Array.isArray(fieldValue)
                ? fieldValue.some(value => value.toLowerCase().includes(searchValue.toLowerCase()))
                : fieldValue.toString().toLowerCase().includes(searchValue.toLowerCase());

            return match;
        });

        setFilteredData(filtered);
    };

    return (
        <ManagementTransferContext.Provider value={{
            isModalOpen,
            setIsModalOpen,
            defaultSearchOptions,
            form,
            costCenterOptions,
            accountAccountingOptionsRequester,
            isFetchingAccountOptionsRequester,
            accountAccountingOptionsAssignor,
            isFetchingAccountOptionsAssignor,
            setCostCenterRequester,
            costCenterRequester,
            setCostCenterAssignor,
            costCenterAssignor,
            budgetDates,
            openedCalendarPeriod,
            openBudgetYears,
            flexFieldsFilters,
            isFetchingFlexFields,
            selectedRowKeys,
            setSelectedRowKeys,
            transferData,
            setTransferData,
            searchType,
            setSearchType,
            inputValue,
            setInputValue,
            handleOpenModal,
            handleDelete,
            dataSource,
            setDataSource,
            isEditing,
            setIsEditing,
            editingRecord,
            setEditingRecord,
            dateRange,
            setDateRange,
            totals,
            setTotals,
            setFlexFieldValues,
            flexFieldValues,
            userInfo,
            handleEdit,
            getManagementTransfer,
            isLoading,
            setIsLoading,
            selectedFlexField,
            setSelectedFlexField,
            handleSearch,
            filteredData,
            setFilteredData,
            formattedData,
            isDataReady,
            year,
            setYear,
            isDataLoaded,
            dataFlexFieldFreeze,
            setDataFlexFieldFreeze,
            getFlexiFieldFreezedValues,
            dataCostCenterLiked,
            setDataCostCenterLiked,
            getCostCenterLinked,
            flexFieldsFiltersAssignor,
            costCenterAssignorOptions
        }}>
            {children}
        </ManagementTransferContext.Provider>
    )
}

export const useManagementTransferContext: () => IManagementTransferContext = () => {
    return useContext(ManagementTransferContext);
} 