import { Icon } from "@iconify/react";
import { Form, Input } from "antd";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { SecudaryButton } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import LoadingChanges from "module/budget/pages/revenue/mainFlow/grid/header/LoadingChanges";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useNewReportContext } from "../../context/ReportRegistrationContext";
import { useFormulaContext } from "../../formula/context/FormulaContext";
import { IStepOneProps, LevelReport, SignalType } from "../../IReportRegistration";
import TableLevels from "./TableLevels";

export default function StepOne({ sortLevels, setReportType }: IStepOneProps) {
    const [tableData, setTableData] = useState<LevelReport[]>([]);
    const [descriptionError, setDescriptionError] = useState<boolean>(false);
    const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(false);

    const { report, setReport, selectedRowKeys, setSelectedRowKeys } = useNewReportContext();

    const { isLoading, setIsLoading, hasSavedValue, setHasSavedValue } = useFormulaContext();

    const secondaryButton: SecudaryButton = {
        title: i18n.t<string>("new_sublevel"),
        onClick: () => addSublevel(selectedRowKeys[0]),
        icon: <Icon icon="akar-icons:circle-plus-fill" />,
        disabled: isDisableSecondaryButton(selectedRowKeys, tableData),
    };

    const importProps: IImportModal[] = [
        {
            importUrl: "/budget-report/report/import-structure",
            templateUrl: "/budget-report/report/template-structure?locale={locale}",
            type: "excel",
            urlParams: `&report=${report?.id}`,
        },
    ];

    useEffect(() => {
        setTableData(report.levels);
    }, []);

    useEffect(() => {
        setReport({ ...report, levels: tableData });
    }, [tableData]);

    function isDisableSecondaryButton(selectedKeys: number[], lines: LevelReport[]): boolean {
        let result: boolean = false;
        if (!selectedKeys.length || selectedKeys.length > 1) {
            result = true;
        }
        if (!result) {
            for (let index = 0; index < selectedKeys.length; index++) {
                const keySelected = selectedKeys[index];
                const line = lines.find(({ key }) => keySelected === key);
                if (!line) {
                    result = true;
                    break;
                } else if (line.title) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }

    function fetchReportsAfterImport() {
        setIsLoadingTableData(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `budget-report/report/find/${report.id}`,
            },
            (data) => {
                setReportType(data.type);
                const formattedData = {
                    ...data,
                    reportType: data.reportType,
                    levels: sortLevels(data.levels),
                };

                setReport(formattedData);
                setTableData(formattedData?.levels ? formattedData?.levels : []);
                setIsLoadingTableData(false);
            }
        );
    }

    function addOrdenationTableData(lines: LevelReport[], orderChanged: number): LevelReport[] {
        const newData: LevelReport[] = [];
        lines.forEach((line) => {
            if (line.ordination >= orderChanged) {
                if (line.children) {
                    line.children = addOrdenationTableData(line.children, line.ordination);
                }
                line.ordination = line.ordination + 1;
                line.key = line.key + 1;
            }
            newData.push(line);
        });
        return newData;
    }

    function addSublevel(selectedKey: number) {
        setIsLoading(true);
        setTableData((state) => {
            const levelToAddSublevel = state.find(({ key }) => key === selectedKey);
            let lastOrderSubLevel = 0;

            const newLevel: LevelReport = {
                key: 0,
                description: `${i18n.t<string>("description")} - `,
                informative: false,
                invisible: false,
                title: false,
                children: null,
                ordination: 0,
                externalCode: `${i18n.t<string>("code_row")} - `,
                isSubLevel: true,
                formulas: [],
                levelStyle: {
                    color: "#fff",
                    colorFont: "#000",
                    isBold: false,
                    isItalic: false,
                    isScratched: false,
                    isUnderlined: false,
                    fontSize: 14,
                    signalType: SignalType.ORIGINAL,
                },
            };

            let newChildsLevel: LevelReport[] = [];

            if (levelToAddSublevel && levelToAddSublevel.children?.length) {
                lastOrderSubLevel = levelToAddSublevel.children[levelToAddSublevel.children.length - 1].ordination;
                newLevel.ordination = lastOrderSubLevel + 1;
                newLevel.key = lastOrderSubLevel + 1;
                newLevel.description += `${lastOrderSubLevel + 1}`;
                newLevel.externalCode += `${lastOrderSubLevel + 1}`;
                newChildsLevel = [...levelToAddSublevel.children, newLevel];
            } else {
                lastOrderSubLevel = levelToAddSublevel.ordination + 1;
                newLevel.ordination = lastOrderSubLevel;
                newLevel.key = lastOrderSubLevel;
                newLevel.description += `${lastOrderSubLevel + 1}`;
                newLevel.externalCode += `${lastOrderSubLevel + 1}`;
                newChildsLevel = [newLevel];
            }

            levelToAddSublevel.children = newChildsLevel;
            const newData: LevelReport[] = addOrdenationTableData(state, lastOrderSubLevel);
            setIsLoading(false);
            return newData;
        });

        setHasSavedValue(true);
        setSelectedRowKeys([]);
    }

    function handleDelete() {
        setIsLoading(true);

        setTableData((data) => {
            let newData: LevelReport[] = [];
            for (let index = 0; index < data.length; index++) {
                let line = data[index];
                if (selectedRowKeys.some((selected) => line.key === selected)) {
                    continue;
                } else if (line.children?.length) {
                    let childrenLine: LevelReport[] = [];
                    line.children.forEach((line) => {
                        if (!selectedRowKeys.some((selected) => line.key === selected)) {
                            childrenLine.push(line);
                        }
                    });
                    line.children = childrenLine;
                }
                newData.push(line);
            }

            setIsLoading(false);
            return newData;
        });

        setHasSavedValue(true);
    }

    function getLastOrdination(levels: LevelReport[]): number {
        const lastLevel: LevelReport = levels[levels.length - 1];
        let lastOrdination = 0;
        if (lastLevel) {
            lastOrdination =
                (lastLevel.children?.length
                    ? lastLevel.children[lastLevel.children.length - 1].ordination
                    : lastLevel.ordination) + 1;
        }
        return lastOrdination;
    }

    function handleNew() {
        if (!report.description || report.description.trim() === "") {
            setDescriptionError(true);
            return;
        }
        setIsLoading(true);
        setTableData((state) => {
            const lastOrdination = getLastOrdination(state);
            const newLevel: LevelReport = {
                description: `${i18n.t<string>("description")} - ${lastOrdination + 1}`,
                informative: false,
                invisible: false,
                children: null,
                title: false,
                ordination: lastOrdination,
                externalCode: `${i18n.t<string>("code_row")} - ${lastOrdination + 1}`,
                key: lastOrdination,
                isSubLevel: false,
                formulas: [],
                levelStyle: {
                    color: "#fff",
                    colorFont: "#000",
                    isBold: false,
                    isItalic: false,
                    isScratched: false,
                    isUnderlined: false,
                    fontSize: 14,
                    signalType: SignalType.ORIGINAL,
                },
            };

            setIsLoading(false);
            return [...state, newLevel];
        });
        setHasSavedValue(true);
        setSelectedRowKeys([]);
    }

    return (
        <div className="step-one">
            <div className="description-report">
                <label htmlFor="description">{i18n.t("description")}</label>
                <Form.Item
                    validateStatus={descriptionError ? "error" : ""}
                    help={descriptionError ? i18n.t<string>("description_mandatory") : ""}
                    hasFeedback
                >
                    <Input
                        id="description"
                        defaultValue={report.description}
                        onBlur={(value) => {
                            const inputValue = value.target.value.trim();
                            setReport({ ...report, description: inputValue });
                            setDescriptionError(!inputValue);
                        }}
                    />
                </Form.Item>
            </div>
            <div
                style={{
                    display: "flex",
                }}
            >
                <TopButtons
                    mainButtonTitle={i18n.t<string>("new_level")}
                    handleNew={handleNew}
                    secondaryButton={secondaryButton}
                    handleDelete={handleDelete}
                    isDeletable={!!selectedRowKeys.length}
                    handleSuccessImport={fetchReportsAfterImport}
                    importExportOptions={report.id ? { importProps: importProps } : null}
                />
                <div
                    style={{
                        paddingTop: 5,
                        marginTop: 30,
                    }}
                >
                    <LoadingChanges isLoading={isLoading} hasSavedValue={hasSavedValue} />
                </div>
            </div>
            <TableLevels
                selectedRowKeys={selectedRowKeys}
                tableData={tableData}
                onChange={(value) => setSelectedRowKeys(value)}
                setTableData={setTableData}
                setHasSavedValue={setHasSavedValue}
                setIsLoading={setIsLoading}
                isLoadingTableData={isLoadingTableData}
            />
        </div>
    );
}
