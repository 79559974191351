import { Button, Popconfirm } from "antd";
import { OperationsSelect } from "module/budget/pages/revenue/attributeParameterization/components/operators/OperationsSelect";
import { useEffect } from "react";
import i18n from "util/base/i18n";
import { useNewReportContext } from "../context/ReportRegistrationContext";
import { useFormulaContext } from "./context/FormulaContext";
import MultipleFormulaArea from "./MultipleFormulaArea";

export default function FormulaContent() {
    const { report, selectedRowFormula } = useNewReportContext();

    const {
        formula,
        setFormula,
        selectedOptionsList,
        setSelectedOptionsList,
        setUpdateOptionsRecursively,
        isLoading,
        setIsLoading,
        planningCondition,
        classNameForPulseButtonFormula,
        setHasRemovedLevel,
        setHasUnsavedChanges,
        onAcceptFormulaChanges,
        onSaveFormula,
        onCancelChangeFormulas,
        setIsSelectedLevel,
        hasUnsavedChanges,
    } = useFormulaContext();

    useEffect(() => {
        if (!planningCondition) return;

        setIsLoading(false);
    }, [planningCondition, isLoading]);

    function handleOnAcceptFormulaChanges() {
        const level = report.levels.find((level) => level.id === selectedRowFormula);
        level.formulas = formula.map((item, index) => {
            if (item.type === "attribute") {
                return {
                    id: item.content.id,
                    ordination: index,
                    name: item.content.name,
                    type: item.type,
                    attributeId: item.content.id,
                };
            } else {
                return {
                    id: item.key,
                    name: item.content,
                    type: item.type,
                    ordination: index,
                };
            }
        });

        onAcceptFormulaChanges();
    }

    function removeItem(index: number) {
        const removedItem = formula[index];
        const updatedFormula = formula.filter((_, itemIndex) => itemIndex !== index);

        const remainingKeys = updatedFormula.map((item) => item.key);
        setSelectedOptionsList(selectedOptionsList.filter((selected) => remainingKeys.includes(selected)));

        setFormula(updatedFormula);

        if (removedItem.type === "attribute") {
            setUpdateOptionsRecursively(
                removedItem.key.split("-")[1] === "" ? `${removedItem.key}${removedItem.value}` : removedItem.key
            );
            setHasRemovedLevel(removedItem.key.split("-")[0] === "LEVEL");
        }

        setHasUnsavedChanges(true);
    }

    function addOperationToFormula(selectedOperation: string) {
        if (selectedOperation === "left_parenthesis" || selectedOperation === "right_parenthesis") {
            setFormula([
                ...formula,
                { type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
            ]);
            setHasUnsavedChanges(true);
        }

        if (selectedOperation === "value") {
            setFormula([
                ...formula,
                { type: "value", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` },
            ]);
            setHasUnsavedChanges(true);
            return;
        }

        if (selectedOperation !== "operator") {
            setFormula([
                ...formula,
                { type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
            ]);
            setHasUnsavedChanges(true);
        }
    }

    function onClickCleanFormula() {
        setFormula([]);
        setSelectedOptionsList([]);
        onSaveFormula([]);
        validateIsSelectedLevel();
    }

    const validateIsSelectedLevel = () => {
        const isLevelSelected =
            formula.length === 0 ? null : formula.some((operation) => operation.key.split("-")[0] === "LEVEL");
        setIsSelectedLevel(isLevelSelected);
    };

    return (
        <div
            id="attribute_parameterization-new-attribute-modal-formula"
            className="attribute_parameterization-new-attribute-modal"
        >
            <div className="form-field">
                <OperationsSelect onChooseOperation={addOperationToFormula} hasValueOperator />
            </div>
            <div className="visualization-formula">
                <div className="header-buttons">
                    <h4>{i18n.t<string>("formula_view")}</h4>
                    <Popconfirm
                        placement="bottomLeft"
                        overlayClassName="popconfirm-delete"
                        title={i18n.t<string>("clear_formula_level")}
                        onConfirm={onClickCleanFormula}
                        okText={i18n.t<string>("yes")}
                        cancelText={i18n.t<string>("cancel")}
                        okButtonProps={{ danger: true, className: "popconfirm-delete-button" }}
                    >
                        <Button type="text">{i18n.t("clean")}</Button>
                    </Popconfirm>
                </div>

                <MultipleFormulaArea setFormula={setFormula} formula={formula} removeItem={removeItem} />

                <div className="footer-buttons">
                    <Button type="text" onClick={onCancelChangeFormulas.bind(this)}>
                        {i18n.t("cancel")}
                    </Button>
                    <Button
                        onClick={handleOnAcceptFormulaChanges}
                        disabled={!hasUnsavedChanges}
                        type="default"
                        className={`gs-secondary-button ${classNameForPulseButtonFormula}`}
                    >
                        {i18n.t("save")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
