import { useState } from "react";
import i18n from "util/base/i18n";
import { PermissionSecurityArea } from "./components/PermissionSecurityArea";
import { PermissionSecurityUserGroupTab } from "./components/PermissionSecurityUserGroupTab";
import { User } from "./IPermissionSecurity";

import './PermissionSecurity.sass';

export function PermissionSecurity() {
    const [selectedUser, setSelectedUser] = useState<User>({} as User);
    const [isSelectingAll, setIsSelectingAll] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'user' | 'group'>('group')
    const [selectedValue, setSelectedValue] = useState<number>(null);

    return (
        <>
            <div className="page-title-content">
                <h1>{i18n.t<string>("budget.permissions.security_permissions")}</h1>
            </div>
            <p style={{ marginLeft: "24px" }}>
                {i18n.t<string>("budget.permissions.change_and_manage_security_permissions")}
            </p>
            <main className={isSelectingAll ? "permission-security-container select-all" : "permission-security-container"}>
                <PermissionSecurityUserGroupTab
                    setSelectedUser={setSelectedUser}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
                <PermissionSecurityArea
                    selectedUser={selectedUser}
                    isSelectingAll={isSelectingAll}
                    setIsSelectingAll={setIsSelectingAll}
                    selectedTab={selectedTab}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                />
            </main>
        </>
    )
}