import { Button, Col, Row, Select, Modal, Table, Tabs, Popover } from "antd";
import { useEffect, useState } from "react";
import { useManagementTransferContext } from "../context/ManagementTransferContext";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { Notification } from "components/notification/Notification";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { IFlexFieldFreeze } from "../IManagementTransfer";
import { ImportTabContent } from "./ImportTabContent";
import { CenterCostLinkedTabContent } from "./CenterCostLinkedTabContent";
import { FlexFilter } from "util/types/types";

interface ManagementTransferSetupProps {
    isModalOpenSetup: boolean;
    setIsModalOpenSetup: (value: boolean) => void;
}

type CustomColumnType = {
    title?: string;
    dataIndex: string;
    key: string;
    width?: number;
    align?: "left" | "center" | "right";
    render?: (text: any, record: any) => React.ReactNode;
};

export default function ManagementTransferSetup({
    isModalOpenSetup,
    setIsModalOpenSetup,
}: ManagementTransferSetupProps) {
    const { flexFieldsFilters, dataFlexFieldFreeze, setDataFlexFieldFreeze, getFlexiFieldFreezedValues, dataCostCenterLiked,getCostCenterLinked } = useManagementTransferContext();
    const [activeTab, setActiveTab] = useState<string>("1");
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isOpenDeletePopover, setIsOpenDeletePopover] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<number | null>(null);

    useEffect(() => {
        getFlexiFieldFreezedValues();
        getCostCenterLinked();
    }, [isModalOpenSetup]);

    useEffect(() => {
        if (isModalOpenSetup) {
            setActiveTab("1");
        }
    }, [isModalOpenSetup]);

    const handleCancel = () => {
        setActiveTab("1");
        setDataFlexFieldFreeze(dataFlexFieldFreeze.filter(item => item.flexFieldId || item.flexFieldValueId));
        setIsModalOpenSetup(false);
    };

    const addFlexible = () => {
        const newItem = {
            id: null,
            flexFieldId: null,
            flexFieldValueId: null,
        };
        setDataFlexFieldFreeze([...dataFlexFieldFreeze, newItem]);
    };

    const handleNotifySuccess = () => {
        Notification({
            type: "success",
            message: isEditing ? i18n.t<string>("successfully_edited") : i18n.t<string>("successfully_saved"),
            duration: 3
        })
        if (isEditing) {
            setIsEditing(false);
        }
        getFlexiFieldFreezedValues();
    };
    
    const handleNotifyDelete = () => {
        Notification({
            type: "success",
            message: i18n.t<string>("successfully_deleted"),
            duration: 3
        });
        getFlexiFieldFreezedValues();
    };

    const handleSelectChange = (key: number, field: string, value: any) => {
        if (field === "flexFieldId") {

            const isDuplicate = dataFlexFieldFreeze.some(item => item.flexFieldId === value && item.id !== key);
            if (isDuplicate) {
                Notification({
                    type: "error",
                    message: i18n.t<string>("management_transfer.flexible_duplicate"),
                    duration: 3
                });
                return;
            }
        }
        
        const updatedData = dataFlexFieldFreeze.map((item) => {
            if (item.id === key) {
                if (field === "flexFieldId") {
                    return {
                        ...item,
                        flexFieldId: value,
                        flexFieldValueId: null,
                    };
                }
                return { ...item, [field]: value };
            }
            return item;
        });

        setDataFlexFieldFreeze(updatedData);

        const flexFieldId = updatedData.find(item => item.id === key)?.flexFieldId;
        const flexFieldValueId = updatedData.find(item => item.id === key)?.flexFieldValueId;
        const idSelected = dataFlexFieldFreeze.find(item => item.id === key)?.id;

        if (flexFieldId && flexFieldValueId) {
            const payload: IFlexFieldFreeze[] = [{
                id: isEditing ? idSelected : null,
                flexFieldId,
                flexFieldValueId
            }];
            ServiceCaller.doRequest({
                type: isEditing ? RequestType.PUT : RequestType.POST,
                url: "/expenses/flexFieldSelection",
                params: payload,
            }, handleNotifySuccess, handleErrorRequest);
        }
        setEditingKey(null);
    };

    const handleDelete = (key: number) => {
        const idSelected: number = dataFlexFieldFreeze.find(item => item.id === key)?.id;

        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/expenses/flexFieldSelection/${idSelected}`,
        }, handleNotifyDelete, handleErrorRequest);
    };

    const handleDeleteIconClick = (id) => {
        setIdToDelete(id);
        setIsOpenDeletePopover(true);
    };

    const handlePopoverClose = () => {
        setIsOpenDeletePopover(false);
        setIdToDelete(null);
    };

    const deletePopoverContent = (
        <div className="left-buttons-popover-container">
            <Button type="text" onClick={handlePopoverClose}>
                {i18n.t<string>("cancel")}
            </Button>
            <Button className="gs-main-button delete-button" onClick={() => {
                if (idToDelete) {
                    handleDelete(idToDelete);
                    handlePopoverClose();
                }
            }}>
                {i18n.t<string>("yes")}
            </Button>
        </div>
    );

    const selectProps = {
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
        filterSort: (optionA: FlexFilter, optionB: FlexFilter) =>
            (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
    };

    const columns: CustomColumnType[] = [
        {
            title: i18n.t<string>("flexible_field"),
            dataIndex: "flexFieldId",
            key: "flexFieldId",
            render: (text: any, record: any) => {
                const flexField = flexFieldsFilters.find(field => field.value === record.flexFieldId);
                return flexField ? (
                    <span>{flexField.label}</span>
                ) : (
                    <Select
                        style={{ width: "100%" }}
                        placeholder={i18n.t<string>("select")}
                        {...selectProps}
                        options={flexFieldsFilters.map((field) => ({ value: field.value, label: field.label }))}
                        onChange={(value) => handleSelectChange(record.id, "flexFieldId", value)}
                    />
                );
            },
        },
        {
            title: i18n.t<string>("flexible_value"),
            dataIndex: "flexFieldValueId",
            key: "flexFieldValueId",
            render: (text: any, record: any) => {
                const filteredChildren = flexFieldsFilters.find((field) => field.value === record.flexFieldId)?.allValues || [];
                const selectedChild = filteredChildren.find(child => child.fieldValueId === record.flexFieldValueId);

                if (editingKey === record.id) {
                    return (
                        <Select
                            style={{ width: "100%" }}
                            placeholder={i18n.t<string>("select")}
                            {...selectProps}
                            value={record.flexFieldValueId}
                            options={filteredChildren.map((child) => ({
                                value: child.fieldValueId,
                                label: `${child.externalCode} - ${child.description}`
                            }))}
                            onChange={(value) => handleSelectChange(record.id, "flexFieldValueId", value)}
                            disabled={!record.flexFieldId}
                        />
                    );
                }

                return selectedChild ? (
                    <span>{`${selectedChild.externalCode} - ${selectedChild.description}`}</span> 
                ) : (
                    <span>{i18n.t<string>("select")}</span>
                );
            },
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: 50,
            align: "center",
            render: (text: any, record: any) => (
                <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
                    <Popover
                        visible={isOpenDeletePopover && idToDelete === record.id}
                        overlayClassName="currencies-popover"
                        content={deletePopoverContent}
                        title={i18n.t<string>("delete_confirm_message")}
                        trigger="click"
                        placement="left"
                        onVisibleChange={(visible) => {
                            if (!visible) {
                                handlePopoverClose();
                            }
                        }}
                    >
                        <Icon
                            icon="icomoon-free:bin"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteIconClick(record.id);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Popover>
                    <Icon
                        icon="icomoon-free:pencil"
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditingKey(record.id);
                            setIsEditing(true);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
            )
        }
    ];

    const VerticalTabs = () => {
        const [activeTabKey, setActiveTabKey] = useState('1');
        const [isLinked, setIsLinked] = useState(true);

        const handleTabChange = (key) => {
            setActiveTabKey(key);
        };

        return (
            <Tabs
                activeKey={activeTabKey}
                onChange={handleTabChange}
                tabPosition="left"
                style={{ height: '100%' }}
            >
                <Tabs.TabPane
                    tab={i18n.t("import")}
                    key="1">
                    <ImportTabContent
                        type="excel"
                        importUrl={"/expenses/cost-center-account-parameterization/import"}
                        hasImportOption={false}
                        templateUrl={"/expenses/cost-center-account-parameterization/template?user={user}&client={client}&locale={locale}"}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={i18n.t("management_transfer.imported")}
                    key="2">
                    <CenterCostLinkedTabContent
                        dataCostCenterLiked={dataCostCenterLiked}
                        isLinked={isLinked}
                        setIsLinked={setIsLinked}
                    />
                </Tabs.TabPane>
            </Tabs>
        );
    };

    return (
        <Modal
            open={isModalOpenSetup}
            onCancel={handleCancel}
            title={i18n.t<string>("settings")}
            width={1000}
            className="modal-setup"
            maskClosable={false}
            keyboard={false}
            footer={[
                <Button key="ok" type="primary" onClick={() => setIsModalOpenSetup(false)}>
                    {i18n.t<string>("exit")}
                </Button>,
            ]}
        >
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <Tabs.TabPane
                    tab={i18n.t<string>("management_transfer.freeze_flexible")}
                    key="1"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Table
                                dataSource={dataFlexFieldFreeze}
                                columns={columns}
                                rowKey="id"
                                style={{ marginTop: 16 }}
                                bordered
                                pagination={false}
                            />
                        </Col>
                    </Row>
                    <div style={{ marginTop: 16, display: "flex", justifyContent: "flex-end" }}>
                        <Button type="primary" onClick={addFlexible}>{i18n.t<string>("add")}</Button>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t<string>("management_transfer.cost_center_link")} key="2">
                <VerticalTabs />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
}