import { Icon } from "@iconify/react";
import { Button, Col, Form, Row } from "antd";
import { CustomSelect } from "module/budget/pages/revenue/attributeParameterization/components/custom/CustomSelect";
import i18n from "util/base/i18n";
import { IPlanningLineFilterProps, IReportFilterSignal } from "../IReportRegistration";
import { filterOperationToSignal } from "../components/constants";

export default function PlanningLineFilter({
	account,
	costCenter,
	filterSignalType,
	setFilterSignalType,
	fromAccountingExternalCode,
	fromCostCenterExternalCode,
	setFromAccountingExternalCode,
	setFromCostCenterExternalCode,
	setToAccountingExternalCode,
	setToCostCenterExternalCode,
	toAccountingExternalCode,
	toCostCenterExternalCode
}: IPlanningLineFilterProps) {

	const invertSignal: Record<IReportFilterSignal, IReportFilterSignal> = {
		PLUS: IReportFilterSignal.MINUS,
		MINUS: IReportFilterSignal.PLUS
	}

	const getFilterOperation = (): string => {
		return filterOperationToSignal[filterSignalType];
	};

	const onClickChangeSignal = (currentSignal: IReportFilterSignal): void => {
		setFilterSignalType(invertSignal[currentSignal])
	}

	return (
		<Row className="report-planning-line-filter">
			<Row gutter={10}>
				<Col span={12}>
					<Form.Item>
						<CustomSelect
							hasSearch
							id="fromAccount"
							labelName={i18n.t("new_sales_report.from_account")}
							clearSelects
							options={account}
							onChange={setFromAccountingExternalCode}
							onClear={() => setFromAccountingExternalCode("")}
							value={fromAccountingExternalCode}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<CustomSelect
							hasSearch
							id="toAccount"
							labelName={i18n.t("till")}
							clearSelects
							options={account}
							onChange={setToAccountingExternalCode}
							onClear={() => setToAccountingExternalCode("")}
							value={toAccountingExternalCode}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={12}>
					<Form.Item>
						<CustomSelect
							hasSearch
							id="fromCostCenter"
							labelName={i18n.t("new_sales_report.from_cost_center")}
							clearSelects
							options={costCenter}
							onChange={setFromCostCenterExternalCode}
							onClear={() => setFromCostCenterExternalCode("")}
							value={fromCostCenterExternalCode}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<CustomSelect
							hasSearch
							id="toCostCenter"
							labelName={i18n.t("till")}
							clearSelects
							options={costCenter}
							onChange={setToCostCenterExternalCode}
							onClear={() => setToCostCenterExternalCode("")}
							value={toCostCenterExternalCode}
						/>
					</Form.Item>
				</Col>

			</Row>
			<Row>
				<Col className="signal-filter">
					<div >
						<p>{i18n.t("new_sales_report.signal")}</p>
						<Button
							className="gs-secondary-button"
							onClick={() => onClickChangeSignal(filterSignalType)}
						>
							<div className="gs-flex flex-row align-start"
								style={{ gap: 10, cursor: "pointer", alignItems: "center" }}
							>
								<span>
									{getFilterOperation()}
								</span>
								<Icon icon="ep:arrow-down" />
							</div>
						</Button>
					</div>
				</Col>
			</Row>
		</Row>
	)
}