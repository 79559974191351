import { Col, Input, Row, Select } from "antd";
import { useEffect, useMemo, useRef } from "react";
import i18n from "util/base/i18n";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ISearchType } from "../IDistributionRule";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import { IOption } from "../../revenue/attributeParameterization/IAttributeParameterization";
import { selectProps } from "util/props/props";
import { useUserContext } from "context/UserContext";

export function DistributionRuleMultipleSearch() {
	const {
		distributionRuleTypeSelected,
		searchType,
		searchValue,
		setDistributionRuleTypeSelected,
		setSearchType,
		setSearchValue,
		distributionGroupers,
		getTableData
	} = useDistributionRuleContext();
	const { userInfo: { selection: { scenarioId } } } = useUserContext();
	const searchRef = useRef(null);
	const timer = useRef<NodeJS.Timeout | null>(null);
	const groupersOptions: IOption[] = useMemo(() => {
		if (!distributionGroupers) return [];
		return Object.keys(distributionGroupers).map((key) => {
			const grouper: IOption = {
				value: key,
				label: distributionGroupers[key]
			}
			return grouper;
		})
	}, [distributionGroupers])

	const importProps: IImportModal[] = [
		{
			importUrl: "/software-house/distribution-rule/import/rules",
			templateUrl: "/software-house/distribution-rule/template/rules?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("distribution_rules.import_rules"),
		},
		{
			importUrl: "/software-house/distribution-rule/import/filters",
			templateUrl: "/software-house/distribution-rule/template/filters?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("distribution_rules.import_filters"),
		},
		{
			importUrl: "/software-house/distribution-rule/import/results",
			templateUrl: "/software-house/distribution-rule/template/results?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("distribution_rules.import_results"),
		},
		{
			importUrl: "/software-house/distribution-rule/import/values",
			templateUrl:"/software-house/distribution-rule/template/values?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("distribution_rules.import_values"),
		}
	]

	const optionsSearch: GenericSelection[] = [
		{
			label: `${i18n.t('search_by')} ${i18n.t("description")}`,
			value: ISearchType.DESCRIPTION
		},
		{
			label: `${i18n.t('search_by')} ${i18n.t("external_code")}`,
			value: ISearchType.EXTERNAL_CODE
		},
	]

	useEffect(() => {
		if (!scenarioId) return;
		timer.current = setTimeout(() => {
			getTableData(null, null, searchValue, searchType, distributionRuleTypeSelected);
		}, 800);
		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, [searchValue, searchType, distributionRuleTypeSelected, scenarioId]);

	function handleSearch(e: any) {
		const searchValue: string = e.target.value;

		setSearchValue(searchValue);
	}

	function onChangeSearchType(value: ISearchType): void {
		setSearchValue("");
		setSearchType(value);
	}

	return (
		<Row>
			<Col>
				<Select
					{...selectProps}
					className="select-search"
					options={groupersOptions}
					value={distributionRuleTypeSelected}
					onChange={setDistributionRuleTypeSelected}
					dropdownMatchSelectWidth={false}
				/>
			</Col>
			<Col className="multiple-search-content">
				<Input.Group compact>
					<Select
						className="select-search"
						value={searchType}
						dropdownMatchSelectWidth={false}
						onChange={onChangeSearchType}
						options={optionsSearch} />
					<Input
						className="search-field"
						ref={searchRef}
						onChange={handleSearch}
						value={searchValue}
						placeholder={i18n.t("search")}
					/>
				</Input.Group>
			</Col>
			<Col>
				<ImportExportMenu
					importProps={importProps}
				/>
			</Col>
		</Row>
	);
}
