import { Col, Form, Row, Space, Tooltip } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { BackButton } from "components/button/BackButton";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Notification } from "components/notification/Notification";
import { TopButtons } from "components/topButtons/TopButtons";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { Key, useEffect, useRef, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { queryClient } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest, FlexFieldValueListBean, Options } from "util/types/types";
import "./FlexFieldValues.sass";
import {
    DataToSave,
    FlexFieldValueProps,
    FlexFieldValuesData,
    FlexFieldValuesResponse,
    LinkedFlexFilters,
    LinkedFlexFiltersDetails,
    LinkedFlexFiltersResponse,
} from "./IFlexFieldValues";
import FlexFieldValuesModal from "./components/FlexFieldValuesModal";
import { FlexFieldValuesTable } from "./components/FlexFieldValuesTable";
import { SideFlexFieldList } from "./components/SideFlexFieldList";

export function FlexFieldValues({ toggleSection, flexFieldList, modules }: FlexFieldValueProps) {
    const [selectedRows, setSelectedRows] = useState<FlexFieldValuesData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedFlexField, setSelectedFlexField] = useState("");
    const [tableData, setTableData] = useState<FlexFieldValuesData[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewFlexFieldValues, setIsNewFlexFieldValues] = useState(true);
    const [isLoadingLinkedFilters, setIsLoadingLinkedFilters] = useState(false);
    const [linkedFlexFilters, setLinkedFlexFilters] = useState<LinkedFlexFilters[]>([]);
    const [linkedFlexDetails, setLinkedFlexDetails] = useState<LinkedFlexFilters[]>([]);
    const [isLoadingLinkedDetails, setIsLoadingLinkedDetails] = useState(false);
    const [LinkedFlexFiltersResponse, setLinkedFlexFiltersResponse] = useState<LinkedFlexFiltersResponse>([]);
    const [listToSave, setListToSave] = useState<FlexFieldValuesData[]>([]);
    const { userInfo } = useUserContext();
    const [filterdTableData, setFilterdTableData] = useState<FlexFieldValuesData[]>([]);
    const [isBusinessUnitVisible, setIsBusinessUnitVisible] = useState(false);
    const [isAccountingAccountVisible, setIsAccountingAccountVisible] = useState(false);
    const [isCostCenterVisible, setIsCostCenterVisible] = useState(false);
    const [isFlexFieldVisible, setIsFlexFieldVisible] = useState(false);
    const [isOrganizationVisible, setIsOrganizationVisible] = useState(false);
    const [form] = Form.useForm();
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [lastDescription, setLastDescription] = useState<string>('');

    const pagination: TablePaginationConfig = {
        current: currentPage,
        pageSize: currentPageSize,
        async onChange(page: number, pageSize: number) {
            setIsFetching(true);
            const dataResponse = await fetchFlexFieldValuesData(page, pageSize);
            setTableData(dataResponse.content);
            updatePaginationValues(dataResponse);
            setIsFetching(false);
        },
        total: totalItems,
        hideOnSinglePage: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50"],
        showTotal: (total) => `${total} itens`,
    };

    const tableColumns: ColumnsType<FlexFieldValuesData> = [
        {
            title: i18n.t<string>("external_code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "left",
            fixed: "left",
            width: 200,
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            fixed: "left",
            align: "left",
            width: 300,
        },
    ];
    const tableDateColumns: ColumnsType<FlexFieldValuesData> = [
        {
            title: i18n.t("creation_date"),
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 200,
            render: (text: string) => moment(text).format("DD/MM/YYYY"),
        },
        {
            title: i18n.t("inactivation_date"),
            dataIndex: "inactivationDate",
            key: "inactivationDate",
            align: "center",
            width: 200,
            render: (text: string) => (text ? moment(text).format("DD/MM/YYYY") : "-"),
        },
    ];

    const [columns, setColumns] = useState(tableColumns);

    const importProps: IImportModal[] = [
        {
            importUrl: "/budget-base/flex-field-value/import/value",
            templateUrl: "/budget-base/flex-field-value/import/value/template?locale={locale}",
            type: "excel",
            title: i18n.t("imports.import_flexible_field_value"),
        },
        {
            importUrl: `/budget-base/flex-field-value/import/detail`,
            templateUrl: `/budget-base/flex-field-value/import/details/template?flexFieldId=${selectedFlexField}&user={user}&client={client}&locale={locale}`,
            urlParams: `&flexFieldId=${selectedFlexField}`,
            type: "excel",
            title: i18n.t("import_flex_field_details"),
        },
    ];

    const [accountingList, setAccountingList] = useState<Options[]>([]);
    const [isFetchingAccounting, setisFetchingAccounting] = useState(true);

    const [costCenterList, setCostCenterList] = useState<Options[]>([]);
    const [isFetchingCostCenter, setisFetchingCostCenter] = useState(true);

    const [businessUnitList, setBusinessUnitList] = useState<Options[]>([]);
    const [isFetchingBusinessUnit, setisFetchingBusinessUnit] = useState(true);

    const [organizationList, setOrganizationList] = useState<Options[]>([]);
    const [isFetchingOrganization, setisFetchingOrganization] = useState(true);

    const updatePaginationValues = ({ totalElements, pageable: { pageSize, pageNumber } }: FlexFieldValuesResponse) => {
        setCurrentPageSize(pageSize);
        setCurrentPage(pageNumber + 1);
        setTotalItems(totalElements);
    };

    function onLoadAccounting(data: Options[]) {
        setAccountingList(data);
        setisFetchingAccounting(false);
    }

    function onLoadCostCenter(data: Options[]) {
        setCostCenterList(data);
        setisFetchingCostCenter(false);
    }

    function onLoadBusinessUnit(data: any[]) {
        setBusinessUnitList(
            data.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            })
        );
        setisFetchingBusinessUnit(false);
    }

    function onLoadOrganization(data: any[]) {
        setOrganizationList(
            data.map((organization) => {
                return {
                    label: organization.name,
                    value: organization.id,
                };
            })
        );
        setisFetchingOrganization(false);
    }

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-base/accounting-account?client={client}&locale={locale}&organization={organization}&bu={businessUnit}",
            },
            onLoadAccounting.bind(this)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-base/cost-center?client={client}&locale={locale}&organization={organization}&bu={businessUnit}",
            },
            onLoadCostCenter.bind(this)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/monolith/business-unit?client={client}&user={user}&locale={locale}",
            },
            onLoadBusinessUnit.bind(this)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/monolith/organization?client={client}&locale={locale}&user={user}",
            },
            onLoadOrganization
        );
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

	async function fetchFlexFieldValuesData(
        currentPageLocal: number = currentPage,
        pageSize: number = currentPageSize,
        value: string = null,
        optionSelected = null
    ) {
        if (!isFetching) setIsFetching(true);
        const { searchDescription, searchExternalCode, page } = getSearchCriteria(value, optionSelected, currentPageLocal);

        const flexField = await new Promise<any>((resolve, reject) => {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url:
                        `/budget-base/flex-field-value/find-flex-field-values-pageable?flexFieldId=${selectedFlexField}&page=${
                            page - 1
                        }&size=${pageSize}` +
                        (searchDescription ? `&description=${searchDescription}` : "") +
                        (searchExternalCode ? `&externalCode=${searchExternalCode}` : ""),
                },
                (data) => {
                    resolve(data);
                    updatePaginationValues(data);
                    setTableData(data.content);
                    setIsFetching(false);
                },
                (err) => {
                    handleErrorRequest(err);
                    reject(err);
                }
            );
        });
        return flexField;
    }

    function getSearchCriteria(value: string, optionSelected: any, page: number) {
        if (value === "") return { searchDescription: null, searchExternalCode: null, page };

        const valueToSend: string = value;
        const stringOption: string = optionSelected?.description || lastDescription;

        if (value && optionSelected) {
            handleNewSearchCriteria(valueToSend, stringOption);
            page = 1;
        }

        const { searchDescription, searchExternalCode } = extractSearchCriteria(valueToSend, stringOption);

        return { searchDescription, searchExternalCode, page };
    }

    function handleNewSearchCriteria(value: string, optionSelected: any) {
        if (value === "") {
            setLastDescription("");
        } else if (optionSelected) {
            setLastDescription(optionSelected);
        }
    }

    function extractSearchCriteria(value: string, optionSelected: any) {
        return {
            searchDescription: optionSelected === "description" ? value : null,
            searchExternalCode: optionSelected === "externalCode" ? value : null,
        };
    }

    function removeFlexQueries(data: FlexFieldValueListBean[] = []) {
        for (const item of data) {
            if (item.flexFieldFilters?.length > 0) {
                for (const id of item.flexFieldFilters) {
                    queryClient.removeQueries({
                        queryKey: ["flex-values-by-parent-value", id.toString()],
                        exact: false,
                    });
                }
            }
        }
    }

    async function onSaveFlexFieldValues(data: FlexFieldValueListBean) {
        Notification({
            type: "success",
            message: i18n.t<string>("successfully_saved"),
        });
        removeFlexQueries([data]);

        const dataResponse = await fetchFlexFieldValuesData();

        updatePaginationValues(dataResponse);

        setTableData(dataResponse.content);
        setIsFetching(false);
        setIsNewFlexFieldValues(true);
    }

    function handleOpenModal(isToEdit: boolean) {
        if (isToEdit) {
            setIsNewFlexFieldValues(false);
            const fieldToEdit = selectedRows[0];
            const flexFieldsLInked: {} = fieldToEdit.flexFieldValueListBean
                ? fieldToEdit.flexFieldValueListBean[0].list.reduce((acc, val) => {
                      const index = Object.keys(acc).findIndex((k) => k === `flexFieldId-${val.flexFieldId}`);
                      if (index !== -1) {
                          acc[`flexFieldId-${val.flexFieldId}`] = [
                              ...acc[`flexFieldId-${val.flexFieldId}`],
                              ...val.flexFieldFilters,
                          ];
                      } else {
                          acc[`flexFieldId-${val.flexFieldId}`] = val.flexFieldFilters;
                      }
                      return acc;
                  }, {})
                : {};
            const flexFieldsLInkedDetail: {} = fieldToEdit.flexFieldValueDetails
                ? fieldToEdit.flexFieldValueDetails.reduce((acc, val) => {
                      const index = Object.keys(acc).findIndex((k) => k === `detail-${val}`);
                      const detail = linkedFlexDetails.find(({ flexFieldValues }) =>
                          flexFieldValues.some(({ value }) => value === val)
                      );
                      if (index !== -1) {
                          acc[`detail-${detail.flexFieldId}`] = [...acc[`detail-${detail.flexFieldId}`], val];
                      } else {
                          acc[`detail-${detail.flexFieldId}`] = val;
                      }
                      return acc;
                  }, {})
                : {};
            form.setFieldsValue({
                externalCode: fieldToEdit.externalCode,
                description: fieldToEdit.description,
                inactivationDate: fieldToEdit.inactivationDate ? moment(fieldToEdit.inactivationDate) : undefined,
                businessUnitFilters: fieldToEdit.businessUnitFilters,
                accountingAccountFilters: fieldToEdit.accountingAccountFilters,
                costCenterFilters: fieldToEdit.costCenterFilters,
                organizationFilters: fieldToEdit.organizationFilters,
                ...flexFieldsLInked,
                ...flexFieldsLInkedDetail,
            });
        } else {
            setIsNewFlexFieldValues(true);
        }
        setIsModalVisible(true);
    }

    const FiltersActive = {
        BUSINESS_UNIT: true,
        ACCOUNTING_ACCOUNT: true,
        COST_CENTER: true,
        ORGANIZATION: true,
        default: false,
    };

    function setAllFilersToFalse() {
        setIsBusinessUnitVisible(false);
        setIsAccountingAccountVisible(false);
        setIsCostCenterVisible(false);
        setIsFlexFieldVisible(false);
        setIsOrganizationVisible(false);
    }

    function updateFiltersModal(data: string[]) {
        if (!data || data.length === 0) {
            setAllFilersToFalse();
            return;
        }
        setIsBusinessUnitVisible(FiltersActive[data.find((item) => item.includes("BUSINESS_UNIT"))]);
        setIsAccountingAccountVisible(FiltersActive[data.find((item) => item.includes("ACCOUNTING_ACCOUNT"))]);
        setIsCostCenterVisible(FiltersActive[data.find((item) => item.includes("COST_CENTER"))]);
        setIsOrganizationVisible(FiltersActive[data.find((item) => item.includes("ORGANIZATION"))]);
    }

    function handleSaveFlexFieldValues(data: FlexFieldValuesData[]) {
        if (data.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t<string>("empty_list"),
            });
            return;
        }

        setIsFetching(true);
        setIsModalVisible(false);
        const flexField = flexFieldList.find((flexField) => flexField.id.toString() === selectedFlexField);
        const dataToSave: DataToSave[] = data.map((flexFieldValues) => {
            const flexFieldOptionLinkedList = [];
            const flexFieldValueDetails: number[] = [];
            Object.keys(flexFieldValues).forEach((key) => {
                if (key.includes("flexFieldId")) {
                    const flexFieldId = key.split("-").pop();
                    flexFieldOptionLinkedList.push({
                        flexFieldId: Number(flexFieldId),
                        flexFieldValues: [...flexFieldValues[key]],
                    });
                }
                if (key.includes("detail")) {
                    flexFieldValueDetails.push(flexFieldValues[key]);
                }
            });

            const flexFieldValueListBean = flexFieldOptionLinkedList.map((flexField) => {
                const LinkedFlexFilter = LinkedFlexFiltersResponse.find(
                    (item) => item.flexFieldId === flexField.flexFieldId
                );
                const list = LinkedFlexFilter.list.filter((item) => flexField.flexFieldValues.includes(item.id));
                return { list };
            });

            let inactivationDate = flexFieldValues.inactivationDate ? moment(flexFieldValues.inactivationDate) : null;

            return {
                description: flexFieldValues.description,
                externalCode: flexFieldValues.externalCode,
                period: inactivationDate
                    ? inactivationDate.endOf("month").diff(moment().startOf("month"), "month")
                    : null,
                flexField,
                flexFieldId: Number(selectedFlexField),
                id: isNewFlexFieldValues ? null : selectedRows[0].id,
                clientId: userInfo.clientId,
                userId: userInfo.id,
                inactivationDate:
                    isNewFlexFieldValues ||
                    flexFieldValues.inactivationDate === undefined ||
                    flexFieldValues.inactivationDate === null
                        ? null
                        : inactivationDate.format("YYYY-MM-DD"),
                accountingAccountFilters: flexFieldValues.accountingAccountFilters,
                businessUnitFilters: flexFieldValues.businessUnitFilters,
                costCenterFilters: flexFieldValues.costCenterFilters,
                organizationFilters: flexFieldValues.organizationFilters,
                flexFieldFilters: [Number(selectedFlexField), ...flexFieldOptionLinkedList.map((ff) => ff.flexFieldId)],
                flexFieldValueListBean,
                flexFieldValueDetails: flexFieldValueDetails.flat(),
            };
        });

        setSelectedRows([]);
        setSelectedRowKeys([]);
        setListToSave([]);
        ServiceCaller.doRequest(
            {
                type: isNewFlexFieldValues ? RequestType.POST : RequestType.PUT,
                url: "/budget-base/flex-field-value",
                useProxy: true,
                params: isNewFlexFieldValues ? dataToSave : dataToSave[0],
            },
            onSaveFlexFieldValues,
            (err) => {
                setIsFetching(false);
                handleErrorRequest(err);
            }
        );
    }

    function handleAddItem(data: FlexFieldValuesData[]) {
        if (isNewFlexFieldValues) {
            setListToSave([...listToSave, data[0]]);
        } else {
            handleSaveFlexFieldValues(data);
        }
        form.resetFields();
    }

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: FlexFieldValuesData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    }

    function handleDelete() {
        const data = tableData.filter((item) => selectedRowKeys.includes(item.id)) as any;

        setIsFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.DELETE,
                url: `/budget-base/flex-field-value?ids=${selectedRowKeys.toString()}`,
            },
            () => onDelete(data as FlexFieldValueListBean[])
        );
    }

    function handleDeactivate(data) {
        if (selectedRows.length === 0) return;
        setIsFetching(true);

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/budget-base/flex-field-value/deactivate`,
                params: {
                    flexFieldsIds: selectedRowKeys,
                    inactivationDate: data.format("YYYY-MM-DD"),
                },
            },
            () => onDeactivate(),
            (err: ErrorRequest) => handleErrorRequest(err)
        );
    }

    async function onDeactivate() {
        const dataResponse = await fetchFlexFieldValuesData();
        setTableData(dataResponse.content);
        updatePaginationValues(dataResponse);
        setIsFetching(false);
        Notification({
            type: "success",
            message: "Desativados com sucesso!",
        });
    }

    async function onDelete(data: FlexFieldValueListBean[]) {
        removeFlexQueries(data);
        const dataResponse = await fetchFlexFieldValuesData();
        updatePaginationValues(dataResponse);
        setTableData(dataResponse.content);
        setIsFetching(false);
        Notification({
            type: "success",
            message: "Deletado com sucesso!",
        });
    }

    function handleCancel() {
        setSelectedRowKeys([]);
        setListToSave([]);
        setIsModalVisible(false);
        form.resetFields();
    }

    function onChangeSidebar() {
        setCurrentPageSize(10);
        setCurrentPage(1);
    }

    useEffect(() => {
        if (selectedFlexField === "") return;
        if (isFetchingAccounting || isFetchingBusinessUnit || isFetchingCostCenter) return;
        setTableData([]);
        setIsFetching(true);
        const fieldSelected = flexFieldList.find((item) => item.id.toString() === selectedFlexField);
        const fetchData = async () => {
            setIsLoadingLinkedDetails(true);
            setIsLoadingLinkedFilters(true);

            const dataResponse = await fetchFlexFieldValuesData();

            const flexField = dataResponse.content;

            updatePaginationValues(dataResponse);

            try {
                const data: LinkedFlexFiltersResponse = await new Promise<LinkedFlexFiltersResponse>(
                    (resolve, reject) => {
                        ServiceCaller.doRequest(
                            {
                                type: RequestType.GET,
                                url: `/budget-base/flex-field-value/find-filter-values?user={user}&flexFieldId=${selectedFlexField}`,
                            },
                            (responseData: LinkedFlexFiltersResponse) => {
                                resolve(responseData);
                            }
                        );
                    }
                );

                setIsLoadingLinkedFilters(false);
                let dataDetails: LinkedFlexFiltersDetails[] = [];
                if (fieldSelected.details?.length) {
                    dataDetails = await new Promise<LinkedFlexFiltersDetails[]>((resolve, reject) => {
                        ServiceCaller.doRequest(
                            {
                                type: RequestType.GET,
                                url: `/budget-base/flex-field-value/flex-all-detail-by-flex-field-id?flexFieldId=${selectedFlexField}`,
                            },
                            (responseData: LinkedFlexFiltersDetails[]) => {
                                resolve(responseData);
                            }
                        );
                    });
                }
                setIsLoadingLinkedDetails(false);
                if (!data.length) {
                    setLinkedFlexFilters([]);
                }
                if (!dataDetails.length) {
                    setLinkedFlexDetails([]);
                }

                if (!data.length && !dataDetails.length) {
                    let updatedColumns = [...tableColumns, ...tableDateColumns];

                    setColumns(updatedColumns);
                    setTableData(flexField);
                    setIsFetching(false);
                    return;
                }

                const updatedLinkedFlexFilters: LinkedFlexFilters[] = data.length
                    ? data.map((flexField) => {
                          const linkedFlexField = flexField.list.reduce((acc: LinkedFlexFilters, val) => {
                              if (Object.keys(acc).length === 0) {
                                  acc = {
                                      fieldCode: val.fieldCode,
                                      flexFieldDescription: val.flexFieldDescription,
                                      flexFieldId: val.flexFieldId,
                                      flexFieldValues: [
                                          {
                                              label: val.description,
                                              value: val.id,
                                          },
                                      ],
                                  };
                              } else {
                                  acc.flexFieldValues.push({
                                      label: val.description,
                                      value: val.id,
                                  });
                              }
                              return acc;
                          }, {} as LinkedFlexFilters);

                          return linkedFlexField;
                      })
                    : [];

                updatedLinkedFlexFilters.sort((a, b) => {
                    return Number(a.fieldCode.split("_").pop()) - Number(b.fieldCode.split("_").pop());
                });

                const updatedLinkedFlexDetails: LinkedFlexFilters[] = dataDetails.length
                    ? dataDetails.map((detail) => {
                          const newDetail: LinkedFlexFilters = {
                              fieldCode: "",
                              flexFieldDescription: detail.description,
                              flexFieldId: detail.id,
                              flexFieldValues: detail.linkedFlexFields.map(({ description, id }) => {
                                  return {
                                      label: description,
                                      value: id,
                                  };
                              }),
                          };
                          return newDetail;
                      })
                    : [];

                const formattedData: LinkedFlexFiltersResponse = data
                    .map((item) => {
                        return {
                            flexFieldId: item.list.length > 0 ? item.list[0].flexFieldId : null,
                            ...item,
                        };
                    })
                    .concat(updatedLinkedFlexDetails.map((item) => ({ flexFieldId: item.flexFieldId, list: [] })));

                setLinkedFlexFiltersResponse(formattedData);
                setLinkedFlexFilters(updatedLinkedFlexFilters);
                addLinkedFlexFilterToColumns(
                    updatedLinkedFlexFilters,
                    updatedLinkedFlexDetails.map((detail) => ({
                        ...detail,
                        flexFieldDescription: `${i18n.t("detail")} - ${detail.flexFieldDescription}`,
                    })),
                    flexField
                );
                setLinkedFlexDetails(updatedLinkedFlexDetails);
            } catch (error) {
                handleErrorRequest(error);
            }
        };

        fetchData();

        const filters: string[] = fieldSelected.filters;
        updateFiltersModal(filters);
    }, [selectedFlexField, isFetchingAccounting, isFetchingBusinessUnit, isFetchingCostCenter]);

    function addLinkedFlexFilterToColumns(
        updatedLinkedFlexFilters: LinkedFlexFilters[],
        updatedDetailsFlexFilters: LinkedFlexFilters[],
        flexField: FlexFieldValuesData[]
    ) {
        if (flexField.length === 0) {
            setIsFetching(false);
            return;
        }
        let updatedColumns = [...tableColumns];
        if (flexField[0].businessUnitFilters) {
            const businessUnitFilters = businessUnitList.filter((bu) =>
                flexField[0].businessUnitFilters.includes(bu.value)
            );
            updatedColumns.push({
                title: i18n.t("business_unit"),
                dataIndex: "businessUnitFilters",
                key: "BUSINESS_UNIT",
                align: "center",
                width: 200,
                render: () => {
                    return businessUnitFilters.map((aa) => aa.label);
                },
            });
        }
        if (flexField[0].costCenterFilters) {
            const costCenterFilters = costCenterList.filter((cc) => flexField[0].costCenterFilters.includes(cc.value));
            updatedColumns.push({
                title: i18n.t("cost_center"),
                dataIndex: "costCenterFilters",
                key: "COST_CENTER",
                align: "center",
                width: 200,
                render: () => {
                    return costCenterFilters.map((aa) => aa.label);
                },
            });
        }
        if (flexField[0].accountingAccountFilters) {
            const accountingAccountFilters = accountingList.filter((aa) =>
                flexField[0].accountingAccountFilters.includes(aa.value)
            );
            updatedColumns.push({
                title: i18n.t("accounting_account"),
                dataIndex: "accountingAccountFilters",
                key: "ACCOUNTING_ACCOUNT",
                align: "center",
                width: 200,
                render: () => {
                    return accountingAccountFilters.map((aa) => aa.label);
                },
            });
        }
        if (flexField[0].organizationFilters) {
            const organizationFilters = organizationList.filter((aa) =>
                flexField[0].organizationFilters.includes(aa.value)
            );
            updatedColumns.push({
                title: i18n.t("organization"),
                dataIndex: "organizationFilters",
                key: "ORGANIZATION",
                align: "center",
                width: 200,
                render: () => {
                    return organizationFilters.map((aa) => aa.label);
                },
            });
        }
        updatedLinkedFlexFilters.forEach((flexFilter) => {
            updatedColumns.push({
                title: flexFilter.flexFieldDescription,
                dataIndex: flexFilter.flexFieldDescription,
                key: flexFilter.flexFieldDescription,
                align: "center",
                width: 200,
                render: (text: string[], record) => {
                    let flexFieldValueFilterValues = record.flexFieldValueListBean
                        ? record.flexFieldValueListBean[0].list.flatMap((filter) => filter.flexFieldFilters)
                        : [];

                    let flexFieldFilterValues = flexFilter.flexFieldValues.filter((flexFieldValue) =>
                        flexFieldValueFilterValues.includes(flexFieldValue.value)
                    );

                    return (
                        <>
                            {flexFieldFilterValues[0]?.label}
                            {flexFieldFilterValues?.length > 1 && (
                                <Tooltip
                                    arrowPointAtCenter
                                    color={"rgba(0,0,0,0.9"}
                                    placement="right"
                                    title={flexFieldFilterValues.map((flexField) => (
                                        <p key={flexField.value} style={{ margin: 0 }}>
                                            {i18n.t<string>(flexField.label)}
                                        </p>
                                    ))}
                                >
                                    <span className="additional-items">{`+${flexFieldFilterValues.length - 1}`}</span>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            });
        });

        updatedDetailsFlexFilters.forEach((detail) => {
            updatedColumns.push({
                title: detail.flexFieldDescription,
                dataIndex: detail.flexFieldDescription,
                key: detail.flexFieldDescription,
                align: "center",
                width: 200,
                render: (text: string[], record) => {
                    let flexFieldValueFilterValues = record.flexFieldValueDetails?.length
                        ? record.flexFieldValueDetails
                        : [];

                    let flexFieldFilterValues = detail.flexFieldValues.filter((flexFieldValue) =>
                        flexFieldValueFilterValues.includes(flexFieldValue.value)
                    );

                    return (
                        <>
                            {flexFieldFilterValues[0]?.label}
                            {flexFieldFilterValues?.length > 1 && (
                                <Tooltip
                                    arrowPointAtCenter
                                    color={"rgba(0,0,0,0.9"}
                                    placement="right"
                                    title={flexFieldFilterValues.map((flexField) => (
                                        <p key={flexField.value} style={{ margin: 0 }}>
                                            {i18n.t<string>(flexField.label)}
                                        </p>
                                    ))}
                                >
                                    <span className="additional-items">{`+${flexFieldFilterValues.length - 1}`}</span>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            });
        });

        updatedColumns.push(...tableDateColumns);

        setTableData(flexField);
        setColumns(updatedColumns);
        setIsFetching(false);
    }

    function onRequestToSearch(optionSelected, value) {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(async () => {
            await fetchFlexFieldValuesData(currentPage, currentPageSize, value, optionSelected);
        }, 600);
    }

    const translatedTable = () => {
        if (tableData.length === 0) return tableData;
    
        return tableData.map((item) => {
            let details = null;
            if (item.flexFieldValueDetails && item.details) {
                details = item.flexFieldValueDetails.map((flexFieldId) => {
                    const detail = item.details.find((detail) => detail.id === flexFieldId);
                    return detail ? { id: flexFieldId, description: detail.description, flexFieldDescription: detail.flexFieldDescription } : null;
                }).filter(Boolean);
            }
    
            let baseItem = {};

            columns.forEach((column) => {
                const detailsFind = details?.find((detail) => `${i18n.t("detail")} - ${detail.flexFieldDescription}` === column.key);
                
                baseItem[column.key] = detailsFind ? detailsFind.description : null;
            });
            
            columns.forEach((column) => {
                if (!(column.key in baseItem)) {
                    baseItem[column.key] = null;
                }
            });

            baseItem = {
                ...baseItem,
                externalCode: item.externalCode,
                description: item.description,
                createdAt: item.creationDate,
                inactivationDate: item.inactivationDate,
            };
    
            return baseItem;
        });
    };

    return (
        <div className="flex-field-value">
            <div className="page-title-content" id="flex-field-values-title">
                <div className="gs-flex flex-col align-start">
                    <h1>{i18n.t("flexField.values_flexible_fields")}</h1>
                    <BackButton onGoBack={() => toggleSection((state) => !state)} />
                </div>
            </div>
            <Row>
                <Col className="sider-content" span={5}>
                    <SideFlexFieldList
                        flexFieldList={flexFieldList}
                        setSelectedFlexField={setSelectedFlexField}
                        selectedFlexField={selectedFlexField}
                        modules={modules}
                        onChangeSidebar={onChangeSidebar}
                    />
                </Col>
                <Col span={19}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <div id="top-buttons-flex-field">
                            <div>
                                <TopButtons
                                    mainButtonTitle={i18n.t("add")}
                                    handleNew={() => handleOpenModal(false)}
                                    handleEdit={() => handleOpenModal(true)}
                                    handleDelete={() => handleDelete()}
                                    handleDeactivate={(data) => handleDeactivate(data)}
                                    isDeletable={selectedRowKeys.length > 0}
                                    isEditable={selectedRowKeys.length === 1}
                                    isDeactivatable={selectedRowKeys.length > 0}
                                    disabledMainButton={selectedFlexField.length === 0}
                                    multipleSearch={{
                                        tableData: tableData,
										onChange: onRequestToSearch,
                                        options: [
                                            { i18nString: "external_code", description: "externalCode" },
                                            { i18nString: "description", description: "description" },
                                        ],
                                    }}
                                />
                            </div>
                            <ImportExportMenu
                                exportGridData={() =>
                                    handleExportGridData(
                                        translatedTable(),
                                        columns.map(col => col?.render ? { ...col, render: undefined } : col),
                                        i18n.t("imports.field_flexible_values")
                                    )
                                }
                                importModalOpen={importModalOpen}
                                setImportModalOpen={setImportModalOpen}
                                importProps={importProps}
                                buttonType="3dots"
                            />
                        </div>
                        <FlexFieldValuesTable
                            isFetching={isFetching}
                            tableData={filterdTableData}
                            onChange={onSelectRowChange}
                            selectedRowKeys={selectedRowKeys}
                            tableColumns={columns}
                            pagination={pagination}
                        />
                    </Space>
                </Col>
            </Row>
            <FlexFieldValuesModal
                form={form}
                handleSave={handleSaveFlexFieldValues}
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                isNewFlexFieldValues={isNewFlexFieldValues}
                handleSubmit={handleAddItem}
                listToSave={listToSave}
                setListToSave={setListToSave}
                tableData={filterdTableData}
                isAccountingAccountVisible={isAccountingAccountVisible}
                isBusinessUnitVisible={isBusinessUnitVisible}
                isCostCenterVisible={isCostCenterVisible}
                isFlexFieldVisible={isFlexFieldVisible}
                linkedFlexFilters={linkedFlexFilters}
                isLoadingLinkedFilters={isLoadingLinkedFilters}
                selectedRowKeys={selectedRowKeys}
                accountingList={accountingList}
                isFetchingAccounting={isFetchingAccounting}
                costCenterList={costCenterList}
                isFetchingCostCenter={isFetchingCostCenter}
                businessUnitList={businessUnitList}
                isFetchingBusinessUnit={isFetchingBusinessUnit}
                isFetchingOrganizationList={isFetchingOrganization}
                organizationList={organizationList}
                isOrganizationVisible={isOrganizationVisible}
                isLoadingLinkedDetails={isLoadingLinkedDetails}
                linkedFlexDetails={linkedFlexDetails}
            />
        </div>
    );
}
