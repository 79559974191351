import { useEffect } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { stringToSignalType } from "../components/constants";
import { useNewReportContext } from "../context/ReportRegistrationContext";
import { useFormulaContext } from "../formula/context/FormulaContext";
import { FormulaAttribute, LevelReport, OptionSelect, Report } from "../IReportRegistration";
import { HeaderNewReport } from "./components/HeaderNewReport";
import StepOne from "./stepOne/StepOne";
import StepThree from "./stepThree/StepThree";
import StepTwo from "./stepTwo/StepTwo";

interface HeaderNewReportProps {
    handleSetIsOnNewReport: (isOnNewReport: boolean) => void;
}

export const NewReport: React.FC<HeaderNewReportProps> = (props) => {
    const { report, setReport, reportType, setReportType, idReportForEdit, isPlanningReport, current, setCurrent } =
        useNewReportContext();

    const { optionsForFormula, setOptionsForFormula } = useFormulaContext();

    useEffect(() => {
        if (!idReportForEdit) return;
        fetchReports();
    }, [idReportForEdit]);

    useEffect(() => {
        if (!reportType) {
            return;
        }

        if (isPlanningReport()) {
            onLoadFindLevels([]);
            return;
        }

        if (reportType === "REVENUE") {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: "revenue/grouper/attribute?loadFormulaAttribute=true",
                },
                onLoadFindLevels,
                handleErrorRequest
            );
        }
    }, [reportType]);

    useEffect(() => {
        const updateOptions = (options: OptionSelect[]): OptionSelect[] => {
            let newOptions: OptionSelect[] = [];
            options.forEach((option) => {
                if (option && option.value.includes("ATTRIBUTE-")) {
                    newOptions.push(option);
                }
            });
            let formattedLevels: OptionSelect[] = flattenHierarchy(report?.levels);
            newOptions = newOptions.concat(formattedLevels);
            return newOptions.filter((value) => value);
        };

        setOptionsForFormula(updateOptions(optionsForFormula));
    }, [report]);

    function onLoadFindLevels(levels: FormulaAttribute[]) {
        const attributesOptions: OptionSelect[] = levels.map(({ name, id, formulaAttribute, grouperName }) => {
            const labelAttribute: string = formulaAttribute
                ? i18n.t(name)
                : `${i18n.t(grouperName.toString().toLowerCase())} - ${name} `;
            return {
                value: `ATTRIBUTE-${id}`,
                label: labelAttribute,
            };
        });
        let level: OptionSelect[] = [];
        report?.levels.forEach(({ id, description, externalCode, title }) => {
            if (title) return;
            level.push({
                value: `LEVEL-${id}`,
                label: `${externalCode} - ${description}`,
            });
        });
        const options: OptionSelect[] = attributesOptions.concat();
        setOptionsForFormula(options);
    }

    function onLoadEdit(data: Report) {
        setReportType(data.type);
        setReport({
            ...data,
            reportType: data.reportType,
            levels: sortLevels(data.levels),
        });
        setCurrent(0);
    }

    const flattenHierarchy = (lines: LevelReport[] = []): OptionSelect[] => {
        let newLevels: OptionSelect[] = [];

        lines.forEach(({ externalCode, description, children, id, title }) => {
            if (title) return;
            newLevels.push({
                value: `LEVEL-${id}`,
                label: `${externalCode} - ${description}`,
            });
            if (children?.length) {
                newLevels = newLevels.concat(flattenHierarchy(children));
            }
        });
        return newLevels;
    };

    function sortLevels(levels: LevelReport[]): LevelReport[] {
        return levels
            .map((line) => {
                if (line.subLevels.length) {
                    line.children = sortLevels(line.subLevels);
                }
                line.isSubLevel = line.upperLevelId ? true : false;
                line.levelFormula = {
                    belongsToId: line.id,
                    formulas: line.formulas,
                };
                line.levelStyle = { ...line.levelStyle, signalType: stringToSignalType[line.levelStyle.signalType] };
                line.isSubLevel = line.upperLevelId ? true : false;

                return { ...line, key: line.ordination };
            })
            .sort((a, b) => (a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1));
    }

    function fetchReports() {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `budget-report/report/find/${idReportForEdit}`,
            },
            onLoadEdit
        );
    }

    const renderStep = {
        0: <StepOne sortLevels={sortLevels} setReportType={setReportType} />,
        1: <StepTwo fetchReports={fetchReports} />,
        2: <StepThree />,
    };

    const setIsOnNewReport = (isOnNewReport: boolean) => {
        props.handleSetIsOnNewReport(isOnNewReport);
    };

    return (
        <div className="new-report-page">
            <HeaderNewReport handleSetIsOnNewReport={setIsOnNewReport} />
            {report && <main>{renderStep[current]}</main>}
        </div>
    );
};
