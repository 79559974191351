import { FormInstance } from "antd";
import { IRuleOperationFromRequest } from "../attributeParameterization/components/modals/rule/components/IModalFactory";
import {
    FlexFieldValue,
    ICondition,
    ILevel,
    ILevelsValue,
    RuleConditionOperations,
} from "../attributeParameterization/IAttributeParameterization";

export interface ITableData {
    description: string;
    id: number;
}

export interface LevelReport {
    externalCode: string;
    isSubLevel?: boolean;
    ordination: number;
    description: string;
    invisible: boolean;
    title: boolean;
    informative: boolean;
    children: LevelReport[];
    levelStyle?: ILevelStyle;
    key: number;
    id?: number;
    subLevels?: LevelReport[];
    upperLevelId?: number;
    levelFormula?: LevelFormula;
    levelFilter?: LevelFilter;
    formulas: Formula[];
    filters?: ICondition[];
}

export interface LevelFormula {
    belongsToId: number;
    formulas: Formula[];
}

export interface LevelFilter {
    belongsToId: number;
    filters: Filters[];
}

export interface Filters {
    id?: number;
    operation?: RuleConditionOperations;
    flexFieldId?: number;
    flexFieldValueId?: number;
    filterType?: FilterType;
    managementCostCenterId?: number;
    managementAccountingAccountId?: number;
    filterPlanning?: LevelFilterPlanning;
}

export enum FilterType {
    FLEX_FIELD,
    FROM_TO,
}

export interface ILevelStyle {
    color: string;
    colorFont: string;
    isBold: boolean;
    isItalic: boolean;
    isUnderlined: boolean;
    isScratched: boolean;
    id?: number;
    fontSize: number;
    signalType?: SignalType;
}

export interface Report {
    description: string;
    levels: LevelReport[];
    id?: number;
    reportType?: ReportType;
    type?: ReportType;
}

export interface Formula {
    id?: number;
    ordination: number;
    type: string;
    value?: string;
    operator?: string;
    levelId?: number;
    attributeId?: number;
    name?: string;
}

export enum FormulaType {
    INFORMED_VALUE = "INFORMED_VALUE",
    OPERATORS = "OPERATORS",
    LEVEL = "LEVEL",
    ATTRIBUTE = "ATTRIBUTE",
    OPENING_BALANCE = "OPENING_BALANCE",
    FINAL_BALANCE = "FINAL_BALANCE",
    MOVEMENT = "MOVEMENT",
    DEBIT = "DEBIT",
    CREDIT = "CREDIT",
    VALUE = "VALUE",
}

export interface IconIsVisible {
    isIconDownVisible: boolean;
    isIconUpVisible: boolean;
}

export interface IStepOneProps {
    sortLevels: (levels: LevelReport[]) => LevelReport[];
    setReportType: React.Dispatch<React.SetStateAction<ReportType>>;
}

export interface IStepTwoProps {
    fetchReports: () => void;
}

export interface ITableLevelsProps {
    selectedRowKeys: number[];
    tableData: LevelReport[];
    setTableData: React.Dispatch<React.SetStateAction<LevelReport[]>>;
    onChange: (selectRowKeys: number[], selectedRows: ITableData[]) => void;
    setHasSavedValue: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
    isLoadingTableData: boolean;
}

export interface IOverflowColorProps {
    isVisible: boolean;
    setIsVisible: (isVisible: number) => void;
    selected: ILevelStyle;
    index: number;
    saveNewStyle: (newStyle: ILevelStyle) => void;
}

export interface IFontStylePickerProps {
    isBoldSelected: boolean;
    setIsBoldSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isItalicSelected: boolean;
    setIsItalicSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isUnderlinedSelected: boolean;
    setIsUnderlinedSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isScratchedSelected: boolean;
    setIsScratchedSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum FormulaGrouperName {
    FIXED_EXPENSE,
    VARIABLE_EXPENSES,
    SALE_DEDUCTION,
    MARKETING_COST,
    MARKETING_COST_VARIABLE,
    MARKETING_COST_FIXED,
}

export interface FormulaAttribute {
    id: number;
    grouperId: number;
    name: string;
    grouperName: FormulaGrouperName;
    formulaAttribute: boolean;
}

export interface FontSizeOptions {
    value: number;
    label: string;
}

export interface ChooseIconOperation {
    key: number;
    removeItem: (index: number) => void;
    position: number;
}

export enum ReportType {
    REVENUE,
    PLANNING,
}

export interface INewReportModalProps {
    isNewReportModalOpen: boolean;
    setIsNewReportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum SignalType {
    ORIGINAL,
    POSITIVE,
    NEGATIVE,
}

export interface OptionsFilter {
    label: string;
    value: string;
}

export interface IReportRegistrationContext {
    reportType: ReportType | string;
    setReportType: (reportType: ReportType) => void;
    resetReportType: () => void;
    isPlanningReport: (type?: ReportType) => boolean;
    report: Report;
    setReport: (report: Report) => void;
    selectedRowKey: number;
    setSelectedRowKey: (selectedRowKey: number) => void;
    selectedRowKeys: number[];
    setSelectedRowKeys: (selectedRowKeys: number[]) => void;
    selectedRowFormula: number;
    setSelectedRowFormula: (selectedFormula: number) => void;
    onSaveReport: (reportSelected: string | number, description: string) => Promise<Report>;
    isSavingReport: boolean;
    isNewProjection: boolean;
    setIsNewProjection: (newProjection: boolean) => void;
    isFetching: boolean;
    setIsFetching: (newProjection: boolean) => void;
    tableData: ITableData[];
    setTableData: (tableData: ITableData[]) => void;
    idReportForEdit: number;
    setIdReportForEdit: (idReportForEdit: number) => void;
    isEditingReport: boolean;
    setIsEditingReport: (isEditingReport: boolean) => void;
    current: number;
    setCurrent: (current: number) => void;
    getTableData: () => void;
}

export interface IFiltersResponse {
    id: number;
    flexField: IRuleOperationFromRequest;
    operation: RuleConditionOperations;
    flexFieldValue: IRuleOperationFromRequest;
    managementCostCenter: IRuleOperationFromRequest;
    managementAccounting: IRuleOperationFromRequest;
    fromAccounting: ILevelExternalCodeResponse;
    fromCostCenter: ILevelExternalCodeResponse;
    toAccounting: ILevelExternalCodeResponse;
    toCostCenter: ILevelExternalCodeResponse;
    signal: IReportFilterSignal;
}

export interface IPlanningLineFilterProps {
    costCenter: OptionsFilter[];
    account: OptionsFilter[];
    filterSignalType: IReportFilterSignal;
    setFilterSignalType: React.Dispatch<React.SetStateAction<IReportFilterSignal>>;
    fromCostCenterExternalCode: string;
    toCostCenterExternalCode: string;
    fromAccountingExternalCode: string;
    toAccountingExternalCode: string;
    setFromCostCenterExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setToCostCenterExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setFromAccountingExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setToAccountingExternalCode: React.Dispatch<React.SetStateAction<string>>;
}

export enum IReportFilterSignal {
    PLUS = "PLUS",
    MINUS = "MINUS",
}
export interface LevelFilterPlanning {
    fromCostCenterExternalCode: string;
    toCostCenterExternalCode: string;
    fromAccountingExternalCode: string;
    toAccountingExternalCode: string;
    signal: IReportFilterSignal;
    id: number;
}

export interface IPlanningConditionProps {
    conditions: LevelFilterPlanning[];
    handleRemove: (id: number) => void;
    handleEdit: (id: number) => void;
}

export interface ILevelExternalCodeResponse {
    id: string;
    name: string;
}

export interface FilterProps {
    levelsValue: ILevelsValue[];
    setLevelsValue: React.Dispatch<React.SetStateAction<ILevelsValue[]>>;
    setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    levels: ILevel;
    setLevels: React.Dispatch<React.SetStateAction<ILevel>>;
    operations: RuleConditionOperations;
    setOperations: React.Dispatch<React.SetStateAction<RuleConditionOperations>>;
    availableOperations: any[];
    setAvailableOperations: React.Dispatch<React.SetStateAction<[]>>;
    conditions: ICondition[];
    setConditions: React.Dispatch<React.SetStateAction<ICondition[]>>;
    flexFieldsValues: FlexFieldValue[];
    setFlexFieldsValues: React.Dispatch<React.SetStateAction<FlexFieldValue[]>>;
    filterType: FilterType;
    setFilterType: React.Dispatch<React.SetStateAction<FilterType>>;
}

export interface IFormulaContext {
    form: FormInstance<any>;
    formula: FormulaItem[];
    setFormula: (formula: FormulaItem[]) => void;
    selectedOptionsList: string[];
    setSelectedOptionsList: (optionsList: string[]) => void;
    allListFormula: Map<number, LevelReport>;
    setAllListFormula: (allListFormula: Map<number, LevelReport>) => void;
    updateOptionsRecursively: string;
    setUpdateOptionsRecursively: (indexLevel: string) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    planningCondition: LevelFilterPlanning[];
    setPlanningCondition: (planningCondition: LevelFilterPlanning[]) => void;
    optionsForFormula: OptionSelect[];
    setOptionsForFormula: (optionsForFormula: OptionSelect[]) => void;
    selectedRowFormulasFormated: FormulaItem[];
    setSelectedRowFormulasFormated: (formula: FormulaItem[]) => void;
    levels: ILevel;
    setLevels: (levels: ILevel) => void;
    pendingLevelSelected: LevelReport;
    setPendingLevelSelected: (level: LevelReport) => void;
    selectedRowFilters: ICondition[];
    setSelectedRowFilters: (filters: ICondition[]) => void;
    selectRowPlanningFilters: LevelFilterPlanning[];
    setSelectedRowPlanningFilters: (filters: LevelFilterPlanning[]) => void;
    hasUnsavedChanges: boolean;
    setHasUnsavedChanges: (hasUnsaved: boolean) => void;
    hasSavedValue: boolean;
    setHasSavedValue: (hasSaved: boolean) => void;
    levelsValue: ILevelsValue[];
    setLevelsValue: (values: ILevelsValue[]) => void;
    operations: RuleConditionOperations;
    setOperations: (operations: RuleConditionOperations) => void;
    classNameForPulseButtonFilter: string;
    setClassNameForPulseButtonFilter: (className: string) => void;
    classNameForPulseButtonFormula: string;
    setClassNameForPulseButtonFormula: (className: string) => void;
    filterType: FilterType;
    setFilterType: (type: FilterType) => void;
    availableOperations: any[];
    setAvailableOperations: (any: any[]) => void;
    conditions: ICondition[];
    setConditions: (conditions: ICondition[]) => void;
    flexFieldsValues: FlexFieldValue[];
    setFlexFieldsValues: (values: FlexFieldValue[]) => void;
    hasRemovedLevel: boolean;
    setHasRemovedLevel: (value: boolean) => void;
    onAcceptFormulaChanges: () => void;
    validateLevelsOnFormula: (value: string) => void;
    validateFormulaIsEqual: (levelFormula: LevelFormula) => boolean;
    validateFilter: () => boolean;
    handleCancelPendingLevel: () => void;
    onSaveFormula: (formula: Formula[]) => void;
    onCancelChangeFormulas: () => void;
    lines: Line[];
    setLines: (lines: Line[]) => void;
    isSelectedLevel: Boolean;
    setIsSelectedLevel: (value: Boolean) => void;
}

export interface Line {
    id: number;
    name: string;
    subLines: Line[];
    belongsLines?: Line[];
}

export interface CheckboxOptionFormula {
    type: string;
    key: string | number;
    content: {
        id: string | number;
        name: string;
    };
}

export interface FormulaItem {
    key?: any
    type: "value" | "operator" | "attribute";
    content: any;
    value?: number;
    levelId?: number;
}

export type OptionSelect = {
    value: FormulaType | string,
    label: string
}