import { Row } from "antd";
import { TopButtons } from "components/topButtons/TopButtons";
import { useManagementTransferContext } from "./context/ManagementTransferContext";
import i18n from "util/base/i18n";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { Icon } from "@iconify/react";
import { useState } from "react";
import ManagementTransferSetup from "./components/ManagementTransferSetup";
import { FiltersManagementTransfer } from "./components/FiltersManagementTransfer";
import { usePermissionContext } from "context/PermissionContext";

export function Title() {

    const {
        selectedRowKeys,
        handleOpenModal,
        handleDelete,
        handleEdit,
        defaultSearchOptions,
        filteredData,
        setFilteredData,
        handleSearch,
    } = useManagementTransferContext();

    const { functionalityPermissions } = usePermissionContext();

    const [isModalOpenSetup, setIsModalOpenSetup] = useState(false);

    const customButtons: CustomButtons[] = [
        ...(functionalityPermissions.settings ? [{
            toolTip: i18n.t<string>("management_transfer.transfer_settings"),
            icon: <Icon icon="icon-park-solid:config" width="22" height="22" />,
            onClick: () => setIsModalOpenSetup(true)
        }] : [])
    ];

    return (
        <Row>
            <Row className="page-title-content">
                <h1>{i18n.t<string>("management_transfer.title")}</h1>
            </Row>
            <Row
                className="filter-container"
                style={{ margin: '0 25px' }}
            >

                <FiltersManagementTransfer />

            </Row>
            <Row>
                <TopButtons
                    mainButtonTitle={i18n.t<string>("management_transfer.new_transfer")}
                    handleNew={handleOpenModal}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    isDeletable={selectedRowKeys.length > 0}
                    isEditable={selectedRowKeys.length === 1}
                    customButtons={customButtons}
                    multipleSearch={{
                        options: defaultSearchOptions,
                        tableData: filteredData,
                        setTableData: setFilteredData,
                        onChange: (option, value) => {
                            handleSearch(option, value);
                        }
                    }}
                />
            </Row>
            <ManagementTransferSetup
                isModalOpenSetup={isModalOpenSetup}
                setIsModalOpenSetup={setIsModalOpenSetup}
            />
        </Row>
    );
}