import { CopyOutlined } from "@ant-design/icons";
import { Button, Carousel, Modal } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { Filter } from "components/filter/Filter";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Notification } from "components/notification/Notification";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useUserContext } from "context/UserContext";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { onChangeFlexFieldFilter } from "util/functions/onChangeFlexFieldFilter";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { apportionmentOptions, type } from "../../../constants";
import { ApportionmentType, IFilters } from "../../../IApportionment";
import { usePermissionContext } from "context/PermissionContext";

export function Filters({
    businessUnitOptions,
    costCenterOptions,
    flexFieldsOriginOptions,
    setFlexFieldsOriginOptions,
    fetchApportionments,
    openCalendarPeriod,
    year,
    setYear,
    currentPage,
    currentPageSize,
}: IFilters) {
    const [apportionmentType, setApportionmentType] = useState<Options[]>([]);
    const [businessUnit, setBusinessUnit] = useState<Options[]>([]);
    const [costCenter, setCostCenter] = useState<Options[]>([]);
    const [account, setAccount] = useState<Options[]>([]);
    const [flexFieldStringKey, setFlexFieldStringKey] = useState(Array(30).join(":"));
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(false);
    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const windowSize = useWindowSize();
    const [optionsAccounting, setOptionsAccounting] = useState<Options[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [originYear, setOriginYear] = useState<any>([{ year: () => new Date().getFullYear() }]);
    const [destinationYear, setDestinationYear] = useState<any>([{ year: () => new Date().getFullYear() }]);

    const { userInfo } = useUserContext()
    const { functionalityPermissions } = usePermissionContext();

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 288) / 195);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}`,
            },
            (data) => {
                setOptionsAccounting(data);
            }
        );
    }, []);

    useEffect(() => {
        fetchApportionments(
            type[apportionmentType[0]?.value] ?? ApportionmentType.ALL,
            businessUnit[0]?.value ?? 0,
            costCenter[0]?.value ?? 0,
            account[0]?.value ?? 0,
            flexFieldStringKey,
            year[0].year()
        )
    }, [currentPage, currentPageSize])

    const importProps: IImportModal[] = [
        {
            importUrl: "/expenses/apportionment/import",
            templateUrl: "/expenses/apportionment/template?user={user}&client={client}&locale={locale}",
            type: "excel",
            title: i18n.t("apportionment_import"),
            hasImportOption: true,
        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCopy = () => {
        const apportionmentRequest = {
            userId: userInfo.id,
            clientId: userInfo.clientId,
            scenarioId: userInfo.selection.scenarioId,
            organizationId: userInfo.selection.organizationId,
            businessUnitId: userInfo.selection.businessUnitId,
            originYear: originYear[0].year(),
            destinationYear: destinationYear[0].year(),
        };

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/expenses/apportionment/copy-to-new-year`,
                params: apportionmentRequest,
            },
            (data) => {
                if (data) {
                    Notification({
                        type: 'success',
                        message: `${i18n.t("realized_copy")} ${originYear[0].year()} ${i18n.t("at_year")} ${destinationYear[0].year()}`
                    })
                } else {
                    Notification({
                        type: 'error',
                        message: i18n.t("error_to_copy"),
                    })
                }
            }
        );
        setIsModalOpen(false);
    };

    const menuItem: ItemType[] = [
        {
            key: "copy",
            label: (
                <Button
                    className="grid-operation-importExport-btn"
                    onClick={showModal}
                    type="text"
                    icon={<CopyOutlined width="19" />}
                >
                    {i18n.t("copy_apportionment")}
                </Button>
            ),
        },
    ].filter(Boolean);

    const isValidCopy = (): boolean => {
        let isValid = false;
        
        if (destinationYear.length === 0 || originYear.length === 0) return isValid;

        if (destinationYear[0].year() > originYear[0].year() && originYear[0].year() !== destinationYear[0].year()) {
            isValid = true;
        }

        return isValid;
    }

    return (
        <>
            <div className="apportionment-filters-container">
                {isFetchingFlexFields ? (
                    <FilterSkeleton direction="row" active amount={4} gap={10} cardClassName="filter-card-skeleton" />
                ) : (
                    <Carousel
                        className="gs-carousel carousel-infos"
                        {...carouselDefaultProps}
                        slidesPerRow={filtersPerRow}
                        style={{ width: "calc(90% - 25px)", margin: "0 12px" }}
                    >
                        <Filter
                            id="period-filter"
                            value={year}
                            setValue={(value) => {
                                setYear(value);
                            }}
                            title={i18n.t("year")}
                            type="datepicker"
                            data={[]}
                            datepicker={{
                                format: "YYYY",
                                picker: "year",
                                allowClear: false,
                                defaultToday: true,
                                period: [openCalendarPeriod],
                            }}
                            buttonStyles={{ cursor: "default" }}
                            isFetching={false}
                        />
                        <Filter
                            id="period-filter"
                            value={apportionmentType}
                            title={i18n.t("Critério de rateio")}
                            type="radio"
                            setValue={(value) => {
                                setApportionmentType(value);
                            }}
                            data={apportionmentOptions.map((opt) => ({ ...opt, label: i18n.t(opt.label) }))}
                        />
                        <Filter
                            id="businessUnit-filter"
                            title={`${i18n.t("business_unit")} (${i18n.t("origin")})`}
                            type="radio"
                            hasSearch
                            hasSelectNone
                            searchPlaceholder={i18n.t("business_unit_search")}
                            value={businessUnit}
                            setValue={(value) => {
                                setBusinessUnit(value);
                                onChangeFlexFieldFilter(
                                    value[0]?.value,
                                    "BUSINESS_UNIT",
                                    flexFieldsOriginOptions,
                                    setFlexFieldsOriginOptions,
                                    value[0]?.value,
                                    costCenter[0]?.value ?? 0,
                                    account[0]?.value ?? 0
                                );
                            }}
                            data={businessUnitOptions}
                        />
                        <Filter
                            id="costCenter-filter"
                            title={`${i18n.t("cost_center")} (${i18n.t("origin")})`}
                            type="radio"
                            hasSearch
                            hasSelectNone
                            searchPlaceholder={i18n.t("search_by_cost_center")}
                            value={costCenter}
                            setValue={(value) => {
                                setCostCenter(value);
                                onChangeFlexFieldFilter(
                                    value[0]?.value,
                                    "COST_CENTER",
                                    flexFieldsOriginOptions,
                                    setFlexFieldsOriginOptions,
                                    businessUnit[0]?.value ?? 0,
                                    value[0]?.value,
                                    account[0]?.value ?? 0
                                );
                            }}
                            data={costCenterOptions}
                        />
                        <Filter
                            id="account-filter"
                            title={`${i18n.t("account")} (${i18n.t("origin")})`}
                            type="radio"
                            hasSearch
                            hasSelectNone
                            searchPlaceholder={i18n.t("search_by_cost_center")}
                            value={account}
                            setValue={(value) => {
                                setAccount(value);
                                onChangeFlexFieldFilter(
                                    value[0]?.value,
                                    "ACCOUNTING_ACCOUNT",
                                    flexFieldsOriginOptions,
                                    setFlexFieldsOriginOptions,
                                    businessUnit[0]?.value ?? 0,
                                    costCenter[0]?.value ?? 0,
                                    value[0]?.value
                                );
                            }}
                            data={optionsAccounting}
                        />
                        {flexFieldsOriginOptions.map((ffFilter) => {
                            return (
                                <Filter
                                    key={ffFilter.value}
                                    id="flex-field-filter"
                                    title={ffFilter.label}
                                    type="radio"
                                    value={ffFilter.selectedOption}
                                    setValue={async (value: any) => {
                                        if (value.length > 0) {
                                            const flexString = await onChangeFlexFieldFilter(
                                                value[0]?.value,
                                                ffFilter.value,
                                                flexFieldsOriginOptions,
                                                setFlexFieldsOriginOptions,
                                                businessUnit[0]?.value ?? 0,
                                                costCenter[0]?.value ?? 0,
                                                account[0]?.value ?? 0
                                            );
                                            setFlexFieldStringKey(flexString);
                                        }
                                    }}
                                    data={ffFilter.children}
                                    isFetching={ffFilter.isFetching ?? false}
                                    hasSearch
                                    hasSelectNone
                                />
                            );
                        })}
                    </Carousel>
                )}
                <div className="apportionment-apply-buttons">
                    <Button
                        className="gs-main-button"
                        id="apportionment-filters-apply-button"
                        onClick={() => {
                            fetchApportionments(
                                type[apportionmentType[0]?.value] ?? ApportionmentType.ALL,
                                businessUnit[0]?.value ?? 0,
                                costCenter[0]?.value ?? 0,
                                account[0]?.value ?? 0,
                                flexFieldStringKey,
                                year[0].year()
                            );
                        }}
                        style={{ marginRight: "5px", marginLeft: "-100px" }}
                    >
                        {i18n.t("apply")}
                    </Button>
                </div>
                <div className="apportionment-import-buttons-container">
                    <ImportExportMenu buttonType="3dots" importProps={importProps} otherFeatures={functionalityPermissions.copyapportionment ? menuItem : null} />
                </div>
            </div>

            <Modal
                title="Copiar Rateios"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {i18n.t("cancel")}
                    </Button>,
                    <Button
                        key="copy"
                        type="primary"
                        onClick={handleCopy}
                        disabled={!isValidCopy()}
                    >
                        
                        {i18n.t("copy")}
                    </Button>,
                ]}
            >
                <p>{i18n.t("select_year_to_apportionment")}</p>

                <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
                    <div>
                        <Filter
                            id="origin-year-filter"
                            value={originYear}
                            setValue={(value) => setOriginYear(value)}
                            title={"Origem:"}
                            type="datepicker"
                            data={[]}
                            datepicker={{
                                format: "YYYY",
                                picker: "year",
                                allowClear: false,
                                defaultToday: true,
                                period: [openCalendarPeriod],
                            }}
                            buttonStyles={{ cursor: "default" }}
                            isFetching={false}
                        />
                    </div>
                    <div>
                        <Filter
                            id="destination-year-filter"
                            value={destinationYear}
                            setValue={(value) => setDestinationYear(value)}
                            title={"Destino:"}
                            type="datepicker"
                            data={[]}
                            datepicker={{
                                format: "YYYY",
                                picker: "year",
                                allowClear: false,
                                defaultToday: true,
                                period: [openCalendarPeriod],
                            }}
                            buttonStyles={{ cursor: "default" }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}
