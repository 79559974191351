import { Icon } from "@iconify/react";
import { Input } from "antd";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useState, useEffect, BaseSyntheticEvent, useRef } from "react";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import i18n from "util/base/i18n";
import "./styles.sass";
import { ServiceCaller } from "util/service/ServiceCaller";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { MenuItemType } from "rc-menu/lib/interface";
import { IMenuItem } from "structure/module/IModule";
import { useNavigate } from "react-router-dom";
import { ROOT_PATH } from "util/rootPath";
import { useUserContext } from "context/UserContext";
import { handleRedirect } from "util/functions/handleRedirect";
import { useErrorBoundary } from "react-error-boundary";
import FreemiumTag from "components/freemiumTag/FreemiumTag";

export function MainMenu() {
	const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);
	const [searchItems, setSearchItems] = useState<MenuItemType[]>([]);
	const [searchBy, setSearchBy] = useState("");
	const [searchDelay, setSearchDelay] = useState<NodeJS.Timeout>(null);
	const [loading, setLoading] = useState(false);
	const { resetBoundary } = useErrorBoundary();
	const { isBudgetModule, setIsMainMenuOpen, isMainMenuOpen, openMenuBtnRef, menuSearchRef } = useUserContext()
	const navigate = useNavigate()
	const siderRef = useRef(null);
	const { userInfo } = useUserContext()

	useEffect(() => {
		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/menu/modules"
		}, onLoadMenu.bind(this));
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (siderRef.current && !siderRef.current.contains(event.target) && !openMenuBtnRef.current.contains(event.target)) {
				setIsMainMenuOpen(false)
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [openMenuBtnRef]);

	function doRedirect(url: string, event: MouseEvent): void {
		if (!event.ctrlKey) {
			resetBoundary()
			event.preventDefault();
			handleRedirect(url, isBudgetModule, navigate)
			setSearchBy("")
			if (isBudgetModule) {
				setIsMainMenuOpen(false)
			}
		}
	};

	const onLoadMenu = (modules: IMenuItem[]) => {
		setMenuItems(modules.sort(({ order: orderA }, { order: orderB }) => orderA - orderB).map(module => {
			return {
				label: <a href={ROOT_PATH + module.link} onClick={doRedirect.bind(this, ROOT_PATH + module.link)}>{module.caption}</a>,
				key: module.order,
				icon: <Icon width={18} icon={module.icon} />
			}
		}));
	};

	function closeMenu() {
		if (isBudgetModule) {
			setIsMainMenuOpen(false)
		} else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.CLOSE_MENU
			});
		}
	};

	const doSearchMenuItem = (searchBy: string) => {
		setLoading(true);

		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: `/menu/items/search?searchBy=${searchBy}`
		}, onLoadSearch.bind(this));
	};

	const onLoadSearch = (items: IMenuItem[]) => {
		setSearchItems(items.sort(({ itemOrder: orderA }, { itemOrder: orderB }) => orderA - orderB).map(({ caption, link }, index) => {
			return {
				label: <a href={ROOT_PATH + link} onClick={doRedirect.bind(this, ROOT_PATH + link)}>{caption}</a>,
				key: index
			}
		}));
		setLoading(false);
	};

	const onSearch = ({ target: { value: searchBy } }: BaseSyntheticEvent) => {
		if (searchItems.length > 0) {
			setSearchItems([]);
		}

		setSearchBy(searchBy);

		clearTimeout(searchDelay);

		if (searchBy.trim() !== "") {
			setSearchDelay(setTimeout(doSearchMenuItem.bind(this, searchBy), 300));
		}
	};

	return <>
		<Sider
			id="main-menu-container"
			width={300}
			ref={siderRef}
			trigger={null}
			collapsedWidth={0}
			defaultCollapsed={isBudgetModule ? true : false}
			collapsed={isBudgetModule ? !isMainMenuOpen : false}
			collapsible
			onCollapse={isBudgetModule ? () => setIsMainMenuOpen(false) : closeMenu}
			style={userInfo.plan === "FREEMIUM"	? {background: "#045594"} : {}}
		>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", alignItems: "center", marginTop: "18px" }}>
					<img onClick={closeMenu} src="/assets/images/brand-totvs-white.png" alt="gesplan" style={{cursor: "pointer", margin: "0 18px 0" }} />
					{userInfo.plan === "FREEMIUM" && <FreemiumTag style={{ color: '#AFC2D0', borderColor: '#AFC2D0', marginLeft: 0 }} />}
				</div>
				<Input
					ref={menuSearchRef}
					style={{
						margin: "15px auto 0",
						width: "90%",
						height: 35,
						backgroundColor: "#081c2b",
						border: "none",
						borderRadius: 20
					}}
					prefix={loading ? <LoadingOutlined style={{ color: "#fff", margin: "0 5px 0 10px" }} /> : <SearchOutlined style={{ color: "#fff", margin: "0 5px 0 10px" }} />}
					placeholder={i18n.t<string>("search")}
					onChange={onSearch.bind(this)}
					value={searchBy} 
					className={userInfo.plan === "FREEMIUM" ? "input-freemium" : ""}
				/>
			</div>

			<Menu id="main-menu-content" style={userInfo.plan === "FREEMIUM" ? {background: "#045594"} : {}} theme="dark" mode="inline" selectable={false} items={searchBy.trim() !== "" ? searchItems : menuItems} />
		</Sider>
	</>;

}
