import { Layout } from "antd";
import { useState } from "react";
import ReportRegistrationTable from "./components/ReportRegistrationTable";
import ReportRegistrationTitle from "./components/ReportRegistrationTitle";
import TopButtonsReportRegistrationContent from "./components/TopButtonsReportRegistrationContent";
import { NewReportProvider } from "./context/ReportRegistrationContext";
import { NewReport } from "./NewReport/NewReport";
import "./ReportRegistration.sass";
import { FormulaProvider } from "./formula/context/FormulaContext";

const { Header, Content } = Layout;
export default function ReportRegistration() {
    const [isOnNewReport, setIsOnNewReport] = useState<boolean>(false);

    const handleSetIsOnNewReport = (isOnNewReport: boolean) => {
        setIsOnNewReport(isOnNewReport);
    };

    return (
        <NewReportProvider>
            <Layout className="light projection-groupres-page projection-modeling-page">
                <Content>
                    <div className="registration-sales-page">
                        {isOnNewReport ? (
                            <FormulaProvider>
                                <NewReport handleSetIsOnNewReport={handleSetIsOnNewReport} />
                            </FormulaProvider>
                        ) : (
                            <>
                                <Header>
                                    <ReportRegistrationTitle />
                                </Header>
                                <TopButtonsReportRegistrationContent
                                    isOnNewReport={isOnNewReport}
                                    setIsOnNewReport={setIsOnNewReport}
                                />
                                <ReportRegistrationTable />
                            </>
                        )}
                    </div>
                </Content>
            </Layout>
        </NewReportProvider>
    );
}
