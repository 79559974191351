import { Table } from "antd";
import { tableProps } from "util/props/props";
import { useManagementTransferContext } from "../context/ManagementTransferContext";
import i18n from "util/base/i18n";
import { Loading } from "components/loading/Loading";
import { formatNumber } from "util/formatNumber";

export function GridManagementTransfer() {

    const {
        flexFieldsFilters,
        setSelectedRowKeys,
        selectedRowKeys,
        isLoading,
        isDataReady,
        formattedData,
        isDataLoaded
    } = useManagementTransferContext();

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const tableColumns = [
        {
            title: i18n.t<string>("management_transfer.transfer_period"),
            dataIndex: "date_range",
            key: "date_range",
            sorter: (a, b) => a.date_range.localeCompare(b.date_range),
            width: 180,
        },
        {
            title: i18n.t<string>("management_transfer.requester"),
            key: "requester",
            children: [
                {
                    title: i18n.t<string>("cost_center"),
                    dataIndex: "requester_cost_center",
                    key: "requester_cost_center",
                    width: 180,
                    sorter: (a, b) => a.requester_cost_center.localeCompare(b.requester_cost_center),
                },
                {
                    title: i18n.t<string>("accounting_account"),
                    dataIndex: "requester_account_accounting",
                    key: "requester_account_accounting",
                    width: 180,
                    sorter: (a, b) => a.requester_account_accounting.localeCompare(b.requester_account_accounting),
                },
                ...flexFieldsFilters.map(flexField => ({
                    title: flexField.label,
                    dataIndex: "requester_flex_fields",
                    className: "flex-fields",
                    width: 180,
                    key: `requester_${flexField.value}`,
                    sorter: (a, b) => {
                        const fieldA = a.requester_flex_fields.find((f) => f.label === flexField.label)?.values.join(", ") || "";
                        const fieldB = b.requester_flex_fields.find((f) => f.label === flexField.label)?.values.join(", ") || "";
                        return fieldA.localeCompare(fieldB);
                    },
                    render: (flexFields) => {
                        const field = flexFields.find((f) => f.label === flexField.label);
                        return field ? field.values.join(", ") : "-";
                    }
                })),
            ]
        },
        {
            title: i18n.t<string>("management_transfer.assignor"),
            key: "assignor",
            children: [
                {
                    title: i18n.t<string>("cost_center"),
                    dataIndex: "assignor_cost_center",
                    key: "assignor_cost_center",
                    width: 180,
                    sorter: (a, b) => a.assignor_cost_center.localeCompare(b.assignor_cost_center),
                },
                {
                    title: i18n.t<string>("accounting_account"),
                    dataIndex: "assignor_account_accounting",
                    key: "assignor_account_accounting",
                    width: 180,
                    sorter: (a, b) => a.assignor_account_accounting.localeCompare(b.assignor_account_accounting),
                },
                ...flexFieldsFilters.map(flexField => ({
                    title: flexField.label,
                    dataIndex: `assignor_flex_fields`,
                    className: "flex-fields",
                    width: 180,
                    key: `assignor_${flexField.value}`,
                    sorter: (a, b) => {
                        const fieldA = a.assignor_flex_fields.find((f) => f.label === flexField.label)?.values.join(", ") || "";
                        const fieldB = b.assignor_flex_fields.find((f) => f.label === flexField.label)?.values.join(", ") || "";
                        return fieldA.localeCompare(fieldB);
                    },
                    render: (flexFields) => {
                        const field = flexFields.find((f) => f.label === flexField.label);
                        return field ? field.values.join(", ") : "-";
                    }
                })),
            ]
        },
        {
            title: i18n.t("management_transfer.changed_by"),
            dataIndex: "lastUpdateUserName",
            key: "lastUpdateUserName",
            sorter: (a, b) => a.lastUpdateUserName.localeCompare(b.lastUpdateUserName),
        },
        {
            title: i18n.t("management_transfer.change_date"),
            dataIndex: "lastUpdateDate",
            key: "lastUpdateDate",
            sorter: (a, b) => a.lastUpdateDate.localeCompare(b.lastUpdateDate),
        },
        {
            title: i18n.t<string>("value"),
            dataIndex: "total",
            key: "total",
            className: "total-cell",
            render: (value: number = 0) => {
                const formattedValue = formatNumber(value, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                });
                return formattedValue;
            },
            sorter: (a, b) => a.total - b.total,
        },
    ];

    return (
        <>
            {isDataReady ? (
                isLoading ? (
                    <Loading />
                ) : isDataLoaded ? (
                    formattedData && formattedData.length > 0 ? (
                        <div className="management-transfer-content-table">
                            <Table
                                id="management-transfer-table"
                                {...tableProps}
                                columns={tableColumns}
                                dataSource={formattedData}
                                rowKey={"id"}
                                rowSelection={rowSelection}
                                scroll={{ x: "max-content", y: 380 }}
                                bordered
                                pagination={false}
                            />
                        </div>
                    ) : (
                        <div className="management-transfer-no-data">
                            <img src="/assets/images/budget.png" alt="wfn-budget" />
                            <h2>{i18n.t("management_transfer.not_registered_transfer")}</h2>
                        </div>
                    )
                ) : (
                    <div className="management-transfer-no-data">
                        <img src="/assets/images/budget.png" alt="wfn-budget" />
                        <h2>{i18n.t("management_transfer.select_period")}</h2>
                    </div>
                )
            ) : null}
        </>
    );
}