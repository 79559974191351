import { FilterType } from "../reportRegistration/IReportRegistration"
import { IConditionFromRequest, IFormulaPartFromRequest } from "./components/modals/rule/components/IModalFactory"

export interface IAttributeParameterizationContext {
    searchTextTable: string
    selectedGrouper: IGrouper
    selectedAttribute: IAttribute
    rules: IRule[],
    selectedRows: IRowFromTable[],
    tableData: any,

    isNewAttributeModalOpen: boolean,
    isEditAttributeModalOpen: boolean,
    isDeleteAttributeModalOpen: boolean,
    editAttributeInfo: any,
    openDeleteAttributeModal: () => void,

    isNewRuleModalOpen: boolean,
    isEditRuleModalOpen: boolean,
    openNewRuleModal: () => void,
    closeNewRuleModal: () => void
    openEditRuleModal: () => void,
    closeEditRuleModal: () => void,

    isNewRuleModalDFOpen: boolean,
    isEditRuleModalDFOpen: boolean,
    openNewRuleModalDF: () => void
    closeNewRuleModalDF: () => void,
    openEditRuleModalDF: () => void,
    closeEditRuleModalDF: () => void,

    isNewRuleModalFixedMarketingCostOpen: boolean;
    isEditRuleModalFixedMarketingCostOpen: boolean;
    openNewRuleModalFixedMarketingCost: () => void;
    closeNewRuleModalFixedMarketingCost: () => void;
    closeEditRuleModalFixedMarketingCost: () => void;
    openEditRuleModalFixedMarketingCost: () => void;

    isYearPeriod: boolean,
    isFetchingData: boolean,
    lastAccomplishedPeriod: string,

    openEditAttributeModal: (attribute) => void
    openNewAttributeModal: () => void
    closeEditAttributeModal: () => void
    closeNewAttributeModal: () => void,

    closeDeleteAttributeModal: () => void,

    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleClearSearch: () => void
    handleSelectedGrouper: (grouper: IGrouper) => void
    handleSelectedAttribute: (option) => void
    handleSelectedRows: (rows: any) => void
    handleRemoveRows: () => void,
    handleRemoveRule: (externalCode: string) => void
    handleAddRule: (ruleToBeAdded: IRule) => void,
    clearRules: () => void,
    period: [moment.Moment, moment.Moment],
    setPeriod: (value: [moment.Moment, moment.Moment]) => void;
    handleChangeTableData: (data: any) => void,
    defaultColumns: any,
    setDefaultColumns: (value: any) => void,
    handleChangePeriod: () => void,
    clearSelectedRows: () => void,
    handleReplicateRules: () => void,
    reloadTable: () => void;
    hasSavedValue: boolean;
    setHasSavedValue: (value: boolean) => void;
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
    labelDeleteAttributeModal: string;
    setLabelDeleteAttributeModal: React.Dispatch<React.SetStateAction<string>>;
}

export interface IRule {
    externalCode: string
    description: string
    attributeId: number
    ruleOrder: number
    levels: {
        id: number
        description: string
    },
    levelsValue: {
        id: number
        description: string
    }
    operations: number,
}

export interface IFixedExpenseRule {
    externalCode: string
    description: string
    attributeId: number
    ruleOrder: number
    operations: number
    levelsValue: number
    conditions: IRuleCondition[]
}

export interface IRevenueRule {
    externalCode: string
    description: string
    attributeId: number
    ruleOrder: number
    operations: number
    levelsValue: number
    conditions: IRuleCondition[]
}

export interface IRuleCondition {
    moduleType: string
    apportionmentType: string
    partsConditions: IPartCondition[]
}

export interface IPartCondition {
    flexFieldId?: number
    flexFieldValueId?: number
}

export enum GrouperName {
    FIXED_EXPENSE = "Despesa Fixa",
    SALE_DEDUCTION = "Deduções de Vendas",
    VARIABLE_EXPENSES = "Despesas Variáveis",
    MARKETING_COST = "Custo de Comercialização",
    MARKETING_COST_VARIABLE = "Custo de Comercialização Variável",
    MARKETING_COST_FIXED = "Custo de Comercialização Fixo",
}


export enum NewRuleModalOperations {
    Equal = 'EQUALS',
    Different = 'DIFFERENT',
    Lesser = 'LESS',
    LessOrEqual = 'LESS_OR_GREATER',
    Greater = 'GREATER',
    GreaterOrEqual = 'GREATER_OR_EQUAL'
}

export interface AttributeInfo {
    externalCode: string | number
    description: string
    order: number
    levels: string
    operations: string
    levelValues: string
}

export interface IOption {
    id?: number | string
    label: string
    value: string | number
    disabled?: boolean
}

export interface IRuleLabel {
    name: string
}

export interface IRuleComponent {
    children: React.ReactNode,
    index: number,
    handleRemove: (index: number) => void
}

export interface ICustomSelect {
    id: string;
    labelName: string;
    hasOptionGroups?: boolean;
    defaultValue?: any;
    value?: any;
	options: ICustomSelectOptions[];
    disabled?: boolean;
    onChange?: (value: any) => void;
    clearSelects?: boolean;
    key?: any;
    hasSearch?: boolean;
	onClear?: () => void;
	mode?: "multiple" | "tags";
	loading?: boolean;
}

export interface ICustomSelectOptions {
	label: string,
	value: string | number
}

export interface IRenderRules {
    rulesList: IRule[]
}

export interface FlexField {
    id: number;
    clientId?: null | number
    creationDate: string
    description: string
    externalCode: string
    fieldCode: string
    filters?: any
    informative: boolean
    linkedFlexFields?: any
    managementSecurity: boolean
    modules: string[]
    ordenation: number
    updateDate: null | string
    isLastLevel: boolean
}

export interface FlexFieldValue {
    id: number;
    clientId?: number | string;
    flexFieldId: 15;
    flexFieldDescription: string;
    fieldCode: string;
    externalCode: string;
    description: string;
    period: number;
    creationDate: string;
    updateDate?: null | string;
    accountingAccountFilters?: any;
    businessUnitFilters?: any;
    costCenterFilters?: any;
    flexFieldFilters?: any;
    flexFieldValueListBean?: any;
}

export interface IGrouper {
    id: number;
    name: GrouperName | string;
    costMarketing?: {
        id: number;
        name: string;
    }[];
}

export interface ITableAttributeRule {
    attributeRuleId: number;
    externalCode: string;
    moduleType: string;
    name: string;
    order: number;
    monthValues: any[];
}

export interface IRuleAttribute {
    id: number
    externalCode: string
    description: string
    levelsValue: number
    operations: number
    order: number
    ruleName: string
    createdAt: string | Date
    attributeId: {
        id: number,
        grouperId: number,
        name: string,
        grouperName: GrouperName
    }
}

export interface IAttribute {
    id: number
    name: string
    grouperId: number
    grouperName: string
    isMarketingCost?: boolean
}

export interface INewAttributeModalAttribute {
    id?: number
    grouperId: number
    grouperName: string | null
    name: string
    caption?: string
    grossRevenue?: number | null
}

export interface FormulaItem {
    key?: any
    type: "attribute" | "operator" | "value";
    content: any;
    value?: number;
}

export enum AttributeMathOperations {
    plus = "PLUS",
    minus = "LESS",
    times = "MULTIPLY",
    divide = "DIVIDER",
    left_parenthesis = "LEFT_PARENTHESES",
    right_parenthesis = "RIGHT_PARENTHESES"
}

export enum AttributeMathOperationsFromServer {
    PLUS = "plus",
    LESS = "minus",
    MULTIPLY = "times",
    DIVIDER = "divide",
    LEFT_PARENTHESES = "left_parenthesis",
    RIGHT_PARENTHESES = "right_parenthesis",
}

export enum RuleConditionOperations { 
    EQUALS = "equals",
    DIFFERENT = "different",
    CONTAINS = "contains",
    NOT_CONTAINS = "not_contains",
    FROM_TO = "from_to",
    NOT_EQUALS = "NOT_EQUALS"
}

export enum RuleConditionOperationsTempCaptions {
    EQUALS = "Igual",
    DIFFERENT = "Diferente",
    CONTAINS = "Contém",
    NOT_CONTAINS = "Não Contém",
    FROM_TO = "De Para",
}

export enum IConditionGrouperType {
    REVENUE = "REVENUE",
    FIXED_EXPENSE = "FIXED_EXPENSE"
}

export enum IConditionModuleType {
    REVENUE,
    FIXED_EXPENSE
}

export interface ICondition {
    id?: number;
    moduleType?: IConditionModuleType;
    existingConditionId?: number;
    grouperType: IConditionGrouperType;
    selectedLevel: ILevel;
    operations: RuleConditionOperations;
	selectedLevelValue: ILevelsValue;
	filterType?: FilterType;
}

export interface ILevel {
    id: string;
    description: string;
    type?: string;
}

export interface ILevelsValue {
    id: number;
    description: string;
}

export interface IRowFromTable {
    attributeRuleId: number;
    description: string;
    externalCode: string;
    key: number;
    moduleType: string;
    order: number;
    conditionsBeans: IConditionFromRequest[];
    periodValues: any[];
    formulas?: IFormulaPartFromRequest[];
}

export enum IRuleApportionmentType {
    REVENUE = "GROSS_REVENUE",
    OCCURRENCE = "OCCURRENCE"
}

export interface IDeleteAttributeModalProps {
    label: string;
}

export interface Item {
    key: string;
    label?: JSX.Element;
    translationKey: string;
}

export interface TranslationKey {
    label: string;
    translationKey: string;
}

export interface CalculateParams {
    groupers: string[];
    startPeriod: string;
    endPeriod: string;
    clientId: number;
    userId: number;
    localeId: number;
    scenarioId: number;
    organizationId: number;
    businessUnitId: number;
}
