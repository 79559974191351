import { Layout } from "antd";
import { ManagementTransferProvider } from "./context/ManagementTransferContext";
import { Title } from "./Title";
import { GridManagementTransfer } from "./components/GridManagementTransfer";
import ManagementTransferModal from "./components/ManagementTransferModal";
import "./ManagementTransfer.sass";

export default function ManagementTransfer() {
    return (
        <ManagementTransferProvider>
            <Layout className="management-transfer-page">
                <Layout.Header>
                    <Title />
                </Layout.Header>
                <Layout.Content>
                    <GridManagementTransfer />
                </Layout.Content>
                <ManagementTransferModal />
            </Layout>
        </ManagementTransferProvider>
    );
}
