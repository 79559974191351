import { Button, Form, Upload, Radio, message } from "antd";
import { Icon } from "@iconify/react";
import { useUserContext } from "context/UserContext";
import i18n from "util/base/i18n";
import { Notification } from "components/notification/Notification";
import { ServiceCaller } from "util/service/ServiceCaller";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportResponse } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { useState } from "react";
import { useManagementTransferContext } from "../context/ManagementTransferContext";

const { Dragger } = Upload;

export function ImportTabContent({
    importUrl,
    templateFileName,
    urlParams,
    hasImportOption = false,
    setImportModalOpen,
    useProxy = true,
    templateUrl,
    uploadType,
    bodyData,
    handleSuccessImport,
    async
}: IImportModal) {
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();
    const [disabled, setDisabled] = useState(true);
    const { getCostCenterLinked } = useManagementTransferContext();
 
    const typeAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const handleDownloadStandardWorksheet = () => {
        ServiceCaller.doSendMessage({
            messageType: MessageType.REQUEST_FILE,
            params: {
                url: templateUrl,
                fileName: templateFileName,
                useProxy: true
            },
        });
    };

    function createNotification(response) {
        Notification({
            type: response.success ? "success" : "error",
            message: i18n.t(response.message),
        });
    }

    const handleUpload = (file) => {
        const isXLSX = file.type === typeAccept;
        if (!isXLSX) {
            message.error(i18n.t("import_modal.xlsx_file"));
        }
        return isXLSX;
    };

    function handleFileChange(fileList) {
        setDisabled(fileList.length === 0);
    }

    function handleSubmit(data) {
        if (!data.file) {
            Notification({
                type: "warning",
                message: i18n.t("import_modal.no_file_selected"),
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", data.file.file.originFileObj);
        formData.append("fileName", data.file.file.name);
        formData.append("clientId", userInfo.clientId.toString());
        formData.append("userId", userInfo.id.toString());
        formData.append("localeId", userInfo.localeId.toString());
        formData.append("scenarioId", userInfo.selection.scenarioId.toString());
        formData.append("organizationId", userInfo.selection.organizationId.toString());
        formData.append("businessUnitId", userInfo.selection.businessUnitId.toString());

        if (hasImportOption) {
            formData.append("importType", data.importType);
        } else {
            formData.append("importType", "CREATE_UPDATE");
        }

        if (uploadType) {
            formData.append("uploadType", uploadType);
        }

        if (bodyData) {
            for (const [key, value] of bodyData.entries()) {
                formData.append(key, value);
            }
        }

        Notification({
            type: "info",
            message: i18n.t("import_modal.import_in_progress"),
        });

        const urlImport = importUrl.includes("projectionlease") ? importUrl :
            importUrl +
            "?clientId={client}&userId={user}&localeId={locale}&businessUnitId={businessUnit}&organizationId={organization}&scenarioId={scenario}&groupIds={groups}" +
            (data.importType ? "&importType=" + data.importType : "&importType=CREATE_UPDATE") +
            (urlParams ? urlParams : "");

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: urlImport,
                useProxy: useProxy,
                params: [...formData.entries()],
                isFile: true,
            },
            (response: ImportResponse) => {
                if (!Object.is(async, true)) {
                    createNotification(response);
                }
                if (response.success) {
                    getCostCenterLinked(); 
                }

                form.resetFields();
                setDisabled(true);
                handleSuccessImport();
            },
            handleErrorRequest
        );
    }

    return (
        <div>
            <h3>{i18n.t("import_modal.import_data")}</h3>
            <p>
                {i18n.t("import_modal.select_your_spreadsheet")}
                <Button style={{ padding: "0 4px" }} type="link" onClick={handleDownloadStandardWorksheet}>
                    {i18n.t("import_modal.standard_worksheet")}
                </Button>
                {i18n.t("import_modal.to_fill")}
            </p>

            <Form
                form={form}
                name="import-form"
                onFinish={handleSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <Form.Item name="file">
                    <Dragger
                        accept={typeAccept}
                        multiple={false}
                        onDrop={(e) => e.preventDefault()}
                        customRequest={({ file, onSuccess }) => {
                            const isValid = handleUpload(file);
                            if (isValid) {
                                onSuccess(null, null);
                            } else {
                                onSuccess("error", null);
                            }
                        }}
                        onChange={({ fileList }) => handleFileChange(fileList)}
                    >
                        <p style={{ margin: 0 }}>
                            <Icon icon="ph:upload-simple-duotone" style={{ fontSize: 24 }} />
                        </p>
                        <p className="ant-upload-text">{i18n.t("import_modal.drag_your_file_here")}</p>
                        <p className="ant-upload-hint">
                            {i18n.t("import_modal.file_type")} excel {i18n.t("import_modal.accepted_files")}
                        </p>
                    </Dragger>
                </Form.Item>

                {hasImportOption && (
                    <Form.Item name="importType" label={`${i18n.t("human_resources.import_type")}:`}>
                        <Radio.Group>
                            <Radio value="CREATE_UPDATE">{i18n.t("import_modal.inclusion_change")}</Radio>
                            <Radio value="WIPE_CREATE">{i18n.t("import_modal.zeroing")}</Radio>
                            <Radio value="EXCLUSION_CREATE">{i18n.t("import_modal.exclusion")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                )}

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disabled}
                    >
                        {i18n.t("import_modal.load_data")}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}