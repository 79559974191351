import { Tag } from "module/budget/pages/revenue/attributeParameterization/components/Tag";
import { DivideOp, LeftParenthesisOp, MinusOp, PlusOp, RightParenthesisOp, TimesOp } from "module/budget/pages/revenue/attributeParameterization/components/operators/MathOperators";
import { ChooseIconOperation, IReportFilterSignal, SignalType } from "../IReportRegistration";

export const chooseIconOperation = ({ key, position, removeItem }: ChooseIconOperation) => {
	return ({
		plus: <div key={key}>
			<Tag isMathOperation itemIndex={position} removeItem={removeItem}>
				<PlusOp />
			</Tag>
		</div>,
		minus: <div key={key}>
			<Tag isMathOperation itemIndex={position} removeItem={removeItem}>
				<MinusOp />
			</Tag>
		</div>,
		times: <div key={key}>
			<Tag isMathOperation itemIndex={position} removeItem={removeItem}>
				<TimesOp />
			</Tag>
		</div>,
		divide: <div key={key}>
			<Tag isMathOperation itemIndex={position} removeItem={removeItem}>
				<DivideOp />
			</Tag>
		</div>,
		left_parenthesis: <div key={key}>
			<Tag
				isMathOperation
				itemIndex={position}
				removeItem={removeItem}
				isParenthesis
			>
				<LeftParenthesisOp />
			</Tag>
		</div>,
		right_parenthesis: <div key={key}>
			<Tag
				isMathOperation
				itemIndex={position}
				removeItem={removeItem}
				isParenthesis
			>
				<RightParenthesisOp />
			</Tag>
		</div>
	})
}

export const stringToSignalType: { [value: string]: SignalType } = {
	"ORIGINAL": SignalType.ORIGINAL,
	"POSITIVE": SignalType.POSITIVE,
	"NEGATIVE": SignalType.NEGATIVE,
}

export const filterOperationToSignal: Record<IReportFilterSignal, string> = {
	PLUS: "+",
	MINUS: "-"
}