import { useForm } from "antd/es/form/Form";
import { Notification } from "components/notification/Notification";
import {
    FlexFieldValue,
    ICondition,
    IConditionGrouperType,
    ILevel,
    ILevelsValue,
    RuleConditionOperations,
} from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { useNewReportContext } from "module/budget/pages/revenue/reportRegistration/context/ReportRegistrationContext";
import {
    FilterType,
    Formula,
    FormulaItem,
    OptionSelect,
    FormulaType,
    IFormulaContext,
    LevelFilterPlanning,
    LevelFormula,
    LevelReport,
    Line,
    ReportType,
} from "module/budget/pages/revenue/reportRegistration/IReportRegistration";
import { createContext, useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Report } from "../../IReportRegistration";

export const FormulaContext = createContext<IFormulaContext>({} as IFormulaContext);
export const FormulaProvider: React.FC = ({ children }) => {
    const [selectedOptionsList, setSelectedOptionsList] = useState<string[]>([]);
    const [optionsForFormula, setOptionsForFormula] = useState<OptionSelect[]>([]);
    const [selectedRowFormulasFormated, setSelectedRowFormulasFormated] = useState<FormulaItem[]>([]);
    const [formula, setFormula] = useState<FormulaItem[]>(selectedRowFormulasFormated);
    const [allListFormula, setAllListFormula] = useState<Map<number, LevelReport>>(new Map<number, LevelReport>());
    const [updateOptionsRecursively, setUpdateOptionsRecursively] = useState<string>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [planningCondition, setPlanningCondition] = useState<LevelFilterPlanning[]>([]);

    const [levels, setLevels] = useState<ILevel>();
    const [pendingLevelSelected, setPendingLevelSelected] = useState<LevelReport>(null);
    const [selectedRowFilters, setSelectedRowFilters] = useState<ICondition[]>([]);
    const [selectRowPlanningFilters, setSelectedRowPlanningFilters] = useState<LevelFilterPlanning[]>([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [hasSavedValue, setHasSavedValue] = useState<boolean>(false);
    const [levelsValue, setLevelsValue] = useState<ILevelsValue[]>([]);
    const [operations, setOperations] = useState<RuleConditionOperations>();
    const [classNameForPulseButtonFilter, setClassNameForPulseButtonFilter] = useState("");
    const [classNameForPulseButtonFormula, setClassNameForPulseButtonFormula] = useState("");
    const [filterType, setFilterType] = useState<FilterType>(FilterType.FLEX_FIELD);
    const [hasRemovedLevel, setHasRemovedLevel] = useState<boolean>(false);

    const [conditions, setConditions] = useState<ICondition[]>([]);
    const [availableOperations, setAvailableOperations] = useState([]);
    const [flexFieldsValues, setFlexFieldsValues] = useState<FlexFieldValue[]>([]);

    const [form] = useForm();

    const [lines, setLines] = useState<Line[]>([]);
    const [isSelectedLevel, setIsSelectedLevel] = useState<Boolean>(false);

    const {
        isPlanningReport,
        report,
        setReport,
        selectedRowKey,
        setSelectedRowKey,
        selectedRowFormula,
        setSelectedRowFormula,
    } = useNewReportContext();

    const chooseType = {
        "+": "plus",
        "-": "minus",
        "*": "times",
        "/": "divide",
        "(": "left_parenthesis",
        ")": "right_parenthesis",
        value: "value",
    };

    const chooseTypeOperation = {
        plus: "+",
        minus: "-",
        times: "*",
        divide: "/",
        left_parenthesis: "(",
        right_parenthesis: ")",
        value: "value",
    };

    useEffect(() => {
        setFormula(selectedRowFormulasFormated);
    }, [selectedRowFormulasFormated]);

    useEffect(() => {
        setOperations(null);
        setLevels(null);
        setLevelsValue(null);
        setFlexFieldsValues([]);
        setPendingLevelSelected(null);
        setHasUnsavedChanges(false);
        form.resetFields();

        if (!report?.levels) return;

        if (report.type === ReportType.PLANNING) {
            report.levels.forEach((level) => {
                level.levelFormula.formulas.forEach((formula) => {
                    if (formula.type === "operator") return;
                    if (formula.levelId && !allListFormula.has(formula.levelId)) {
                        allListFormula.set(formula.levelId, level);
                    }
                });
            });
        }

        setHasSavedValue(false);
    }, [selectedRowKey]);

    function validateAndSaveFormula() {
        if (!isFormulaCorrect(formula)) {
            Notification({
                message: i18n.t("incorrect_formula"),
                type: "warning",
            });
            return false;
        }

        if (pendingLevelSelected?.levelFormula && validateFormulaIsEqual(pendingLevelSelected?.levelFormula)) {
            return false;
        }

        onSaveFormula(
            formula.map(({ content, key }, index) => {
                const [typeOperator, id] = (key as string).split("-");
                const typeFormula: FormulaType = FormulaType[typeOperator];
                setIsLoading(true);
                return {
                    ordination: index,
                    type: typeFormula,
                    attributeId: typeFormula === FormulaType.ATTRIBUTE ? Number(id) : null,
                    levelId: typeFormula === FormulaType.LEVEL ? Number(id) : null,
                    operator: typeFormula === FormulaType.OPERATORS ? chooseTypeOperation[content] : null,
                };
            })
        );

        return true;
    }
    function isFormulaCorrect(formulas: FormulaItem[]): boolean {
        if (formula.length % 2 === 0 && formulas.length > 0) return false;
        let prevType: "attribute" | "operator" | "value" | null = null;
        let firstParenthesis: number = -1;
        let lastParenthesis: number = -1;
        let currentFormulaOnParenthesis: FormulaItem[] = [];
        formulas.filter(
            ({ content }) => content.toString() === "left_parenthesis" || content.toString() === "right_parenthesis"
        );
        for (let index = 0; index < formulas.length; index++) {
            const { type, content } = formulas[index];
            if (firstParenthesis) {
                currentFormulaOnParenthesis.push(formulas[index]);
            } else if (lastParenthesis) {
                if (!isFormulaCorrect(currentFormulaOnParenthesis)) return false;
            }
            if (type === "operator") {
                if (content === "left_parenthesis") {
                    firstParenthesis = index;
                    continue;
                } else if (content === "right_parenthesis") {
                    lastParenthesis = index;
                    continue;
                }
                if (index === 0) return false;
            }
            if (prevType === type) return false;

            prevType = type;
        }
        return true;
    }

    const onAcceptFormulaChanges = () => {
        const isFormulaValid = validateAndSaveFormula();
        const isFilterValid = validateAndSaveFilter();

        if (isFilterValid && isFormulaValid) {
            setIsLoading(false);
            setHasSavedValue(true);
            setHasUnsavedChanges(false);
            const level: LevelReport = findNextLevelToSelect(selectedRowKey);
            if (level) {
                setSelectedRowKey(level.id);
                setSelectedRowFormulasFormated(buildFormatedFormula(level.levelFormula));
                setSelectedRowFormula(level.id);
            }
            if (pendingLevelSelected) {
                setSelectedRowKey(pendingLevelSelected.id);
                setPendingLevelSelected(null);
            }
        }
    };

    function validateFormulaIsEqual(levelFormulas: LevelFormula): boolean {
        const formattedCurrentFormula: string = JSON.stringify(removeKeyFormula(formula));
        if (!formattedCurrentFormula) return true;
        const formattedToCompareFormula: string = JSON.stringify(removeKeyFormula(buildFormatedFormula(levelFormulas)));
        return formattedCurrentFormula === formattedToCompareFormula;
    }

    function handleCancelPendingLevel() {
        if (!validateFormulaIsEqual(pendingLevelSelected.levelFormula)) {
            setClassNameForPulseButtonFormula("pulsating-button");
        }

        if (levels) {
            setClassNameForPulseButtonFilter("pulsating-button");
        }
        setPendingLevelSelected(null);
        setTimeout(() => {
            setClassNameForPulseButtonFormula("");
            setClassNameForPulseButtonFilter("");
        }, 1000);
    }

    function validateFilter(): boolean {
        if (isPlanningReport() && conditions.length) {
            let isFilterValid: boolean = true;
            isFilterValid = conditions.some(({ selectedLevel: { id } }, _, allConditions) => {
                if (id === "management_cost_center") {
                    return allConditions.some(
                        ({ selectedLevel }) => selectedLevel.id === "management_accounting_account"
                    );
                } else {
                    return allConditions.some(({ selectedLevel }) => selectedLevel.id === "management_cost_center");
                }
            });

            if (!isFilterValid) {
                Notification({
                    message: i18n.t("new_sales_report.line_filter_error"),
                    type: "warning",
                });

                return false;
            }
        }

        return true;
    }

    function validateAndSaveFilter(): boolean {
        if (validateFilter()) return false;
        handleCreateCondition();
        return true;
    }

    function handleCreateCondition() {
        setConditions((prevConditions) => {
            return [
                ...prevConditions,
                ...levelsValue.map((levelValue) => {
                    return {
                        grouperType: IConditionGrouperType.REVENUE,
                        operations: operations,
                        selectedLevel: levels,
                        selectedLevelValue: levelValue,
                        filterType: filterType,
                    };
                }),
            ];
        });
        setOperations(null);
        setLevels(null);
        setLevelsValue(null);
        setFlexFieldsValues([]);
        setHasUnsavedChanges(false);
    }

    const flattenHierarchy = (lines: LevelReport[] = []): LevelReport[] => {
        let newLevels: LevelReport[] = [];

        lines.forEach((level) => {
            newLevels.push(level);
            if (level.children?.length) {
                newLevels = newLevels.concat(flattenHierarchy(level.children));
            }
        });
        return newLevels.sort((a, b) => (a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1));
    };

    function validateLevelsOnFormula(value: string): boolean {
        return !!Number(value.split("-")[1]);
    }

    function onSaveFormula(formula: Formula[]) {
        let newReport: Report = report;
        for (let index = 0; index < newReport.levels.length; index++) {
            const line = newReport.levels[index];
            if (line.id === selectedRowKey) {
                line.levelFormula = { belongsToId: line.id, formulas: formula };
                newReport.levels[index] = line;
                setReport(newReport);
                break;
            }
            if (line.children) {
                let findedLine = line.children.find(({ id }) => id === selectedRowKey);
                if (findedLine?.id === selectedRowKey) {
                    const findedLineIndex = line.children.findIndex(({ id }) => id === selectedRowKey);
                    findedLine.levelFormula = { belongsToId: findedLine.id, formulas: formula };
                    line.children[findedLineIndex] = findedLine;
                    newReport.levels[index] = line;
                    setReport(newReport);
                    break;
                }
            }
        }

        setIsLoading(false);
        setHasSavedValue(true);
        setHasUnsavedChanges(false);
        const level: LevelReport = findNextLevelToSelect(selectedRowKey);
        if (level) {
            setSelectedRowKey(level.id);
            setSelectedRowFormulasFormated(buildFormatedFormula(level.levelFormula));
            setSelectedRowFormula(level.id);
        }
        if (pendingLevelSelected && pendingLevelSelected.id === selectedRowFormula) {
            setSelectedRowKey(pendingLevelSelected.id);
            setPendingLevelSelected(null);
        }
    }

    function findNextLevelToSelect(currentKey: number): LevelReport {
        let flattedLevels: LevelReport[] = flattenHierarchy(report.levels);
        let indexLineToSelect = flattedLevels.findIndex(({ id }) => id === currentKey);
        let newLevelToSelect: LevelReport = flattedLevels[indexLineToSelect];
        flattedLevels = flattedLevels.filter((_, index) => index > indexLineToSelect);
        const searchLevel = (levels: LevelReport[]) => {
            for (let index = 0; index < levels.length; index++) {
                const level = levels[index];
                if (level.title) continue;
                if (level.isSubLevel) {
                    newLevelToSelect = level;
                    break;
                } else if (level.children?.length) {
                    newLevelToSelect = levels[index + 1];
                    break;
                } else {
                    newLevelToSelect = levels[index];
                    break;
                }
            }
        };
        searchLevel(flattedLevels);
        return newLevelToSelect;
    }

    function buildFormatedFormula(levelFormula: LevelFormula): FormulaItem[] {
        return (
            levelFormula.formulas
                .sort((a, b) => (a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1))
                .map(({ type, attributeId, levelId, operator }) => {
                    const keyItem =
                        FormulaType.OPERATORS === type
                            ? Math.random()
                            : FormulaType.ATTRIBUTE === type
                            ? attributeId
                            : FormulaType.LEVEL === type
                            ? levelId
                            : "";
                    const typeItem =
                        FormulaType.OPERATORS === type
                            ? "operator"
                            : FormulaType.INFORMED_VALUE === type
                            ? "value"
                            : "attribute";

                    return {
                        content:
                            FormulaType.OPERATORS === type
                                ? chooseType[operator]
                                : { id: keyItem, name: getFormattedLabelLevelFormula(type, keyItem) },
                        type: typeItem,
                        key: `${FormulaType[type]}-${keyItem}`,
                        levelId: levelId,
                    };
                }) || []
        );
    }

    function getFormattedLabelLevelFormula(type: FormulaType | string, keyItem: string | number): string {
        const label = optionsForFormula.find(({ value }) => value.toString().includes(`${type}-${keyItem}`))?.label;
        return FormulaType.LEVEL === type ? `${i18n.t("report")}: ${label}` : label;
    }

    const onCancelChangeFormulas = () => {
        setSelectedOptionsList(
            selectedRowFormulasFormated
                .filter(({ key }) => key.toString().startsWith("LEVEL") || key.toString().startsWith("ATTRIBUTE"))
                ?.map(({ key }) => key)
        );
        setSelectedRowFormulasFormated([...selectedRowFormulasFormated]);
        setHasUnsavedChanges(false);
    };

    function removeKeyFormula(formulaToRemove: FormulaItem[]): FormulaItem[] {
        return formulaToRemove.map(({ content, type, value }) => {
            const newContent = Number(content.id)
                ? content
                : { id: "", name: i18n.t(`new_sales_report.options_formula.${type}`) };
            return {
                content: newContent,
                type,
                value,
            };
        });
    }

    return (
        <FormulaContext.Provider
            value={{
                form,
                formula,
                setFormula,
                selectedOptionsList,
                setSelectedOptionsList,
                optionsForFormula,
                setOptionsForFormula,
                allListFormula,
                setAllListFormula,
                updateOptionsRecursively,
                setUpdateOptionsRecursively,
                isLoading,
                setIsLoading,
                planningCondition,
                setPlanningCondition,
                selectedRowFormulasFormated,
                setSelectedRowFormulasFormated,
                levels,
                setLevels,
                pendingLevelSelected,
                setPendingLevelSelected,
                selectedRowFilters,
                setSelectedRowFilters,
                selectRowPlanningFilters,
                setSelectedRowPlanningFilters,
                hasUnsavedChanges,
                setHasUnsavedChanges,
                hasSavedValue,
                setHasSavedValue,
                levelsValue,
                setLevelsValue,
                operations,
                setOperations,
                classNameForPulseButtonFilter,
                setClassNameForPulseButtonFilter,
                classNameForPulseButtonFormula,
                setClassNameForPulseButtonFormula,
                filterType,
                setFilterType,
                availableOperations,
                setAvailableOperations,
                conditions,
                setConditions,
                flexFieldsValues,
                setFlexFieldsValues,
                hasRemovedLevel,
                setHasRemovedLevel,
                onAcceptFormulaChanges,
                validateLevelsOnFormula,
                validateFormulaIsEqual,
                validateFilter,
                handleCancelPendingLevel,
                onSaveFormula,
                onCancelChangeFormulas,
                lines,
                setLines,
                isSelectedLevel,
                setIsSelectedLevel,
            }}
        >
            {children}
        </FormulaContext.Provider>
    );
};

export const useFormulaContext = () => {
    const formulaContext = useContext(FormulaContext);
    return formulaContext;
};
