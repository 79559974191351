import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { IBalanceDetailTable } from "../IAccountingBalances";

export default function BalanceDetailTable({ detailTableData, loadingDetailTable }: IBalanceDetailTable) {

    const tableColumns: ColumnsType = [
        {
            title: i18n.t("company_flex"),
            dataIndex: "companyFlexName",
            key: "companyFlexName",
            align: "left"
        },
        {
            title: i18n.t("grouper"),
            dataIndex: "projectionGrouperName",
            key: "projectionGrouperName",
            align: "left"
        },
        {
            title: i18n.t("launch"),
            dataIndex: "description",
            key: "description",
            align: "left"
        },
        {
            title: i18n.t("cost_center"),
            dataIndex: "managementCostCenterName",
            key: "managementCostCenterName",
            align: "left"
        },
        {
            title: i18n.t("credit"),
            dataIndex: "creditValue",
            key: "creditValue",
            align: "center",
            width: 150,
            render: value => formatCurrency(value)
        },
        {
            title: i18n.t("debit"),
            dataIndex: "debitValue",
            key: "debitValue",
            align: "center",
            width: 150,
            render: value => formatCurrency(value)
        }
    ];

    const formatCurrency = (value: number): string => {
        return new Intl.NumberFormat("pt-BR", {
            notation: "standard",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
    };

    return (
        <div className="content-tables">
            <Table
                scroll={{ y: 580 }}
                className="gs-table"
                columns={tableColumns}
                dataSource={detailTableData}
                loading={loadingDetailTable}
            />
        </div>
    );
}
