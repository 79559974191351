import { FlexFilter, Options } from "util/types/types";
import { ApportionmentData, TableData } from "../IApportionment";

export function createTableData(
    apportionment,
    accountOptions: Options[],
    costCenterOptions: Options[],
    businessUnitOptions: Options[],
    accountLevelOptions: Options[],
    flexFieldOptions: FlexFilter[]
) {
    let tableData = {
        year: apportionment.year?.year(),
        flexFields: []
    } as TableData
    let tableKey = ''

    Object.keys(apportionment).forEach(key => {
        if (key.includes('costCenter')) {
            tableKey = apportionment[key]
            Object.assign(tableData, { costCenter: costCenterOptions.find(costCenter => costCenter.value === apportionment[key]) })
        } else if (key.includes('account')) {
            tableKey += `-${apportionment[key]}`
            Object.assign(tableData, { account: accountOptions.find(acount => acount.value == apportionment[key]) })
        } else if (key.includes('businessUnit')) {
            tableKey += `-${apportionment[key]}`
            Object.assign(tableData, { businessUnit: businessUnitOptions.find(bu => bu.value === apportionment[key]) })
        } else if (key.includes('accLevel')) {
            tableKey += `-${apportionment[key]}`
            Object.assign(tableData, { accLevel: accountLevelOptions.find(acount => acount.value == apportionment[key]) })
        } else if (!key.includes('year')) {
            tableKey += `-${apportionment[key]}`
            const flexField = flexFieldOptions.find(ff => ff.value === Number(key.split('-')[1]))
            tableData.flexFields.push(flexField.children.find(ff => ff.value === apportionment[key]))
        }
    })
    Object.assign(tableData, { key: tableKey })

    return tableData
}