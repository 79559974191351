import { Table, Tooltip } from "antd";
import i18n from "util/base/i18n";
import { ApportionmentResponse, Column, IApportionmentList } from "../../../IApportionment";
import { paginationConfig } from "util/TableUtils";
import { Icon } from "@iconify/react";
import { ImageBox } from "components/imageBox/ImageBox";
import { useAccountAccountingOptions } from "hooks/useAccountAccountingOptions";
import { Loading } from "components/loading/Loading";

export function ApportionmentList({
    apportionmentList,
    onOpenApportionmentInfo,
    isFetchingApportionments,
    selectedRowKeys,
    setSelectedRowKeys,
    costCenterOptions,
    flexFieldsOriginOptions,
    flexFieldsDestinationOptions,
    setIsButtonLoading,
    isButtonLoading,
    additionalOptions,
    allFlexFields,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    totalItems,
}: IApportionmentList) {

    const { data: accountAccounting } = useAccountAccountingOptions();

    const accountAccountingOptions = accountAccounting
        ? [...additionalOptions, ...accountAccounting]
        : additionalOptions;


    const generateFlexFieldColumns = (): Column[] => {
        if (!allFlexFields || allFlexFields.length === 0) return [];

        return allFlexFields.map(parentField => ({
            dataIndex: ['flexFields', parentField.value] as string[],
            title: parentField.label,
            key: `flexField_${parentField.value}`,
            className: 'center-text',
            width: 200,
            render: (_, record: any) => {
                const flexFieldsArray = record.flexFieldFilter?.split(':') || [];

                const matchedLabels = flexFieldsArray
                    .map((flexValue, index) => {
                        if (flexValue === '') return null;
                        if ( index + 1 !== parentField.fieldCode ) return null;
                        const matchingField = parentField.children?.find(
                            child => child.value === Number(flexValue)
                        );
                        return matchingField ? matchingField.label : null;
                    })
                    .filter(Boolean);

                const uniqueMatchedLabels = Array.from(new Set(matchedLabels));

                return uniqueMatchedLabels.length === 1 ? uniqueMatchedLabels[0] : '-';
            }
        }));
    };

    const dynamicColumns = generateFlexFieldColumns();

    const columns: Column[] = [
        {
            dataIndex: 'businessUnitName',
            title: `${i18n.t('origin')} `,
            key: 'businessUnit',
            children: [
                {
                    dataIndex: 'businessUnitName',
                    title: `${i18n.t('business_unit')} `,
                    key: 'businessUnit',
                    render: value => {
                        const mapping = {
                            ALL: i18n.t('all'),
                            ORIGINAL: i18n.t('keep_original'),
                        };
                        return mapping[value] || value;
                    }
                },
                {
                    dataIndex: 'costCenterId',
                    title: `${i18n.t('cost_center')} `,
                    key: 'costCenter',
                    render: costCenter => {
                        const costCenterItem = costCenterOptions?.find(item => item.value === costCenter);
                        return costCenterItem?.label ? costCenterItem.label : "";
                    }
                },
                {
                    dataIndex: 'accountingAccountId',
                    title: `${i18n.t('account')} `,
                    key: 'account',
                    render: accountingAccount => {
                        const accountingAccountItem = accountAccountingOptions?.find(item => item.value === accountingAccount);
                        return accountingAccountItem?.label ? accountingAccountItem.label : "";
                    }
                },
                ...dynamicColumns
            ]
        },
        {
            dataIndex: 'businessUnitName',
            title: i18n.t("destination"),
            key: 'businessUnit',
            fixed: 'left',
            children: [

                {
                    dataIndex: 'businessUnit',
                    title: `${i18n.t('business_unit')}`,
                    key: 'businessUnit',
                    render(value, record: ApportionmentResponse) {
                        if (!record) return ''
                        const data = getLabelList(record, 'businessUnitName')
                        if (data.list.length > 1) {
                            return <Tooltip title={data.list.map(item => <div key={item}>{item}</div>)}>
                                <span className="gs-tag blue">{`${data.amount} ${i18n.t('account')}`}</span>
                            </Tooltip>
                        } else {
                            const mapping = {
                                ALL: i18n.t('all'),
                                ORIGINAL: i18n.t('keep_original'),
                            };
                            return mapping[data.list[0]] || data.list[0];
                        }
                    }
                },
                {
                    dataIndex: 'costCenter',
                    title: `${i18n.t('cost_center')}`,
                    key: 'costCenter',
                    render(value, record: ApportionmentResponse) {
                        if (!record.destinationApportioments) return '';

                        const costCenterLabels = record.destinationApportioments
                            .map(dest => {
                                const costCenterItem = costCenterOptions?.find(item => item.value === dest.costCenterId);
                                return costCenterItem?.label || null;
                            })
                            .filter(Boolean);

                        const uniqueCostCenterLabels = Array.from(new Set(costCenterLabels));

                        if (uniqueCostCenterLabels.length > 1) {
                            return (
                                <Tooltip title={uniqueCostCenterLabels.map(label => <div key={label}>{label}</div>)}>
                                    <span className="gs-tag blue">{`${uniqueCostCenterLabels.length} ${i18n.t('cost_center')}`}</span>
                                </Tooltip>
                            );
                        } else if (uniqueCostCenterLabels.length === 1) {
                            return uniqueCostCenterLabels[0];
                        } else {
                            return '';
                        }
                    }
                },
                {
                    dataIndex: 'account',
                    title: `${i18n.t('account')}`,
                    key: 'account',
                    render(value, record: ApportionmentResponse) {
                        if (!record.destinationApportioments) return '';

                        const accountLabels = record.destinationApportioments
                            .map(dest => {
                                const accountingAccountItem = accountAccountingOptions?.find(item => item.value === dest.accountingAccountId);
                                return accountingAccountItem?.label || null;
                            })
                            .filter(Boolean);

                        const uniqueAccountLabels = Array.from(new Set(accountLabels));

                        if (uniqueAccountLabels.length > 1) {
                            return (
                                <Tooltip title={uniqueAccountLabels.map(label => <div key={label}>{label}</div>)}>
                                    <span className="gs-tag blue">{`${uniqueAccountLabels.length} ${i18n.t('account')}`}</span>
                                </Tooltip>
                            );
                        } else if (uniqueAccountLabels.length === 1) {
                            return uniqueAccountLabels[0];
                        } else {
                            return '';
                        }
                    }
                },
                {
                    className: 'center-text',
                    dataIndex: 'flexFieldFilter',
                    title: `${i18n.t('flexField.modalTitle')} `,
                    key: 'flexFieldsDestination',
                    render: (_: any, record: ApportionmentResponse) => {
                        if (!record.destinationApportioments || record.destinationApportioments.length === 0) {
                            return '';
                        }

                        const destinationFlexFields = record.destinationApportioments
                            .map(dest => dest.flexFieldFilter)
                            .filter(Boolean);

                        const findLabelInChildren = (children: any[], value: string) => {
                            const matchedChild = children.find(child => child.value === Number(value));
                            return matchedChild?.label || null;
                        };

                        const matchedLabels = destinationFlexFields
                            .flatMap(flexFieldFilter => {
                                const flexFieldsArray = flexFieldFilter.split(':');
                                return flexFieldsArray.map(flexValue => {
                                    if (flexValue === '') return null;
                                    const parent = allFlexFields?.find(item =>
                                        item.children?.some(child => child.value === Number(flexValue))
                                    );
                                    return parent
                                        ? findLabelInChildren(parent.children, flexValue)
                                        : null;
                                });
                            })
                            .filter(Boolean);

                        const uniqueMatchedLabels = Array.from(new Set(matchedLabels));

                        if (uniqueMatchedLabels.length > 1) {
                            return (
                                <Tooltip title={uniqueMatchedLabels.map(label => <div key={label}>{label}</div>)}>
                                    <span className="gs-tag blue">{`${uniqueMatchedLabels.length} ${i18n.t('flexible_fields')}`}</span>
                                </Tooltip>
                            );
                        } else if (uniqueMatchedLabels.length === 1) {
                            return uniqueMatchedLabels[0];
                        } else {
                            return '-';
                        }
                    },
                },
            ],
        },
        {
            dataIndex: 'apportionmentType',
            title: i18n.t("apportionment_type"),
            key: 'apportionmentType',
            className: 'center-text',
            render(value) {
                return <span className="gs-tag gray">{i18n.t(value.toLowerCase())}</span>;
            }
        },
        {
            dataIndex: 'operation',
            title: '',
            key: 'operation',
            fixed: 'right',
            className: 'operation-column',
            render(_,record: ApportionmentResponse) {
                return <Icon
                    icon="tabler:chevron-right"
                    className="open-apportionment-info-btn"
                    onClick={() => onOpenApportionmentInfo(record)}
                />
            }
        },
    ]
    if (apportionmentList.some(item => item.transitoryApportionment)) {
        columns.unshift({
            dataIndex: 'transitory',
            title: '',
            key: 'operation',
            className: 'transitory-icon-column',
            fixed: 'right',
            render(_, record: ApportionmentResponse) {
                if (record.transitoryApportionment) {
                    return <Icon icon="fa6-solid:retweet" style={{ color: '#E0A42F' }} />
                }
                return ''
            },
        },)
    }
    function getLabelList(record: ApportionmentResponse, item): { amount: number, list: string[] } {
        const data = record?.destinationApportioments.reduce((acc, value) => {
            if (!acc.list.includes(value[item])) {
                acc.list.push(value[item]);
                acc.amount = acc.amount + 1
            }
            return acc
        }, { list: [], amount: 0 });

        return data
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys: React.Key[], selectedRows: any[]) => {
            setSelectedRowKeys(keys);
        },
    };

    return (
        <>
            {isButtonLoading ? (
                <Loading />
            ) : isFetchingApportionments || apportionmentList.length === 0 ? (
                <ImageBox
                    imgName="budget"
                    message="Selecione os filtros na parte superior da tela para continuar."
                    styles={{ marginTop: 150 }}
                />
            ) : (
                <Table
                    style={{ width: '100%', marginBottom: 40 }}
                    columns={columns}
                    loading={isFetchingApportionments || isButtonLoading}
                    className="gs-table"
                    id="apportionment-list-table"
                    dataSource={apportionmentList.map(item => ({ ...item, key: item.id }))}
                    pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        current: currentPage,
                        pageSize: currentPageSize,
                        onChange(page: number, pageSize: number) {
                            setCurrentPage(page);
                            setCurrentPageSize(pageSize);
                        },
                        total: totalItems,
                        pageSizeOptions: [100, 250, 500, 1000],
                        hideOnSinglePage: false,
                    }}
                    rowSelection={{
                        fixed: true,
                        ...rowSelection,
                    }}
                    bordered
                    scroll={{ x: 'max-content' }}
                    onRow={(record) => ({
                        onDoubleClick: () => onOpenApportionmentInfo(record),
                        style: { cursor: 'pointer' }
                    })}
                />
            )}
        </>
    );
}
