import { Button, Collapse, Form, FormInstance, InputNumber, InputRef, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import i18n from "util/base/i18n";
import { tableProps } from "util/props/props";
import { EditableCellProps, IColumnsProps, IValuePeriodTableData } from "../../revenue/recurringContract/IRecurringContract";
import StringUtil from "util/StringUtil";
import { EditableRowProps } from "../../detailLayout/IDetailLayout";
import { NumericFormat } from "react-number-format";
import { Icon } from "@iconify/react";
import moment from "moment";
import { cloneDeep } from "lodash";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import { formatNumber } from "util/formatNumber";
import { IDistributionRuleData } from "../IDistributionRule";
import { FormattedNumber } from "react-intl";


interface IValuePeriodTableProps {
	monthsForEdit?: { [value: string]: number };
	initialDate: moment.Moment;
	finalDate: moment.Moment;
}
export default function ValuePeriodTable({
	monthsForEdit,
	initialDate,
	finalDate,
}: IValuePeriodTableProps) {
	const {
		monthValuesData,
		setMonthValuesData,
		isModalOpen,
		ruleForEdit,
		setHasEdited
	} = useDistributionRuleContext();
	const defaultColumnValuePeriod: IColumnsProps[] = [{
		title: i18n.t("months"),
		dataIndex: "months",
		key: "months",
		width: 70
	}]
	const defaultDateValuePeriod = {
		key: "months",
		months: i18n.t("rate"),
	}
	const [cols, setCols] = useState<IColumnsProps[]>(defaultColumnValuePeriod);
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const columns = cols.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: any) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	const EditableCell: React.FC<EditableCellProps> = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef<InputRef>(null);
		const form = useContext(EditableContext)!;

		useEffect(() => {
			if (editing) {
				inputRef.current!.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({ [dataIndex]: record[dataIndex] });
		};

		const save = async () => {
			try {
				const values: { [value: string]: number | string } = await form.validateFields();
				const keyIndex: string = Object.keys(values)[0];
				const value: string = values[keyIndex].toString();

				let formattedValue: number = Number(value.replace(",", ".") || 0);
				if (formattedValue > 100) formattedValue = 100;
				formattedValue = parseFloat(formattedValue.toFixed(16));

				values[keyIndex] = formattedValue;

				setHasEdited(true);
				toggleEdit();
				handleSave({ ...record, ...values }, keyIndex);
			} catch (errInfo) {
				console.log("Save failed:", errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{ margin: 0 }}
					name={dataIndex}
				>
					<NumericFormat
						style={{ width: "100%" }}
						decimalSeparator=","
						decimalScale={16}
						className="gs-input-numeric-format"
						max={100}
						allowNegative={false}
						getInputRef={inputRef}
						onBlur={save}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
								save();
								return;
							}
						}}
					/>
				</Form.Item>
			) : (
					<div className="gs-table-input-editable-cell">
						<div
							className="editable-cell-value-wrap"
							style={{ paddingRight: 24 }}
							onClick={toggleEdit}
						>
						{children}
					</div>
						<div className="gs-table-replicate-buttons-container right">
							<Tooltip
								placement="topLeft"
								title={i18n.t<string>("replicate_to_next_months")}
							>
								<Button
									style={{
										border: "none",
										boxShadow: "none",
										color: "#0065B3",
										background: "none",
									}}
									icon={
										<Icon
											style={{ boxShadow: "none", color: "#A6A7A7" }}
											icon={"material-symbols:content-copy"}
										/>
									}
									onClick={() => doReplicateValues(record, dataIndex)}
								/>
							</Tooltip>
						</div>
					</div>
			);
		}

		return <td {...restProps}>{childNode}</td>;
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	function doReplicateValues(table, cellIndex) {
		setHasEdited(true);
		const cellPeriod = cellIndex.replace("/", "-");
		const cellMoment = moment(cellPeriod);
		let currentDate = initialDate.clone();

		while (currentDate.isSameOrBefore(finalDate)) {
			const key = currentDate.format("YYYY/MM");
			if (currentDate.isAfter(cellMoment)) {
				table[key] = table[cellIndex];
			}

			currentDate.add(1, 'month');
		}

		setMonthValuesData([table]);
	}

	const buildMonthValuesByRuleEdit = ({ values }: IDistributionRuleData): {} => {
		const newMonthValues = {};
		values.forEach(({ period, percentageValue }) => {
			const periodFormatted: string = moment(period).format("YYYY/MM");
			newMonthValues[periodFormatted] = percentageValue;
		})

		return newMonthValues;
	}

	const handleSave = (row: any) => {
		const newData = [...monthValuesData];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setMonthValuesData(newData);
	};

	const handleCloseValuePeriodTable = () => {
		const clonedDefaultData: { key: string, months: string } = cloneDeep(defaultDateValuePeriod);
		setMonthValuesData([clonedDefaultData]);
		setCols(defaultColumnValuePeriod);
	}

	useEffect(() => {
		if (!isModalOpen || !(initialDate && finalDate)) {
			handleCloseValuePeriodTable();
			return;
		};

		let newCols = [];
		setCols(() => {
			let currentDate = initialDate.clone();
			let newColumns: IColumnsProps[] = [];

			while (currentDate.isSameOrBefore(finalDate)) {
				const columnIndex: string = currentDate.format("YYYY/MM")
				newColumns.push({
					title: StringUtil.capitalizeFirstLetter(currentDate.format("MMM YYYY")),
					dataIndex: columnIndex,
					key: columnIndex,
					render: (value: number = 0) => {
						const formattedValue: number = value;
						return (
							<FormattedNumber
								value={formattedValue}
								minimumFractionDigits={2}
								maximumFractionDigits={16}
							/>
						)
					},
					align: "center",
					editable: true
				});
				currentDate.add(1, 'month');
			}
			newCols = [...defaultColumnValuePeriod, ...newColumns];
			return [...defaultColumnValuePeriod, ...newColumns];
		});

		setMonthValuesData(state => {
			const clonedDefaultData: { key: string, months: string } = cloneDeep(defaultDateValuePeriod);
			if (ruleForEdit) {
				const newMonthValues = buildMonthValuesByRuleEdit(ruleForEdit);
				return [{ ...clonedDefaultData, ...newMonthValues }];
			} else {
				const line = cloneDeep(state[0]);
				if (!line) return [];
				newCols.forEach(({ key }, index) => {
					if (!index) return;

					let newValue = 0;
					if (monthsForEdit && Object.keys(monthsForEdit).length) {
						const monthToEdit = monthsForEdit[moment(key).format("YYYY-MM")];
						if (monthToEdit) newValue = monthToEdit;
					}

					if (moment(key).isSameOrAfter(initialDate) && moment(key).isSameOrBefore(finalDate)) {
						line[key] = newValue;
					} else {
						line[key] = 0;
					}

				})
				return [{ ...clonedDefaultData, ...line }];
			}
		});
	}, [initialDate, finalDate, isModalOpen, ruleForEdit]);

	return (
		<Collapse ghost>
			<Collapse.Panel header={i18n.t("distribution_rules.rate_period")} key={1}>
				<Table
					dataSource={monthValuesData}
					columns={columns as ColumnsType}
					scroll={{
						x: "max-content"
					}}
					components={components}
					{...tableProps}
				/>
			</Collapse.Panel>
		</Collapse>
	)
}