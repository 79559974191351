import { Checkbox, Col, Input, Row } from "antd";
import { CheckboxOptionType } from "antd/es/checkbox";
import { useEffect, useRef, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import StringUtil from "util/StringUtil";
import { useNewReportContext } from "../context/ReportRegistrationContext";
import {
    CheckboxOptionFormula,
    Formula,
    FormulaItem,
    FormulaType,
    LevelReport,
    Line,
    OptionSelect,
} from "../IReportRegistration";
import { useFormulaContext } from "./context/FormulaContext";

export default function OptionsList() {
    const [searchValue, setSearchValue] = useState("");
    const [filteredDefaultOptions, setFilteredDefaultOptions] = useState<OptionSelect[]>([]);
    const [optionsSelected, setOptionsSelected] = useState<CheckboxOptionFormula[]>([]);
    const prevOptionsSelected = useRef<CheckboxOptionFormula[]>();

    const { report, isPlanningReport, selectedRowKey, selectedRowFormula, setSelectedRowFormula } =
        useNewReportContext();

    const {
        formula,
        setFormula,
        selectedOptionsList,
        setSelectedOptionsList,
        updateOptionsRecursively,
        optionsForFormula,
        validateLevelsOnFormula,
        hasRemovedLevel,
        setHasRemovedLevel,
        lines,
        setLines,
        isSelectedLevel,
        setIsSelectedLevel,
        setHasUnsavedChanges,
    } = useFormulaContext();

    const optionsFormulaFiltered = optionsForFormula.filter(
        ({ value }) => value.startsWith(FormulaType.ATTRIBUTE) || validateLevelsOnFormula(value)
    );
    const [defaultOptions, setDefaultOptions] = useState<OptionSelect[]>(optionsFormulaFiltered);

    useEffect(() => {
        if (isPlanningReport()) {
            fetchPlanningOptions();
        }

        setSelectedRowFormula(report.levels[0].id);
    }, []);

    useEffect(() => {
        if (!report.levels.length) return;

        if (selectedRowFormula) handleLevelSelection();
        if (selectedRowKey) handleLevelStructure();
    }, [selectedRowFormula, selectedRowKey, defaultOptions, report.levels, updateOptionsRecursively, hasRemovedLevel]);

    useEffect(() => {
        if (formula.length) {
            const linesFormated = formatLinesWithFormula(lines, formula, report.levels, selectedRowKey);
            setLines(updateBelongsLines(linesFormated));
            validateIsSelectedLevel();
        }
    }, [formula]);

    useEffect(() => {
        if (selectedRowKey && lines.length) {
            const filteredOptions = filterDefaultOptions(
                defaultOptions,
                lines,
                selectedRowKey,
                isSelectedLevel,
                formula
            );
            setFilteredDefaultOptions(filteredOptions);
        }
    }, [lines]);

    useEffect(() => {
        if (optionsSelected && prevOptionsSelected.current !== optionsSelected) {
            prevOptionsSelected.current = optionsSelected;

            addAttributeToFormula(optionsSelected);
        }
    }, [optionsSelected]);

    useEffect(() => {
        if (selectedOptionsList.length) {
            const checkboxOptions = getCheckboxOptionsFromSelectedList();
            addAttributeToFormula(checkboxOptions);
        }
    }, [selectedOptionsList]);

    useEffect(() => {
        if (hasRemovedLevel) {
            handleRemovedLevel(lines, selectedRowKey, defaultOptions, selectedOptionsList);
            setHasRemovedLevel(false);
            validateIsSelectedLevel();
        }
    }, [hasRemovedLevel]);

    const fetchPlanningOptions = () => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-report/report/get-option-formulas",
            },
            onLoadOptions
        );

        setFilteredDefaultOptions([]);
    };

    const handleLevelSelection = () => {
        const level = report.levels.find((lvl) => lvl.id === selectedRowFormula);
        if (!level) return;

        const hasLevel = level.formulas?.some((operation) => operation.type === FormulaType.LEVEL);
        if (hasLevel) {
            const allRowsFormula = buildAllRowsFormula(report.levels);
            const initialLines = updateBelongsLines(allRowsFormula);
            setLines(initialLines);
        } else {
            const uniqueOptions = new Set();

            if (level.formulas.length === 0) {
                setSelectedOptionsList([]);
                return;
            }

            level.formulas.forEach((formula) => {
                uniqueOptions.add(formula.id);
            });

            setSelectedOptionsList([...uniqueOptions] as string[]);
        }
        validateIsSelectedLevel();
    };

    const handleLevelStructure = () => {
        setSelectedOptionsList([]);
        const levels = report.levels;
        const selectedLevel = levels.find((level) => level.id === selectedRowKey);
        if (!selectedLevel) return;

        const allRowsFormula: Line[] = [];

        levels.forEach((level) => {
            const newLine: Line = { id: level.id, name: `${level.externalCode} - ${level.description}`, subLines: [] };

            level.levelFormula?.formulas?.forEach((formula) => {
                if (formula.type === FormulaType.LEVEL && formula.levelId) {
                    const relatedLevel = levels.find((lvl) => lvl.id === formula.levelId);
                    if (relatedLevel) {
                        const subLine: Line = {
                            id: level.id,
                            name: `${relatedLevel.externalCode} - ${relatedLevel.description}`,
                            subLines: [],
                        };
                        newLine.subLines.push(subLine);
                    }
                }
            });

            const parentLine = allRowsFormula.find((row) => row.name === level.externalCode);
            if (parentLine) {
                addSubLine(parentLine, newLine);
            } else {
                allRowsFormula.push(newLine);
            }
        });

        const initialLines = updateBelongsLines(allRowsFormula);
        setLines(initialLines);
        validateIsSelectedLevel();
    };

    const buildAllRowsFormula = (levels: any[]) => {
        return levels.map((level) => {
            const newLine: Line = { id: level.id, name: `${level.externalCode} - ${level.description}`, subLines: [] };
            level.levelFormula?.formulas?.forEach((formula: Formula) => {
                if (formula.type === FormulaType.LEVEL && formula.levelId) {
                    const relatedLevel = levels.find((lvl) => lvl.id === formula.levelId);
                    if (relatedLevel) {
                        const subLine: Line = {
                            id: level.id,
                            name: `${relatedLevel.externalCode} - ${relatedLevel.description}`,
                            subLines: [],
                        };
                        newLine.subLines.push(subLine);
                    }
                } else {
                    return formula;
                }
            });
            return newLine;
        });
    };

    const formatLinesWithFormula = (
        lines: Line[],
        formula: FormulaItem[],
        levels: LevelReport[],
        selectedRowKey: number
    ) => {
        const linesFormated: Line[] = lines.map((line) => ({ ...line, subLines: [...line.subLines] }));
        const processedKeys = new Set<string>();

        formula.forEach((formulaItem) => {
            if (formulaItem.type === "attribute") {
                const formulaName: string = formulaItem.content.name?.split(":").pop().trim();
                if (!formulaName) return;

                const selectedLevel = levels.find((level) => level.id === selectedRowKey);
                const externalCode = `Relatório: ${selectedLevel.externalCode} - ${selectedLevel.description}`;
                const currentLine = linesFormated.find((line) => line.name === externalCode);

                const lineToAdd = lines.find((line) => line.name === formulaName);

                if (
                    currentLine &&
                    lineToAdd &&
                    !currentLine.subLines.some((subLine) => subLine.name === lineToAdd.name)
                ) {
                    currentLine.subLines = currentLine.subLines.concat({ ...lineToAdd });
                }

                if (formulaItem.content.id === "") {
                    formulaItem.content.id = formulaItem.key;
                }

                processedKeys.add(formulaItem.key);
            }
        });

        return linesFormated;
    };

    const filterDefaultOptions = (
        defaultOptions: OptionSelect[],
        lines: Line[],
        selectedRowKey: number,
        isSelectedLevel: Boolean,
        formula: FormulaItem[]
    ) => {
        const updatedOptions = new Set(defaultOptions.map((option) => option.value));
        const relatedLevels: number[] = [];

        const updateRecursiveOptions = (externalCode: string) => {
            const currentLine = lines.find((line) => line.name === externalCode);
            if (!currentLine) return;

            const hasRelated = relatedLevels.find((level) => level === selectedRowKey);
            if (hasRelated && currentLine.id === selectedRowKey) return;

            currentLine.belongsLines?.forEach((line) => {
                const optionToRemove = defaultOptions.find((option) => option.label === line.name);
                if (optionToRemove && optionToRemove.value !== updateOptionsRecursively) {
                    updatedOptions.delete(optionToRemove.value);
                    relatedLevels.push(Number(optionToRemove.value.split("-").pop()));
                }

                if (currentLine.belongsLines?.length) {
                    const lineRelated = currentLine.belongsLines.find((belongs) => belongs.name === line.name);
                    if (lineRelated) {
                        updateRecursiveOptions(lineRelated.name);
                    }
                }
            });

            currentLine.subLines.forEach((subLine) => {
                const optionToRemove = defaultOptions.find((option) => option.label === subLine.name);
                if (optionToRemove && optionToRemove.value !== updateOptionsRecursively) {
                    updatedOptions.delete(optionToRemove.value);
                    relatedLevels.push(Number(optionToRemove.value.split("-").pop()));
                }

                if (subLine.subLines.length) {
                    updateRecursiveOptions(subLine.name);
                }
            });
        };

        const selectedLevel = report.levels.find((level) => level.id === selectedRowKey);
        updateRecursiveOptions(`${selectedLevel.externalCode} - ${selectedLevel.description}`);

        const filteredOptions = defaultOptions.filter((option) => updatedOptions.has(option.value));
        return filteredOptions.filter((option) => {
            const [preffix] = option.value.split("-");
            const hasSelectedLevel =
                isSelectedLevel !== null ? (isSelectedLevel ? preffix === "LEVEL" : preffix !== "LEVEL") : true;

            if (formula.length === 0) return hasSelectedLevel;

            const hasFormulaAttribute = formula.filter((formula) => {
                if (formula?.key?.split("-")[0] !== "LEVEL") return false;
                return formula.key === option.value;
            });

            return hasSelectedLevel && !hasFormulaAttribute.length;
        });
    };

    const getCheckboxOptionsFromSelectedList = (): CheckboxOptionFormula[] => {
        const selectedOptions: CheckboxOptionFormula[] = formula
            .filter((item) => item.type === "attribute")
            .map((item) => ({
                type: item.type,
                key: item.key,
                content: {
                    id: item.content.id,
                    name: item.content,
                },
            }));

        setOptionsSelected(selectedOptions);
        return selectedOptions;
    };

    const handleRemovedLevel = (
        lines: Line[],
        selectedRowKey: number,
        defaultOptions: OptionSelect[],
        selectedOptionsList: string[]
    ) => {
        const lineToRemove = lines.find((line) => line.id === selectedRowKey);
        if (lineToRemove) {
            removeLine(lineToRemove);
        }

        const updatedOptions = defaultOptions.filter((defaultOption) => {
            return selectedOptionsList.some((optionList) => optionList !== defaultOption.value);
        });

        setFilteredDefaultOptions(updatedOptions);
    };

    const removeLineFromBelongsLines = (allLines: Line[], lineToRemove: Line): Line[] => {
        allLines.forEach((line: Line) => {
            if (line.belongsLines) {
                line.belongsLines = line.belongsLines.filter((belongsLine) => belongsLine.id !== lineToRemove.id);
            }
        });
        return allLines;
    };

    const removeLine = (lineToRemove: Line) => {
        const linesWithUpdatedBelongs: Line[] = removeLineFromBelongsLines(lines, lineToRemove);
        setLines(linesWithUpdatedBelongs);
    };

    const validateIsSelectedLevel = () => {
        const isLevelSelected =
            formula.length === 0 ? null : formula.some((operation) => operation.key.split("-")[0] === "LEVEL");
        setIsSelectedLevel(isLevelSelected);
    };

    const validateAddition = (currentLine: Line, newLine: Line): boolean => {
        const recursivelyContains = (line: Line, target: Line): boolean => {
            return line.subLines.some((sub) => sub.name === target.name || recursivelyContains(sub, target));
        };

        if (currentLine.name === newLine.name || recursivelyContains(newLine, currentLine)) {
            return false;
        }

        for (const subLine of newLine.subLines) {
            if (currentLine.name === subLine.name || recursivelyContains(currentLine, subLine)) {
                return false;
            }
        }

        if (
            newLine.subLines.length > 0 &&
            newLine.subLines.every((sub) => currentLine.subLines.some((existingSub) => existingSub.name === sub.name))
        ) {
            return false;
        }

        return true;
    };

    const addSubLine = (currentLine: Line, newLine: Line): void => {
        if (validateAddition(currentLine, newLine)) {
            setLines(
                lines.map((line) =>
                    line.name === currentLine.name ? { ...line, subLines: [...line.subLines, newLine] } : line
                )
            );
        }
    };

    const updateBelongsLines = (allLines: Line[]): Line[] => {
        const lineMap = new Map<string, Line>();

        allLines.forEach((line) => lineMap.set(line.name, line));

        allLines.forEach((line) => {
            line.subLines.forEach((subLine) => {
                const targetLine = lineMap.get(subLine.name);
                if (targetLine && !targetLine.belongsLines?.some((belongsLine) => belongsLine.id === line.id)) {
                    targetLine.belongsLines = [...(targetLine.belongsLines || []), line];
                }
            });

            removeDuplicateSubLines(line);
        });

        return allLines;
    };

    const removeDuplicateSubLines = (line: Line) => {
        const uniqueSubLines: Line[] = [];
        const seenIds = new Set<number>();

        line.subLines.forEach((subLine) => {
            if (!seenIds.has(subLine.id)) {
                seenIds.add(subLine.id);
                uniqueSubLines.push(subLine);
            }
        });

        line.subLines = uniqueSubLines;
    };

    const addAttributeToFormula = (attributes: CheckboxOptionFormula[]) => {
        if (!attributes?.length) return;

        const formulasAttribute: FormulaItem[] = formula.filter(({ type }) => type === "attribute");
        const [attributeToAdd]: CheckboxOptionFormula[] = attributes.filter(({ key }) => {
            return !formulasAttribute.map(({ key }) => key).includes(key);
        });

        if (!attributeToAdd) return;

        const content = {
            id: attributeToAdd.content.id,
            name: attributeToAdd.content.name,
        };

        setFormula([
            ...formula,
            {
                type: "attribute",
                content,
                key: attributeToAdd.content.id,
            },
        ]);
    };

    const filteredOptionsToCheckboxOption: CheckboxOptionType[] = filteredDefaultOptions
        .map(({ label, value }) => {
            const labelFormatted: string = value.startsWith("LEVEL") ? `${i18n.t("report")}: ${label}` : label;
            const style: React.CSSProperties = isPlanningOption(value) ? { background: "#F1F2F3" } : {};
            return {
                value,
                label: labelFormatted,
                style,
            };
        })
        .filter(
            ({ label, value }) =>
                filterOption(value) &&
                StringUtil.getWithoutSpecials(label)?.includes(StringUtil.getWithoutSpecials(searchValue))
        );

    function filterOption(value: string): boolean {
        return value !== `LEVEL-${selectedRowKey}`;
    }

    function isPlanningOption(value: string): boolean {
        return ["MOVEMENT", "OPENING_BALANCE", "FINAL_BALANCE", "CREDIT", "DEBIT"].includes(value);
    }

    function onLoadOptions(planningOptions: any[]) {
        setDefaultOptions((state) => {
            const newPlanningOptions = planningOptions.map(({ name }) => {
                const translatedName: string = i18n.t(`new_sales_report.options_formula.${name}`);
                return {
                    value: name,
                    label: translatedName,
                };
            });
            return [...newPlanningOptions, ...state];
        });
    }

    return (
        <div className="options-list">
            <Row>{i18n.t<string>("list_of_options")}</Row>
            <div className="options-list-content">
                <Row>
                    <Col span={24}>
                        <Input.Search
                            className="search-business-unit"
                            placeholder={i18n.t<string>("search_values")}
                            value={searchValue}
                            onChange={({ target: { value } }) => setSearchValue(value)}
                        />
                        <Checkbox.Group
                            value={selectedOptionsList}
                            onChange={(listChecked) => {
                                setSelectedOptionsList([...selectedOptionsList, ...listChecked] as string[]);
                                setOptionsSelected(
                                    listChecked.map((checkedId) => {
                                        const matchedOption: any = filteredOptionsToCheckboxOption.find(
                                            ({ value }) => value === checkedId
                                        );

                                        return {
                                            type: FormulaType.ATTRIBUTE,
                                            key: checkedId.toString(),
                                            content: {
                                                id: matchedOption.value,
                                                name: matchedOption.label.toString(),
                                            },
                                            value: checkedId.toString(),
                                        };
                                    })
                                );
                                setHasUnsavedChanges(true);
                            }}
                            options={filteredOptionsToCheckboxOption}
                        />
                        <span className="footer">
                            {filteredOptionsToCheckboxOption?.length} {i18n.t<string>("items")}
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
