import { Icon } from "@iconify/react";
import { Checkbox, Form, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Notification } from "components/notification/Notification";
import { SecudaryButton } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import FlexFieldModal from "./components/FlexFieldModal";
import { FlexFieldTable } from "./components/FlexFieldTable";
import { FlexFieldValues } from "./flexFieldValues/FlexFieldValues";
import { FlexFieldData, GenericSelection } from "./IFlexField";
import "./styles.sass";

export default function FlexField() {
	const [selectedRows, setSelectedRows] = useState<FlexFieldData[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [tableData, setTableData] = useState<FlexFieldData[]>([]);
	const [isFetching, setIsFetching] = useState(true);
	const [form] = Form.useForm<FlexFieldData>();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [listToSave, setListToSave] = useState<FlexFieldData[]>([]);
	const [isNewFlexField, setIsNewFlexField] = useState(true);
	const [toggleSection, setToggleSection] = useState(true);
	const [modules, setModules] = useState<GenericSelection[]>([]);
	const [flexFieldCodeList, setFlexFieldCodeList] = useState<GenericSelection[]>([]);
	const [filters, setFilters] = useState<GenericSelection[]>([]);
	const [filterdTableData, setFilterdTableData] = useState<FlexFieldData[]>([]);
	const [importModalOpen, setImportModalOpen] = useState(false);
	const [availibleFieldCodeList, setAvailibleFieldCodeList] = useState(flexFieldCodeList);

	const importProps: IImportModal[] = [
		{
			importUrl: "/budget-base/flex-field-value/import/filter",
			templateUrl: "/budget-base/flex-field-value/import/filter/template?locale={locale}",
			type: 'excel',
			title: i18n.t("imports.import_flexible_field")
		},
	]
	useEffect(() => {
		fetchFlexFieldTableData()

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/budget-base/flex-field/select/fields",
		}, (data: GenericSelection[]) => {
			data.forEach((d) => {
				let index = d.value.split("_")[2];
				d.label = i18n.t<string>("flexField.modalTitle") + " " + index;
			});
			setFlexFieldCodeList(data);
		})
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/budget-base/flex-field/select/modules",
		}, (data: GenericSelection[]) => {
			data.forEach((d) => {
				d.label = `${i18n.t<string>(d.label)}`;
			});
			setModules(data);
		})
		fetchFlexFieldFiltersToLink([])
	}, []);

	useEffect(() => {
		setFilterdTableData(tableData);
	}, [tableData]);

	function fetchFlexFieldFiltersToLink(modules) {
		var url = "/budget-base/flex-field/select/filters";
		if (modules) {
			url += "?modules=" + modules
		}
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: url,
		}, (data: GenericSelection[]) => {
			setFilters(data.map((item) => {
				return (
					{
						...item,
						label: `${i18n.t(item.label)}`
					}
				)
			}))
		})
	}

	function fetchFlexFieldTableData() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/budget-base/flex-field",
		}, onLoadTableData.bind(this))
	}

	function onLoadTableData(data: FlexFieldData[]) {
		const updateTableData: FlexFieldData[] = data.map(res => {
			return {
				key: res.id,
				id: res.id,
				externalCode: res.externalCode,
				description: res.description,
				modules: res.modules,
				fieldCode: res.fieldCode,
				filters: res.filters,
				informative: res.informative,
				managementSecurity: res.managementSecurity,
				ordenation: res.ordenation,
				isLastLevel: res.isLastLevel,
				isResourceLink: res.isResourceLink,
				details: res.details,
				isOrganization: res.isOrganization,
				isRequired: res.isRequired
			};
		});

		setIsFetching(false);
		setTableData(updateTableData);
		setIsFetching(false);
	};

	function onSaveFlexField(data) {
		Notification({
			type: data ? 'success' : 'error',
			message: data ? i18n.t<string>("successfully_saved") : i18n.t<string>("flex_field_has_account_link"),
		})
		fetchFlexFieldTableData();
		fetchFlexFieldFiltersToLink([])
		setListToSave([]);
		setIsNewFlexField(true);
	}

	function handleOpenModal(isToEdit: boolean) {
		if (isToEdit) {
			setIsNewFlexField(false);
			form.setFieldsValue({
				id: selectedRows[0].id,
				externalCode: selectedRows[0].externalCode,
				description: selectedRows[0].description,
				modules: selectedRows[0].modules,
				fieldCode: selectedRows[0].fieldCode,
				filters: selectedRows[0].filters ? selectedRows[0].filters : [],
				informative: selectedRows[0].informative,
				managementSecurity: selectedRows[0].managementSecurity,
				isLastLevel: selectedRows[0].isLastLevel,
				ordenation: selectedRows[0].ordenation,
				isResourceLink: selectedRows[0].isResourceLink,
				details: selectedRows[0].details?.map((id) => id.toString()),
				isOrganization: selectedRows[0].isOrganization,
				isRequired: selectedRows[0].isRequired
			})
		} else {
			setIsNewFlexField(true);
		}
		setIsModalVisible(true);
	};

	function loadFieldCodeOptions(listToSave) {

		const tableDataFields = tableData.map((item) => {
			if (isNewFlexField || item.fieldCode !== selectedRows[0].fieldCode) {
				return item.fieldCode;
			}
		});

		const filteredData = flexFieldCodeList.filter(cc => !tableDataFields.includes(cc.value));

		const listToSaveFields = listToSave.map((item) => item.fieldCode);

		const updatedOptions = filteredData.filter(cc => !listToSaveFields.includes(cc.value));

		setAvailibleFieldCodeList(updatedOptions);
		form.setFieldsValue({ fieldCode: updatedOptions[0]?.value })
	}

	function handleAddItem(data: FlexFieldData[]) {
		form.resetFields();
		if (isNewFlexField) {
			setListToSave([...listToSave, data[0]]);
			loadFieldCodeOptions([...listToSave, data[0]])
		} else {
			handleSaveBatch(data);
		}
	}

	function handleSaveBatch(data: FlexFieldData[]) {
		if (data.length === 0) {
			Notification({
				type: 'warning',
				message: i18n.t("empty_list_message"),
			});
			return;
		}
		setIsFetching(true);
		setIsModalVisible(false);

		const dataToSave = data.map(flexField => {
			const { description, ...returnFlexField } = flexField;
			return {
				...returnFlexField,
				description: flexField.description.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
				id: isNewFlexField ? null : selectedRows[0].id,
			}
		})
		setSelectedRows([]);
		setSelectedRowKeys([]);
		ServiceCaller.doRequest({
			type: isNewFlexField ? RequestType.POST : RequestType.PUT,
			url: "/budget-base/flex-field?userId={user}",
			useProxy: true,
			params: isNewFlexField ? dataToSave : dataToSave[0],
		}, onSaveFlexField.bind(this))
	}

	function handleDelete() {
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `/budget-base/flex-field?ids=${selectedRowKeys.toString()}`,
		}, () => {
			fetchFlexFieldTableData()
			fetchFlexFieldFiltersToLink([])
		})
	}

	function handleCancel() {
		setSelectedRowKeys([]);
		setListToSave([]);
		setIsModalVisible(false);
		form.resetFields();
	};

	function handleModuleSelect(values) {
		fetchFlexFieldFiltersToLink(values)
	};

	function onSelectRowChange(selectedRowKeys: Key[], selectedRows: FlexFieldData[]) {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	function onClickValueField(a) {
		setToggleSection(false);
	}

	const secundaryButton: SecudaryButton = {
		onClick: onClickValueField,
		title: i18n.t("flexField.field_values"),
		className: "custom-button"
	}

	const tableColumns: ColumnsType = [
		{
			title: i18n.t<string>("order"),
			dataIndex: "ordenation",
			key: "ordenation",
			width: 80,
			align: "center",
			render: (text) => {
				return (
					<div className='field-column'>
						<p>{text + "º"}</p>
						<Icon icon="ph:arrows-out-line-vertical" />
					</div>
				)
			}
		}, {
			title: i18n.t<string>("external_code"),
			dataIndex: "externalCode",
			key: "externalCode",
			align: "left",
			width: 200,
		},
		{
			title: i18n.t<string>("description"),
			dataIndex: "description",
			key: "description",
			className: "description-column",
			align: "left",
		},
		{
			title: i18n.t<string>("flexField.module"),
			dataIndex: "modules",
			key: "modules",
			align: "left",
			render: (text: string[]) => {
				if (!text) return ""
				const moduleList = modules.filter((mod) => text.includes(mod.value));

				return (
					<>
						{
							moduleList.filter((mod) => mod.value === text[0]).map((mod) => mod.label)
						}
						{
							text?.length > 1 &&
							<Tooltip
								arrowPointAtCenter
								color={'rgba(0,0,0,0.9'}
								placement="right"
								title={moduleList.map(mod => <p style={{ margin: 0 }}>{i18n.t<string>(mod.label)}</p>)}
							>
								<span className="additional-items">{`+${text.length - 1}`}</span>
							</Tooltip>
						}
					</>
				)
			}
		},
		{
			title: i18n.t<string>("filter"),
			dataIndex: "filters",
			key: "filters",
			align: "left",
			render: (text: string[]) => {
				if (!text) return;
				const filtersList = filters.filter((mod) => text.includes(mod.value));
				return (
					<>
						{
							filtersList.filter((mod) => mod.value === text[0]).map((mod) => mod.label)
						}
						{
							text.length > 1 &&
							<Tooltip
								arrowPointAtCenter
								color={'rgba(0,0,0,0.9'}
								placement="right"
								title={filtersList.map(mod => <p style={{ margin: 0 }}>{i18n.t<string>(mod.label)}</p>)}
							>
								<span className="additional-items">{`+${text.length - 1}`}</span>
							</Tooltip>
						}
					</>
				)
			}
		},
		{
			title: i18n.t<string>("field"),
			dataIndex: "fieldCode",
			key: "fieldCode",
			align: "left",
			render: (text) => {
				const value = flexFieldCodeList.find(item => item.value === text);
				return value?.label
			},
		},
		{
			title: i18n.t<string>("flexField.managementSecurity"),
			dataIndex: "managementSecurity",
			key: "managementSecurity",
			align: "center",
			width: "200px",
			className: "checkbox-column",
			render: (text) => {
				return (
					<Checkbox disabled checked={text} style={{ color: '#B8B8B8' }} />
				)
			},
		},
		{
			title: i18n.t<string>("detail"),
			dataIndex: "informative",
			key: "informative",
			align: "center",
			width: "100px",
			className: "checkbox-column",
			render: (text) => {
				return (
					<Checkbox disabled checked={text} style={{ color: '#B8B8B8' }} />
				)
			},
		},
		{
			title: i18n.t<string>("flexField.isLastLevel"),
			dataIndex: "isLastLevel",
			key: "isLastLevel",
			align: "center",
			width: "100px",
			className: "checkbox-column",
			render: (text) => {
				return (
					<Checkbox disabled checked={text} style={{ color: '#B8B8B8' }} />
				)
			},
		},
		{
			title: i18n.t<string>("isResourceLink"),
			dataIndex: "isResourceLink",
			key: "isResourceLink",
			align: "center",
			width: "100px",
			className: "checkbox-column",
			render: (text) => {
				return (
					<Checkbox disabled checked={text} style={{ color: '#B8B8B8' }} />
				)
			},
		}
	];

	const translatedTable = () => {
		const translateAndMapData = (data: any[], map: Map<string, string>, translateKey: string) => {
			return data.map((item) => ({
				...item,
				[translateKey]: item[translateKey]
					? item[translateKey].map((value: string) => {
							const label = map.get(value);
							return label ? i18n.t<string>(label) : value;
						})
					: [],
				errors: Object.values(item),
			}));
		};
	
		const modulesMap = new Map(modules.map((module) => [module.value, module.label]));
		const filtersMap = new Map(filters.map((filter) => [filter.value, filter.label]));
	
		const translatedTableModule = translateAndMapData(tableData, modulesMap, "modules");
		
		return translateAndMapData(translatedTableModule, filtersMap, "filters");;
	}

    return toggleSection ? (
        <>
            <div className="page-title-content" id="flex-field-container">
                <h1>{i18n.t("flexField.title")}</h1>
            </div>
            <div className="flex-field-top-buttons">
                <TopButtons
                    mainButtonTitle={i18n.t("flexField.button")}
                    handleNew={() => handleOpenModal(false)}
                    handleEdit={() => handleOpenModal(true)}
                    handleDelete={() => handleDelete()}
                    secondaryButton={secundaryButton}
                    isDeletable={selectedRowKeys.length > 0}
                    isEditable={selectedRowKeys.length === 1}
                    multipleSearch={{
                        tableData: tableData,
                        setTableData: setFilterdTableData,
                        options: [
                            { i18nString: "external_code", description: "externalCode" },
                            { i18nString: "description", description: "description" },
                        ],
                    }}
                />
                <ImportExportMenu
					exportGridData={() => {
						handleExportGridData(
							translatedTable(),
							tableColumns.map((column) => ({ ...column, render: "" })),
							i18n.t("flexField.modalTitle")
						);
                    }}
                    importModalOpen={importModalOpen}
                    setImportModalOpen={setImportModalOpen}
                    importProps={importProps}
                    buttonType="3dots"
                />
            </div>
            <FlexFieldTable
                isFetching={isFetching}
                tableData={filterdTableData}
                onChange={onSelectRowChange}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                filters={filters}
                modules={modules}
                setTableData={setTableData}
                onLoadTableData={onLoadTableData}
                tableColumns={tableColumns}
            />
            <FlexFieldModal
                form={form}
                handleSave={handleSaveBatch}
                handleCancel={handleCancel}
                handleModuleSelect={handleModuleSelect}
                isModalVisible={isModalVisible}
                isNewFlexField={isNewFlexField}
                modules={modules}
                flexFieldCodeList={flexFieldCodeList}
                filters={filters}
                handleSubmit={handleAddItem}
                listToSave={listToSave}
                setListToSave={setListToSave}
                tableData={filterdTableData}
                selectedFlexFieldId={selectedRowKeys}
                selectedRows={selectedRows}
                loadFieldCodeOptions={loadFieldCodeOptions}
                availibleFieldCodeList={availibleFieldCodeList}
            />
        </>
    ) : (
        <>
            <FlexFieldValues flexFieldList={tableData} toggleSection={setToggleSection} modules={modules} />
        </>
    );
}
