import { Icon } from "@iconify/react";
import { Table } from "antd";
import { ColumnTypes } from "module/budget/pages/detailLayout/IDetailLayout";
import { useRef } from "react";
import i18n from "util/base/i18n";
import { useNewReportContext } from "../../context/ReportRegistrationContext";
import { useFormulaContext } from "../../formula/context/FormulaContext";
import { Formula, ILevelStyle, LevelFormula } from "../../IReportRegistration";

export default function StepThree() {
    const { report } = useNewReportContext();

    const { optionsForFormula } = useFormulaContext();

    const columns: ColumnTypes[number][] = [
        {
            title: i18n.t<string>("code_row"),
            dataIndex: "externalCode",
            key: "externalCode",
            className: "line-code-column",
            width: "20%",
            align: "center",
        },
        {
            title: i18n.t("description"),
            dataIndex: "description",
            key: "description",
            render: (value, record) => {
                return (
                    <div style={{ display: "flex", gap: 8, border: "none" }}>
                        <div className={!record["children"]?.length && record["isSubLevel"] ? "caption-level" : ""} />
                        <span>{value}</span>
                    </div>
                );
            },
            className: "description-column-registration-sales",
            width: "30%",
        },
        {
            title: i18n.t<string>("new_sales_report.formula"),
            dataIndex: "levelFormula",
            key: "levelFormula",
            render: ({ formulas }: LevelFormula, record) => {
                let formatedFormula: string = "";
                formulas
                    .sort((a, b) => a.ordination - b.ordination)
                    .forEach((formula) => {
                        formatedFormula += `${chooseType[formula.type](formula)} `;
                    });
                return <div>{formatedFormula}</div>;
            },
            width: "50%",
            className: "formula-column",
        },
    ];

    const chooseType = {
        INFORMED_VALUE: ({ value }: Formula) => {
            return value;
        },
        OPERATORS: ({ operator }: Formula) => {
            return operator;
        },
        LEVEL: ({ levelId }: Formula) => {
            return optionsForFormula?.find(({ value }) => value === `LEVEL-${levelId}`)?.label || "";
        },
        ATTRIBUTE: ({ attributeId }: Formula) => {
            return optionsForFormula?.find(({ value }) => value === `ATTRIBUTE-${attributeId}`)?.label || "";
        },
        OPENING_BALANCE: ({ type }: Formula) => {
            return i18n.t(`new_sales_report.options_formula.${type}`);
        },
        FINAL_BALANCE: ({ type }: Formula) => {
            return i18n.t(`new_sales_report.options_formula.${type}`);
        },
        MOVEMENT: ({ type }: Formula) => {
            return i18n.t(`new_sales_report.options_formula.${type}`);
        },
        DEBIT: ({ type }: Formula) => {
            return i18n.t(`new_sales_report.options_formula.${type}`);
        },
        CREDIT: ({ type }: Formula) => {
            return i18n.t(`new_sales_report.options_formula.${type}`);
        },
    };

    const addStylesClass = ({ isBold, isItalic, isScratched, isUnderlined }: ILevelStyle) => {
        let classStyled = "";
        classStyled += isBold ? "bold" : "";
        classStyled += isItalic ? " italic" : "";
        classStyled += isScratched ? " scratched" : "";
        classStyled += isUnderlined ? " underlined" : "";
        return classStyled;
    };

    const RowRender = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef<HTMLTableRowElement>(null);
        const childrenRecord = Array.isArray(restProps.children) ? restProps.children[0]?.props?.record : null;
        const { children = [], levelStyle = {} } = childrenRecord || {};

        const backgroundColor = levelStyle?.color;
        const fontColor = levelStyle?.colorFont;
        const classStyled = levelStyle ? addStylesClass(levelStyle) : "";
        const fontSize = levelStyle?.fontSize;
        return (
            <tr
                ref={ref}
                className={`${className} ${classStyled} ${children?.length ? "disabled-row" : ""}`}
                style={{ background: backgroundColor, color: fontColor, fontSize: fontSize }}
                {...restProps}
            />
        );
    };

    return (
        <div className="step-one step-three">
            <h3>{report.description}</h3>
            <Table
                columns={columns}
                dataSource={report.levels}
                className="gs-table registration-sales-page"
                pagination={false}
                rowKey={"ordination"}
                components={{
                    body: {
                        row: RowRender,
                    },
                }}
                bordered
                expandable={{
                    expandIcon: ({ record, onExpand, expanded }) => {
                        if (record["children"]) {
                            if (expanded) {
                                return (
                                    <div className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                                        <Icon icon="tabler:chevron-down" />
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                                        <Icon icon="tabler:chevron-right" />
                                    </div>
                                );
                            }
                        }
                    },
                    expandIconColumnIndex: 1,
                }}
            />
        </div>
    );
}
