import { Col, DatePicker, Form, Modal, Row, Select } from "antd";
import { useManagementTransferContext } from "../context/ManagementTransferContext";
import { FlexFilter } from "util/types/types";
import { useEffect, useState } from "react";
import { TableTransfer } from "./TableTransfer";
import i18n from "util/base/i18n";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";
import { ITransferRequest, IValuesMonths } from "../IManagementTransfer";

export default function ManagementTransferModal() {

    const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment] | null>(null);
    const { RangePicker } = DatePicker;

    const {
        isModalOpen,
        setIsModalOpen,
        form,
        costCenterOptions,
        accountAccountingOptionsRequester,
        isFetchingAccountOptionsRequester,
        accountAccountingOptionsAssignor,
        isFetchingAccountOptionsAssignor,
        setCostCenterRequester,
        costCenterRequester,
        setCostCenterAssignor,
        costCenterAssignor,
        openBudgetYears,
        flexFieldsFilters,
        dataSource,
        setDataSource,
        setEditingRecord,
        userInfo,
        flexFieldValues,
        setFlexFieldValues,
        isEditing,
        editingRecord,
        setIsEditing,
        getManagementTransfer,
        setIsLoading,
        setSelectedRowKeys,
        dataFlexFieldFreeze,
        year,
        flexFieldsFiltersAssignor,
        costCenterAssignorOptions
    } = useManagementTransferContext();

    useEffect(() => {
        if (isEditing && editingRecord) {

            if (isEditing && editingRecord) {

                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    requester_cost_center: editingRecord.origin.costCenterId,
                    assignor_cost_center: editingRecord.destination[0].costCenterId,
                    requester_account_accounting: editingRecord.origin.accountId,
                    assignor_account_accounting: editingRecord.destination[0].accountId
                });

            }

            if (editingRecord.origin) {
                setDateRange([
                    moment(editingRecord.startDate, "DD-MM-YYYY"),
                    moment(editingRecord.endDate, "DD-MM-YYYY")
                ]);
            }
            if (editingRecord.values) {
                setDataSource(editingRecord.values);
            }
            const preloadedFlexFieldValues = {};
            if (editingRecord.origin.flexFieldValueIds) {
                flexFieldsFilters.forEach((filter) => {
                    const selectedValue = editingRecord.origin.flexFieldValueIds.find(
                        (id) => filter.children.some((child) => child.value === id)
                    );
                    if (selectedValue) {
                        preloadedFlexFieldValues[`requester_${filter.value}`] = selectedValue;
                    }
                });
            }
            if (editingRecord.destination[0].flexFieldValueIds) {
                flexFieldsFiltersAssignor.forEach((filter) => { 
                    const selectedValue = editingRecord.destination[0].flexFieldValueIds.find(
                        (id) => filter.children.some((child) => child.value === id)
                    );
                    if (selectedValue) {
                        preloadedFlexFieldValues[`assignor_${filter.value}`] = selectedValue;
                    }
                });
            }
            setFlexFieldValues(preloadedFlexFieldValues);
            form.setFieldsValue(preloadedFlexFieldValues);
        }
    }, [isEditing, editingRecord]);

    const handleDateChange = (dates) => {
        if (dates) {
            setDateRange(dates);
        } else {
            setDateRange(null);
        }
    };

    const disabledDate = (current) => {
        const currentYear = current.year();
        return !openBudgetYears.includes(currentYear);
    };

    const handleCloseModal = () => {
        form.resetFields();
        setEditingRecord(null);
        setDateRange(null);
        setDataSource([]);
        setIsModalOpen(false);
        setCostCenterRequester([]);
        setCostCenterAssignor([]);
    };

    const handleCostCenterRequesterChange = (value) => {
        setCostCenterRequester([{ value }]);
        form.setFieldsValue({ requester_account_accounting: undefined });

    };

    const handleCostCenterAssignorChange = (value) => {
        setCostCenterAssignor([{ value }]);
        form.setFieldsValue({ assignor_account_accounting: undefined });
    };

    const selectProps = {
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
        filterSort: (optionA: FlexFilter, optionB: FlexFilter) =>
            (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
    };

    const handleFieldChange = (fieldName: string, value: number) => {
        setFlexFieldValues((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const DynamicFields = ({ flexFieldsFilters, prefix }) => {
        return flexFieldsFilters.map((flexField) => {

            const fieldName = `${prefix}_${flexField.value}`;
            const matchingField = dataFlexFieldFreeze.find(item => item.flexFieldId === flexField.value);
            const defaultValue = matchingField ? matchingField.flexFieldValueId : undefined;
            const defaultLabel = defaultValue
                ? flexField.children.find(child => child.value === defaultValue)?.label
                : undefined;

            return (
                <Col span={4} key={fieldName}>
                    <Form.Item
                        name={fieldName}
                        label={flexField.label}
                        rules={!matchingField ? [
                            { required: true, message: i18n.t<string>("required_field") }
                        ] : []}
                    >
                        <Select
                            options={flexField.children.map((child) => ({
                                value: child.value,
                                label: child.label,
                            }))}
                            placeholder={defaultLabel || i18n.t("select")}
                            {...selectProps}
                            onChange={(value) => handleFieldChange(fieldName, value)}
                            disabled={!!matchingField || !dateRange}
                            value={defaultValue}
                        />
                    </Form.Item>
                </Col>
            );
        });
    };
    const handleNotification = () => {

        Notification({
            type: "success",
            message: isEditing ? i18n.t<string>("successfully_edited") : i18n.t<string>("successfully_saved"),
        })
        if (isEditing) {
            setIsEditing(false);
        }
        setIsLoading(false);
        if(year !== null) {
            getManagementTransfer();
        }

    }

    const handleSubmit = (values) => {

        const originFlexFields = Object.entries(flexFieldValues)
            .filter(([key]) => key.startsWith("requester"))
            .map(([_, value]) => value);

        const destinationFlexFields = Object.entries(flexFieldValues)
            .filter(([key]) => key.startsWith("assignor"))
            .map(([_, value]) => value);

        if (dataFlexFieldFreeze.length > 0) {

            const originFieldIds = dataFlexFieldFreeze.map(item => item.flexFieldValueId);
            const destinationFieldIds = dataFlexFieldFreeze.map(item => item.flexFieldValueId);

            originFlexFields.push(...originFieldIds);
            destinationFlexFields.push(...destinationFieldIds);
        }

        const valuesMonths: IValuesMonths[] = dataSource.flatMap((row) => {
            return Object.entries(row)
                .filter(([key]) => key !== "data" && key !== "total")
                .map(([month, value]) => ({
                    id: isEditing ? row.id : undefined,
                    managementTransferId: isEditing ? editingRecord.id : undefined,
                    period: moment(month, "MMM/YYYY").format("DD-MM-YYYY"),
                    value: Number(value) || 0,
                }));
        });

        const destinationArray = costCenterAssignor.map((costCenter, index) => ({
            id: isEditing ? editingRecord.destination[index].id : undefined,
            costCenterId: Number(costCenter.value),
            accountId: Number(values.assignor_account_accounting),
            flexFieldValueIds: destinationFlexFields,
        }));

        const formattedBody: ITransferRequest = {
            id: isEditing ? editingRecord.id : undefined,
            startDate: dateRange[0].startOf('month').format("DD-MM-YYYY"),
            endDate: dateRange[1].startOf('month').format("DD-MM-YYYY"),
            scenarioId: userInfo.selection.scenarioId,
            clientId: userInfo.clientId,
            organizationId: userInfo.selection.organizationId,
            businessUnitId: userInfo.selection.businessUnitId,
            lastUpdateUserId: userInfo.id,
            origin: {
                id: isEditing ? editingRecord.origin.id : undefined,
                costCenterId: Number(costCenterRequester[0]?.value),
                accountId: Number(values.requester_account_accounting),
                flexFieldValueIds: originFlexFields,
            },
            destination: destinationArray,
            values: valuesMonths,
        };

        const origin = formattedBody.origin;

        const isDuplicate = destinationArray.some(destination => {
            return (
                destination.costCenterId === origin.costCenterId &&
                destination.accountId === origin.accountId &&
                JSON.stringify(destination.flexFieldValueIds) === JSON.stringify(origin.flexFieldValueIds)
            );
        });
    
        if (isDuplicate) {
          Notification({
            type: "warning",
            message: i18n.t<string>("management_transfer.dont_same_combination"),
          })
            return;
        }

        ServiceCaller.doRequest(
            {
                type: isEditing ? RequestType.PUT : RequestType.POST,
                url: isEditing ? "/expenses/managementTransfer/update" : "/expenses/managementTransfer/save",
                params: formattedBody,
            },
            () => {
                setIsModalOpen(false);
                handleNotification();
            },
            handleErrorRequest
        );

        if (isEditing) {
            setEditingRecord(null);
        }

        form.resetFields();
        setDataSource([]);
        setIsModalOpen(false);
        setCostCenterRequester([]);
        setCostCenterAssignor([]);
        setSelectedRowKeys([]);
    };

    const sortedFlexFieldsFilters = flexFieldsFilters.sort((a, b) => a.ordenation - b.ordenation);
    const sortedFlexFieldsFiltersAssignor = flexFieldsFiltersAssignor.sort((a, b) => a.ordenation - b.ordenation);

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCloseModal}
            title={i18n.t<string>("management_transfer.new_transfer")}
            okText={i18n.t<string>("save")}
            okButtonProps={{
                form: "management-transfer-form",
                htmlType: "submit"
            }}
            cancelText={i18n.t<string>("cancel")}
            centered
            width={"100%"}
            maskClosable={false}
            keyboard={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="management-transfer-form"
                className="management-transfer-form"
                onFinish={handleSubmit}
            >
                <Row gutter={16} >
                    <Col span={24}>
                        <Form.Item
                            name="date_range"
                            label={i18n.t<string>("management_transfer.transfer_period")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") }
                            ]}
                        >
                            <RangePicker
                                className="date-picker"
                                picker="month"
                                onChange={handleDateChange}
                                format="MM/YYYY"
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <p>{i18n.t<string>("management_transfer.requester")}</p>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="requester_cost_center"
                            label={i18n.t<string>("cost_center")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                className="cost-center-select"
                                options={costCenterOptions}
                                onChange={handleCostCenterRequesterChange}
                                placeholder={i18n.t<string>("select")}
                                {...selectProps}
                                disabled={!dateRange}
                            />
                        </Form.Item>
                    </Col>
                    <DynamicFields
                        flexFieldsFilters={sortedFlexFieldsFilters}
                        prefix="requester"
                        {...selectProps}
                    />
                    <Col span={4}>
                        <Form.Item
                            name="requester_account_accounting"
                            label={i18n.t<string>("accounting_account")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                className="account-accounting-select"
                                options={accountAccountingOptionsRequester}
                                placeholder={i18n.t<string>("select")}
                                disabled={costCenterRequester[0]?.value ? false : true}
                                loading={isFetchingAccountOptionsRequester}
                                {...selectProps}
                            />
                        </Form.Item>
                    </Col>

                    <div className="section-separation" />

                    <Col span={24}>
                        <p>{i18n.t<string>("management_transfer.assignor")}</p>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="assignor_cost_center"
                            label={i18n.t<string>("cost_center")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                className="cost-center-select"
                                options={costCenterAssignorOptions}
                                onChange={handleCostCenterAssignorChange}
                                placeholder={i18n.t<string>("select")}
                                {...selectProps}
                                disabled={!dateRange}
                            />
                        </Form.Item>
                    </Col>
                    <DynamicFields
                        flexFieldsFilters={sortedFlexFieldsFiltersAssignor}
                        prefix="assignor"
                        {...selectProps}
                    />
                    <Col span={4}>
                        <Form.Item
                            name="assignor_account_accounting"
                            label={i18n.t<string>("accounting_account")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                className="account-accounting-select"
                                options={accountAccountingOptionsAssignor}
                                placeholder={i18n.t<string>("select")}
                                disabled={costCenterAssignor[0]?.value ? false : true}
                                {...selectProps}
                                loading={isFetchingAccountOptionsAssignor}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {(dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) || dataSource.length > 0 ? (
                <TableTransfer
                    dateRange={dateRange}
                    setDataSource={setDataSource}
                    dataSource={dataSource}
                />
            ) : null}

        </Modal>
    );
}
