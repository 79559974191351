import { TopButtons } from "components/topButtons/TopButtons";
import { IApportionmentListSection } from "../../IApportionment";
import { Filters } from "./components/Filters";
import { ApportionmentList } from "./components/ApportionmentList";

export function ApportionmentListSection({
    apportionmentList,
    onClickNewApportionment,
    businessUnitOptions,
    costCenterOptions,
    flexFieldsOriginOptions,
    isFetchingOriginAccountOptions,
    originAccountOptions,
    setFlexFieldsOriginOptions,
    setIsFetchingOriginAccountOptions,
    setOriginAccountOptions,
    onOpenApportionmentInfo,
    isFetchingApportionments,
    fetchApportionments,
    openCalendarPeriod,
    selectedRowKeys,
    setSelectedRowKeys,
    onDelete,
    setIsButtonLoading,
    isButtonLoading,
    year,
    setYear,
    additionalOptions,
    allFlexFields,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    totalItems,
}: IApportionmentListSection) {
    return (
        <div className="gs-flex align-center flex-col w-full">
            <TopButtons
                mainButtonTitle="Nova Alocação"
                handleNew={() => onClickNewApportionment()}
                handleDelete={onDelete}
                isDeletable={selectedRowKeys.length > 0}
                hasCaptcha={true}
            />
            <Filters
                openCalendarPeriod={openCalendarPeriod}
                businessUnitOptions={businessUnitOptions}
                costCenterOptions={costCenterOptions}
                flexFieldsOriginOptions={flexFieldsOriginOptions}
                isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                originAccountOptions={originAccountOptions}
                setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                setIsFetchingOriginAccountOptions={setIsFetchingOriginAccountOptions}
                setOriginAccountOptions={setOriginAccountOptions}
                fetchApportionments={fetchApportionments}
                setIsButtonLoading={setIsButtonLoading}
                isButtonLoading={isButtonLoading}
                year={year}
                setYear={setYear}
                currentPage={currentPage}
                currentPageSize={currentPageSize}
            />

            <ApportionmentList
                apportionmentList={apportionmentList}
                onOpenApportionmentInfo={onOpenApportionmentInfo}
                isFetchingApportionments={isFetchingApportionments}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                costCenterOptions={costCenterOptions}
                flexFieldsOriginOptions={flexFieldsOriginOptions}
                flexFieldsDestinationOptions={flexFieldsOriginOptions}
                setIsButtonLoading={setIsButtonLoading}
                isButtonLoading={isButtonLoading}
                additionalOptions={additionalOptions}
                allFlexFields={allFlexFields}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentPageSize={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
                totalItems={totalItems}
            />
        </div>
    );
}
