import { Filter } from "components/filter/Filter";
import { useBudgetDates } from "hooks/useBudgetDates";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { ModuleType } from "util/types/types";
import { ManagementTransferContext } from "../context/ManagementTransferContext";

export function FiltersManagementTransfer() {
    const { data: budgetDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);
    const [openCalendarPeriod, setOpenPeriodCalendar] = useState([]);
    const { year, setYear, } = useContext(ManagementTransferContext);

    useEffect(() => {
        if (!budgetDates || !budgetDates.period || budgetDates.period.length === 0) {
            setOpenPeriodCalendar([]); 
            return;
        }
    
        const periods = budgetDates.period.map((period) => [
            moment(period.year, "YYYY").startOf("year"),
            moment(period.year, "YYYY").endOf("year"),
        ]);
    
        setOpenPeriodCalendar(periods);
    }, [budgetDates]);

    const handleDateChange = (value) => {
        setYear(value);
    };

    return (
        <div
            className="filters-management-transfer"
            style={{ marginBottom: "10px" }}
        >
            <Filter
                id="period-filter"
                value={year}
                setValue={handleDateChange}
                title={i18n.t("period")}
                type="datepicker"

                data={[]}
                datepicker={{
                    format: "MM/YYYY",
                    picker: "month",
                    rangePicker: true,
                    allowClear: false,
                    defaultToday: true,
                    period: openCalendarPeriod
                }}
                buttonStyles={{ cursor: 'default' }}
            />
        </div>
    );
}