import { Icon } from "@iconify/react";
import { Notification } from "components/notification/Notification";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useNewReportContext } from "../context/ReportRegistrationContext";
import { ReportType } from "../IReportRegistration";
import "../ReportRegistration.sass";
import NewReportModal from "./NewReportModal";

export default function TopButtonsReportRegistrationContent({ isOnNewReport, setIsOnNewReport }) {
    const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(false);

    const {
        getTableData,
        reportType,
        resetReportType,
        isSavingReport,
        report,
        selectedRowKeys,
        setIsFetching,
        isEditingReport,
        setIsEditingReport,
        setIdReportForEdit,
    } = useNewReportContext();

    useEffect(() => {
        if (!ReportType[reportType] || isSavingReport || isEditingReport || isOnNewReport) return;

        if (report?.id) handleEdit(report.id);
    }, [reportType, isSavingReport]);

    useEffect(() => {
        if (isOnNewReport) return;
        getTableData();
    }, [isOnNewReport]);

    function handleNew() {
        resetReportType();
        setIsNewReportModalOpen(true);
    }

    function handleEdit(reportId) {
        setIsEditingReport(true);
        setIdReportForEdit(reportId);
        setIsOnNewReport(true);
    }

    function onDuplicate() {
        ServiceCaller.doRequest(
            {
                type: RequestType.PATCH,
                url: `/budget-report/report/duplicate/${selectedRowKeys[0]}`,
                params: {},
            },
            getTableData
        );
    }

    function handleDelete() {
        setIsFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/budget-report/report/delete?ids=${selectedRowKeys}`,
                params: {},
            },
            onDelete
        );
    }

    function onDelete() {
        Notification({
            message: i18n.t<string>("report_excluded_successfully"),
            type: "success",
        });
        getTableData();
    }

    const copyButton: CustomButtons = {
        onClick: onDuplicate,
        icon: <Icon icon="ps:copy" />,
        className: "icon-copy",
        disabled: !selectedRowKeys.length,
    };

    return (
        <div className="registration-sales-page">
            <NewReportModal
                isNewReportModalOpen={isNewReportModalOpen}
                setIsNewReportModalOpen={setIsNewReportModalOpen}
            />
            {!isEditingReport && (
                <div className="header-content">
                    <TopButtons
                        mainButtonTitle={i18n.t<string>("new_report")}
                        handleNew={handleNew}
                        handleEdit={() => handleEdit(selectedRowKeys[0])}
                        handleDelete={handleDelete}
                        customButtons={[copyButton]}
                        isEditable={selectedRowKeys.length === 1}
                        isDeletable={!!selectedRowKeys.length}
                    />
                </div>
            )}
        </div>
    );
}
