import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { FilterType, IDistributionRuleFilter, IDistributionRuleResultFilter, IFilterFormValues, IOptionRuleFilter, IDistributionRuleContext, IDistributionRuleData, FilterResultType, IDistributionRuleGrouper, ISearchType, IDistributionRuleResponse } from "../IDistributionRule";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { ErrorRequest, FlexFieldFilterResponse } from "util/types/types";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType, TableRowSelection } from "antd/lib/table/interface";
import i18n from "util/base/i18n";
import { IValuePeriodTableData } from "../../revenue/recurringContract/IRecurringContract";
import { TablePaginationConfig } from "antd/es/table";
import { useUserContext } from "context/UserContext";

const DistributionRuleContext = createContext<IDistributionRuleContext>(null);
export const DistributionRuleProvider = ({ children }: { children: ReactElement }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const [tableData, setTableData] = useState<IDistributionRuleData[]>([]);
	const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [ruleForEdit, setRuleForEdit] = useState<IDistributionRuleData | null>();
	const [ruleFilterOptions, setRuleFilterOptions] = useState<IOptionRuleFilter[]>([]);
	const [selectedTab, setSelectedTab] = useState<"filter" | "result">("filter");
	const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
	const [filtersForm] = useForm<IFilterFormValues>();
	const [ruleConditionFilter, setRuleConditionFilter] = useState<IDistributionRuleFilter[]>([]);
	const [ruleConditionResult, setRuleConditionResult] = useState<IDistributionRuleResultFilter[]>([]);
	const [hasEdited, setHasEdited] = useState<boolean>(false);
	const [distributionGroupers, setDistributionGroupers] = useState<Record<IDistributionRuleGrouper, string>>();
	const [selectedRows, setSelectedRows] = useState<IDistributionRuleData[]>([]);
	const [distributionRuleTypeSelected, setDistributionRuleTypeSelected] = useState<IDistributionRuleGrouper>(IDistributionRuleGrouper.SOFTWARE_HOUSE);
	const [searchType, setSearchType] = useState<ISearchType>(ISearchType.DESCRIPTION);
	const [searchValue, setSearchValue] = useState<string>("");
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [currentPageSize, setCurrentPageSize] = useState<number>(20);
	const [totalItems, setTotalItems] = useState(0);
	const { userInfo: { selection: { scenarioId } } } = useUserContext();
	const defaultDateValuePeriod = {
		key: "months",
		months: i18n.t("value"),
	}
	const [monthValuesData, setMonthValuesData] = useState<IValuePeriodTableData[]>([defaultDateValuePeriod]);
	const pagination: TablePaginationConfig = {
		current: currentPage,
		pageSize: currentPageSize,
		onChange(page: number, pageSize: number) {
			setCurrentPage(page);
			setCurrentPageSize(pageSize);
			getTableData(page, pageSize);
		},
		total: totalItems,
		pageSizeOptions: [10, 20, 50, 100],
		hideOnSinglePage: true,
		showSizeChanger: true,
		showTotal: (total) => `${total} itens`,
	}
	const rowSelection: TableRowSelection<IDistributionRuleData> = {
		selectedRowKeys,
		onChange: (keys: number[], rows: IDistributionRuleData[]) => {
			setSelectedRowKeys(keys);
			setSelectedRows(rows);
		},
		checkStrictly: false
	};

	useEffect(() => {
		if (!isModalOpen) {
			setSelectedRowKeys([]);
			return;
		}
		getAsyncData();
	}, [isModalOpen])

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/software-house/distribution-rule/get-distribution-groupers?scenarioId={scenario}"
		}, onLoadDistributionGroupers, handleErrorRequest)
	}, [])

	const getTableData = async (
		page: number = currentPage,
		pageSize: number = currentPageSize,
		currentSearch: string = searchValue,
		currentSearchType: ISearchType = searchType,
		currentRuleType: string = distributionRuleTypeSelected,
	) => {
		setIsTableDataLoading(true);
		setSelectedRowKeys([]);
		const params = {
			scenarioId,
			grouper: currentRuleType,
			search: currentSearch,
			searchType: currentSearchType,
		}
		const response = await ServiceCaller.doAsyncRequest<IDistributionRuleResponse>({
			type: RequestType.POST,
			url: `/software-house/distribution-rule/get-by-scenario?page=${page - 1}&size=${pageSize}&sort=id,desc`,
			params
		})

		if (response) {
			const { data } = response;
			updatePaginationValues(data);
			setTableData(data.content);
			data.content.length > 0 
				? localStorage.setItem("nextOrder", (data.content[data.content.length - 1].order + 1).toString()) 
				: localStorage.setItem("nextOrder", "1");
		} else {
			handleErrorRequest({} as ErrorRequest);
		}
		setIsTableDataLoading(false);
	}

	const updatePaginationValues = ({ totalElements, pageable: { pageSize, pageNumber } }: IDistributionRuleResponse) => {
		setCurrentPageSize(pageSize);
		setCurrentPage(pageNumber + 1);
		setTotalItems(totalElements);
	}

	const onLoadDistributionGroupers = (groupers: Record<IDistributionRuleGrouper, string>) => {
		const groupersI18n = Object.fromEntries(
			Object.entries(groupers).map(([key, value]) => [
				key,
				i18n.t(`distribution_rules.grouper.${value}`)
			])
		) as Record<IDistributionRuleGrouper, string>;

		setDistributionGroupers(groupersI18n);
	}

	const getAsyncData = async () => {
		const flexFieldsResponse = await ServiceCaller.doAsyncRequest<FlexFieldFilterResponse[]>({
			type: RequestType.GET,
			url: `/budget-base/flex-field/find-all-by-module?module=EXPENSES`,
		})

		Promise.resolve(flexFieldsResponse)
			.then(({ data }) => {
				onLoadRuleFilterOptions(data)
			})
	}

	const onLoadRuleFilterOptions = (flexFields: FlexFieldFilterResponse[]) => {
		const flexData: IOptionRuleFilter[] = flexFields.map(({ externalCode, description, id }) => {
			const label: string = `${externalCode} - ${description}`;
			return ({
				label,
				value: `${id}-${FilterType.FLEX_FIELD}`,
				filter: FilterType.FLEX_FIELD,
				result: FilterResultType.FLEX_FIELD
			})
		})
		setRuleFilterOptions(flexData);
		setIsOptionsLoading(false);
	}

	return (
		<DistributionRuleContext.Provider
			value={{
				setTableData,
				tableData,
				isTableDataLoading,
				setIsTableDataLoading,
				isModalOpen,
				setIsModalOpen,
				isSaving,
				setIsSaving,
				ruleForEdit,
				setRuleForEdit,
				ruleFilterOptions,
				selectedTab,
				setRuleFilterOptions,
				setSelectedTab,
				isOptionsLoading,
				setIsOptionsLoading,
				filtersForm,
				ruleConditionFilter,
				ruleConditionResult,
				setRuleConditionFilter,
				setRuleConditionResult,
				getTableData,
				rowSelection,
				selectedRowKeys,
				hasEdited,
				setHasEdited,
				distributionGroupers,
				selectedRows,
				setSelectedRows,
				monthValuesData,
				setMonthValuesData,
				distributionRuleTypeSelected,
				searchType,
				searchValue,
				setDistributionRuleTypeSelected,
				setSearchType,
				setSearchValue,
				currentPage,
				currentPageSize,
				setCurrentPage,
				setCurrentPageSize,
				setTotalItems,
				totalItems,
				pagination
			}}
		>
			{children}
		</DistributionRuleContext.Provider>
	)
}

export const useDistributionRuleContext = (): IDistributionRuleContext => {
	return useContext(DistributionRuleContext);
}