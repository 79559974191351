export interface DefaultResponse {
    status: 'success' | 'info' | 'warning' | 'error';
    messageCode?: string;
    content?: any;
}

export type ICurrency = {

	id: number

	name: string

	isoCode: string

	image: string

}

export interface ImportResponse {
    success: boolean;
    message: 'import.success' | 'import.error' | 'import.running';
}

export type FunctionalityPermissions = {
    new?: boolean;
    edit?: boolean;
    remove?: boolean;
    read?: boolean;
	import?: boolean;
	export?: boolean;
    [key: string]: boolean;
}

export interface ImportResponse {
    success: boolean;
    status?: boolean;
    message: 'import.success' | 'import.error' | 'import.running';
}

export type Options = {
    label: string;
    value: number;
    resourceType?: string;
    disabled?: boolean;
    canEdit?: boolean;
    checked?: boolean;
    linkedParentFlexFieldId?: number[];
    lastSelected?: boolean;
}

export interface FlexFilter {
    fieldCode?: number;
    ordenation?: number;
    selectedOption?: Options[];
    isFetching?: boolean;
    value: number;
    label: string;
    linkedFilters?: string[];
	children?: FlexFilterOptions[];
    allValues?: FlexFieldValues[];
}

export interface FlexFilterOptions {
    flexFieldId?: number;
    linkedFilterIds?: number[];
    linkedAccounts?: number[];
    linkedCostCenters?: number[];
    linkedBusinessUnits?: number[];
    value: number;
    label: string;
    checked?: boolean;
}

export type FlexFieldValuesResponse = {
    id: number;
    flexFieldId: number;
    flexFieldDescription: string;
    description: string;
    externalCode: string;
    flexFieldFilters: number[];
    costCenterFilters: number[];
    businessUnitFilters: number[];
    accountingAccountFilters: number[];	
	organizationFilters: number[];
}

export type FlexFieldFilterResponse = {
    id: number;
    ordenation: number;
    description: string;
    externalCode: string;
    flexFieldFilters: number[];
    filters: string[]
    fieldCode: string;
    isRequired?: boolean;
}

export type ErrorRequest = {
    exception: string
    message: string
    path: string
    timestamp: string
    status: number;
}

export type FlexFieldValueListBean = {
    id: number,
    flexFieldId: number,
    flexFieldDescription: string,
    fieldCode: string;
    externalCode: string;
    description: string;
    accountingAccountFilters: number[];
    businessUnitFilters: number[];
    costCenterFilters: number[];
	organizationFilters: number[];
    flexFieldFilters: number[];
    flexFieldValueListBean: {
        list: FlexFieldValueListBean[]
    }[]
}

interface Pageable {
	sort: {
	  unsorted: boolean;
	  sorted: boolean;
	  empty: boolean;
	};
	offset: number;
	pageNumber: number;
	pageSize: number;
	paged: boolean;
	unpaged: boolean;
  }
  
  interface Sort {
	unsorted: boolean;
	sorted: boolean;
	empty: boolean;
  }
  
  export interface PaginationResponse {
	pageable: Pageable;
	last: boolean;
	totalPages: number;
	totalElements: number;
	size: number;
	number: number;
	sort: Sort;
	numberOfElements: number;
	first: boolean;
	empty: boolean;
  }
  
export enum ModuleType {
    EXPENSERESOURCES = "expensesResources",
	REVENUE = "revenue",
	PLANNING = "planning"
}

export type FlexFieldValues = {
    fieldValueId: number,
    externalCode: string,
    description: string,
    fitler: any,
    theLastSelected: boolean
}

export interface GenericOptionBean {
    value: string;
    label: string;
}

export interface FlexFieldSimpleBean {
    id?: number;
    description?: string;
    label?: string;
    flexFieldValueIds?: number[];
    value: string | number;
    flexFieldValues: GenericOptionBean[];
}

export interface ResponseStatus {
    success: boolean;
    status?: boolean;
    message: 'success' | 'error' | 'running';
}

export interface GenericAlertMessage {
    status: 'success' | 'error' | 'warning'| 'info';
    message: string;
}
