import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from 'hooks/useCachedQuery';
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";

export function useSelectedCostCenterAssignorOptions(queryProps = {} as UseQueryOptions<Options[]>) {
    const queryInfo = useCachedQuery<Options[]>(
        ['cost-center-assignor-selected-options'],
        {
            type: RequestType.GET,
            url: `/monolith/cost-center?client={client}&locale={locale}&organization={organization}`,

        },
        {},
        (data: { id: number; name: string; lastSelected: boolean }[]) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected }));
        }
    )
    
    return queryInfo;
}