import { Layout, Modal, Row } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import LoadingChanges from "module/budget/pages/revenue/mainFlow/grid/header/LoadingChanges";
import i18n from "util/base/i18n";
import { IStepTwoProps } from "../../IReportRegistration";
import { useNewReportContext } from "../../context/ReportRegistrationContext";
import { useFormulaContext } from "../../formula/context/FormulaContext";
import TableSideBar from "./TableSideBar";
import FormulaWrapper from "./components/FormulaWrapper";

export default function StepTwo({ fetchReports }: IStepTwoProps) {
    const { report } = useNewReportContext();

    const { pendingLevelSelected, onAcceptFormulaChanges, handleCancelPendingLevel, isLoading, hasSavedValue } = useFormulaContext();

    const importProps: IImportModal[] = [
        {
            importUrl: "/budget-report/report/import-formula",
            templateUrl: "/budget-report/report/template-formula?locale={locale}",
            type: "excel",
            urlParams: `&report=${report?.id}`,
        },
    ];

    return (
        <div className="step-one step-two">
            <div style={{ textAlign: "right" }}>
                <ImportExportMenu
                    buttonType="3dots"
                    importProps={importProps}
                    handleSuccessImport={() => fetchReports()}
                />
            </div>

            <Layout>
                <Sider theme="light" width={300}>
                    <TableSideBar />
                </Sider>
                <Layout style={{ display: "flex", width: 100 }}>
                    <Content>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <h3>{i18n.t<string>("link_reference_field")}</h3>
                            <div>
                                <LoadingChanges isLoading={isLoading} hasSavedValue={hasSavedValue} />
                            </div>
                        </Row>
                        <Row gutter={20}>
                            <FormulaWrapper/>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
            <Modal
                title={i18n.t<string>("attention")}
                okText={i18n.t<string>("add")}
                visible={pendingLevelSelected != null}
                onCancel={handleCancelPendingLevel}
                onOk={onAcceptFormulaChanges}
            >
                <p>{i18n.t<string>("revenue.unsaved_changes")}</p>
            </Modal>
        </div>
    );
}
