import { Switch, Table, Input } from "antd";
import "../ManagementTransfer.sass";
import { useState } from "react";
import i18n from "util/base/i18n";
import { ICostCenterLinked } from "../IManagementTransfer";

const { Search } = Input;

export function CenterCostLinkedTabContent(
    { isLinked, setIsLinked, dataCostCenterLiked }: { isLinked: boolean, setIsLinked: (isLinked: boolean) => void, dataCostCenterLiked: ICostCenterLinked[] }
) {
    const [searchTerm, setSearchTerm] = useState("");

    const onChange = (checked) => {
        setIsLinked(checked);
    };

    const filteredData = dataCostCenterLiked?.filter((costCenter) => {
        const matchesSelection = costCenter.hasParameterization === isLinked;
        const matchesSearch = costCenter.name.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesSelection && matchesSearch;
    }) || [];

    const columns = [
        {
            title: i18n.t("cost_center"),
            dataIndex: "name",
            key: "name",
        }
    ];

    const onChangeSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <Switch
                        defaultChecked
                        onChange={onChange}
                        className="center-cost-ledger-switch"
                    />
                    <span style={{ marginLeft: 10 }}>
                        {isLinked ? i18n.t("management_transfer.linked") : i18n.t("management_transfer.not_linked")}
                    </span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <Search
                        placeholder={i18n.t("search")}
                        onChange={onChangeSearch}
                        style={{ width: 200 }}
                    />
                </div>
            </div>
            <div>
                <Table
                    dataSource={filteredData}
                    columns={columns}
                    rowKey="value"
                    style={{ marginTop: 16 }}
                    bordered
                    pagination={false}
                    scroll={{ y: 250 }}
                />
            </div>
        </>
    );
}