import { Form, FormInstance, Popover, Table, Tag, Tooltip } from "antd";
import { EditableCellProps } from "../../detailLayout/IDetailLayout";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useManagementTransferContext } from "../context/ManagementTransferContext";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import moment from "moment";
import { cloneDeep } from "lodash";
import { formatNumber } from "util/formatNumber";
import Input from "antd/lib/input/Input";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { ModuleType } from "util/types/types";
import "./TableTransfer.sass";

export function TableTransfer({
    dateRange,
    dataSource,
    setDataSource
}) {
    interface CustomColumnType {
        editable?: boolean;
        title: string;
        dataIndex: string;
        key: string;
        fixed?: "left" | "right";
        className?: string;
        render?: (value: any, record: any) => JSX.Element | string | number | null;
    }

    const [columns, setColumns] = useState<CustomColumnType[]>([]);
    const { budgetDates, isEditing, editingRecord } = useManagementTransferContext();
    const [openPeriod, setOpenPeriod] = useState<any[]>([]);

    useEffect(() => {
        if (budgetDates.period?.length === 0) return;

        const [startDate, endDate] = dateRange.map(date => moment(date));
        let tempOpenPeriod: any[] = [];
        const yearsInRange = new Set();

        budgetDates.period.forEach((period) => {
            const openingStartDate = moment(period.expensesResourcesOpeningStartDate);
            const openingEndDate = moment(period.expensesResourcesOpeningEndDate);

            if (openingStartDate.year() >= startDate.year() && openingEndDate.year() <= endDate.year()) {
                if (
                    moment().isBefore(openingStartDate) ||
                    moment().isAfter(openingEndDate)
                ) {
                    const year = period.year;
                    if (!yearsInRange.has(year)) {
                        tempOpenPeriod.push({
                            year: year,
                            start: openingStartDate.format("DD/MM/YYYY"),
                            end: openingEndDate.format("DD/MM/YYYY"),
                        });
                        yearsInRange.add(year);
                    }
                }
            }
        });
        setOpenPeriod(tempOpenPeriod);
    }, [budgetDates, dateRange]);

    useEffect(() => {
        if (!dateRange || dateRange.length !== 2 || !budgetDates) return;
    
        const [startDate, endDate] = dateRange.map(date => moment(date));
        const months: string[] = [];
        let current = startDate.clone();
    
        const realizedMonths = new Set<string>();
        const frozenMonths = new Set<string>();
    
        budgetDates.period.forEach(period => {
            const realizedPeriod = period.expensesResourcesRealizedPeriod;
            const frozenPeriod = period.expensesResourcesFrozenPeriod;
    
            if (realizedPeriod) {
                const realizedDate = moment(realizedPeriod);
                if (realizedDate.isBetween(startDate, endDate, "month", "[]")) {
                    let cutoff = realizedDate.clone();
                    while (cutoff.isSameOrAfter(startDate, "month")) {
                        realizedMonths.add(cutoff.format("MMM/YYYY"));
                        cutoff.subtract(1, "month");
                    }
                }
            }
            if (frozenPeriod) {
                const frozenDate = moment(frozenPeriod);
                if (frozenDate.isBetween(startDate, endDate, "month", "[]")) {
                    let cutoff = frozenDate.clone();
                    while (cutoff.isSameOrAfter(startDate, "month")) {
                        frozenMonths.add(cutoff.format("MMM/YYYY"));
                        cutoff.subtract(1, "month");
                    }
                }
            }
        });
    
        while (current.isSameOrBefore(endDate, "month")) {
            months.push(current.format("MMM/YYYY"));
            current.add(1, "month");
        }
    
        const dynamicColumns: CustomColumnType[] = [
            {
                title: i18n.t<string>("months"),
                dataIndex: "data",
                key: "data",
                fixed: "left",
            },
            ...months.map(month => {
                const currentDate = moment(month, "MMM/YYYY");
                const currentBudgetPeriod = budgetDates.period.find(
                                (budDate) => budDate.year === moment(currentDate, "YYYY-MM").year()
                            );
                let { isEditable, className } = verifyBudgetPeriod(
                    currentBudgetPeriod,
                    currentDate,
                    budgetDates.localDate,
                    ModuleType.EXPENSERESOURCES
                );
                return {
                    title: month.charAt(0).toUpperCase() + month.slice(1),
                    dataIndex: month,
                    key: month,
                    editable: isEditable,
                    className: className,
                    render: (value: number) => formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                };
            }),
            {
                title: i18n.t<string>("total"),
                dataIndex: "total",
                key: "total",
                fixed: "right",
                className: "total-cell",
                render: (value: number) =>
                    formatNumber(-Math.abs(value), { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            },
        ];

        const initialData = [
            {
                data: i18n.t<string>("value"),
                ...months.reduce((acc, month) => {
                    if (isEditing) {
                        const formattedMonth = moment(month, "MMM/YYYY").format("DD-MM-YYYY");
                        const monthData = editingRecord.values.find(
                            record => moment(record.period, "DD-MM-YYYY").format("DD-MM-YYYY") === formattedMonth
                        );

                        const monthValue = monthData ? monthData.value : 0;

                        acc[month] = monthValue;
                    } else {
                        acc[month] = 0;
                    }
                    return acc;
                }, {}),
                total: isEditing ? editingRecord.totalValue || 0 : 0,
            },
        ];

        setColumns(dynamicColumns);
        setDataSource(initialData);
    }, [dateRange, budgetDates]);

    const EditableContext = createContext<FormInstance<any> | null>(null);
    const EditableRow: React.FC<any> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    function doReplicateValues(record, cellIndex) {
        const cellPeriod = moment(cellIndex, "MMM/YYYY");
        const startPeriod = moment(dateRange[0]);
        const endPeriod = moment(dateRange[1]);

        const newDataSource = cloneDeep(dataSource);

        const targetRow = newDataSource.find(item => item.key === record.key);
        if (targetRow) {
            while (startPeriod.isSameOrBefore(endPeriod, "month")) {
                const columnKey = startPeriod.format("MMM/YYYY");
                if (startPeriod.isAfter(cellPeriod)) {
                    targetRow[columnKey] = record[cellIndex];
                }
                startPeriod.add(1, "month");
            }

            const total = Object.keys(targetRow)
                .filter(key => key !== "data" && key !== "total")
                .reduce((sum, key) => sum + (parseFloat(targetRow[key]) || 0), 0);

            targetRow.total = total;
        }
        setDataSource(newDataSource);
    }

    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<any>(null);
        const [isHovered, setIsHovered] = useState(false);
        const form = useContext(EditableContext)!;
        let isDisabledEditCell: boolean = false;

        const handleSave = (record) => {
            const formattedRecord = {};
            for (const [key, value] of Object.entries(record)) {
                if (typeof value === "string" && (value.includes(",") || value.includes("."))) {
                } else {
                    formattedRecord[key] = value;
                }
            }
            const updatedDataSource = dataSource.map(item => {
                if (item.key === record.key) {
                    const total = Object.keys(formattedRecord)
                        .filter(key => key !== "data" && key !== "total")
                        .reduce((sum, key) => sum + (parseFloat(formattedRecord[key]) || 0), 0);
                    return { ...item, ...formattedRecord, total };
                }
                return item;
            });
            setDataSource(updatedDataSource);
        };

        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            if (!editable) return;
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            if (!editable) return;
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (error) {
                console.log(error);
            }
        }

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    className="editable-cell"
                    style={{ marginBottom: '0 !important' }}
                    name={dataIndex}>
                    <Input
                        className="input-text-cell"
                        type="numeric"
                        ref={inputRef}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                const value = form.getFieldValue(dataIndex);
                                const numericValue = value ? parseFloat(value.replace(",", ".")) : 0;
                                form.setFieldsValue({
                                    [dataIndex]: numericValue > 0 ? -Math.abs(numericValue) : numericValue,
                                });
                                save();
                            }
                        }}
                        onBlur={() => {
                            const value = form.getFieldValue(dataIndex);
                            const numericValue = value ? parseFloat(value.replace(",", ".")) : 0;
                            form.setFieldsValue({
                                [dataIndex]: numericValue > 0 ? -Math.abs(numericValue) : numericValue,
                            });
                            save();
                        }}
                    />
                </Form.Item>
            ) : (
                <div
                    className="gs-table-input-editable-cell"
                    style={{ display: "flex" }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={toggleEdit}
                >
                    <div className="editable-cell-value-wrap">
                        {children}
                    </div>
                    {!isDisabledEditCell && isHovered &&
                        <Tooltip placement="topLeft" title={i18n.t<string>("replicate_to_next_months")}>

                            <Icon
                                style={{ boxShadow: 'none', color: '#A6A7A7', fontSize: '6px !important', marginLeft: '5px' }}
                                icon={"material-symbols:content-copy"}
                                onClick={() => doReplicateValues(record, dataIndex)}
                            />

                        </Tooltip>
                    }
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                className: col.className,
            }),
        };
    });

    const popoverContent = (
        <div>
            {openPeriod.map((period, index) => (
                <div key={index}>
                    <p>{`${i18n.t("year")}: ${period.year}`}</p>
                    <p>{`${i18n.t("typing_period")}: ${period.start} - ${period.end}`}</p>
                </div>
            ))}
        </div>
    );

    return (

        <>
            {openPeriod.length > 0 && (
                <Popover content={popoverContent} placement="bottom">
                    <Tag color="red" style={{ marginBottom: "10px" }}>
                        {`${i18n.t("fixed_expense_texts.outside_typing_period")}`}
                    </Tag>
                </Popover>
            )}
            <Table
                className="gs-table"
                id="table-transfer-management"
                pagination={false}
                dataSource={dataSource}
                components={components}
                columns={mergedColumns}
                bordered
                scroll={{ x: "max-content" }}
            />
        </>
    )
}