import { Icon } from "@iconify/react";
import { Button, Col, Input, Modal, Row, Form } from "antd";
import i18n from "util/base/i18n";
import { INewReportModalProps, ReportType } from "../IReportRegistration";
import { useEffect, useState } from "react";
import { useNewReportContext } from "../context/ReportRegistrationContext";


export default function NewReportModal({
    isNewReportModalOpen,
    setIsNewReportModalOpen,
}: INewReportModalProps) {
    const [reportSelected, setReportSelected] = useState<ReportType>();
    const { setReportType, onSaveReport } = useNewReportContext();
    const [form] = Form.useForm();

    useEffect(() => {
        if (!isNewReportModalOpen) {
            setReportSelected(null);
        }
    }, [isNewReportModalOpen]);

    const updateReportType = (reportType: ReportType) => {
        setReportSelected(reportType);
    }
    const classButtonSelected = (type: ReportType): string => {
        return type === reportSelected ? "type-selected" : "";
    }

    const handleClose = () => {
        setIsNewReportModalOpen(false);
        setReportType(null);
    }

    const onOk = async ({ description }) => {
        try {
            await onSaveReport(reportSelected, description);
            setReportType(reportSelected);
            setIsNewReportModalOpen(false);
            form.resetFields();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            className="new-report-modal"
            open={isNewReportModalOpen}
            cancelButtonProps={{
                type: "link"
            }}
            okText={i18n.t<string>("continue")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                className: "gs-main-button",
                disabled: ReportType[reportSelected] ? false : true,
                htmlType: "submit",
                form: "new-report"
            }}
            title={i18n.t<string>("new_report")}
            onCancel={handleClose}
            destroyOnClose
        >
            <Form
                form={form}
                name="new-report"
                onFinish={onOk}
                initialValues={{ originLevel: 0 }}
                layout="vertical"
                requiredMark={false}
            >
                <Row >
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label={i18n.t("description")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Input
                                id="description"
                                placeholder={i18n.t("type_here")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="new-report-header">
                    <Col>
                        <p>
                            {i18n.t<string>("new_sales_report.select_the_type_of_report_you_want_to_register")}
                        </p>
                    </Col>
                </Row>
                <Row className="new-report-content">
                    <Col span={12}>
                        <Button
                            type="text"
                            className={classButtonSelected(ReportType.PLANNING)}
                            onClick={() => updateReportType(ReportType.PLANNING)}
                        >
                            <Row>
                                <Icon icon="fluent:arrow-growth-24-filled" />
                            </Row>
                            <Row>
                                {i18n.t<string>("financial_statements")}
                            </Row>
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="text"
                            className={classButtonSelected(ReportType.REVENUE)}
                            onClick={() => updateReportType(ReportType.REVENUE)}
                        >
                            <Row>
                                <Icon icon="tabler:report-money" />
                            </Row>
                            <Row>
                                {i18n.t<string>("profitability")}
                            </Row>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}