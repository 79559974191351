import { useUserContext } from "context/UserContext";
import { createContext, useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Formula, IReportRegistrationContext, ITableData, Report, ReportType } from "../IReportRegistration";

const NewReportContext: React.Context<IReportRegistrationContext> = createContext<IReportRegistrationContext>({} as IReportRegistrationContext);

export const NewReportProvider: React.FC = ({ children }) => {
    const [reportType, setReportType] = useState<ReportType>();
    const [report, setReport] = useState<Report>();
    const [selectedRowKey, setSelectedRowKey] = useState<number>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedRowFormula, setSelectedRowFormula] = useState<number>(0);
    const [isSavingReport, setIsSavingReport] = useState<boolean>(false);
    const [isNewProjection, setIsNewProjection] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState(true);
    const [tableData, setTableData] = useState<ITableData[]>([]);
    const [idReportForEdit, setIdReportForEdit] = useState(0);
    const [isEditingReport, setIsEditingReport] = useState(false);
    const [current, setCurrent] = useState(idReportForEdit ? -1 : 0);

    const {
        userInfo: {
            selection: { businessUnitId, organizationId },
        },
    } = useUserContext();

    useEffect(() => {
        getTableData();
    }, []);

    const getTableData = () => {
        setIsFetching(true);
        setSelectedRowKeys([]);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-report/report/list",
            },
            onLoadData
        );
    };

    function onLoadData(data: ITableData[]) {
        setTableData(data);
        setIsFetching(false);
    }

    const isPlanningReport = (type: ReportType = reportType): boolean => {
        return type === ReportType.PLANNING || type?.toString() === "PLANNING";
    };
    const resetReportType = () => setReportType(undefined);

    const onSaveReport = (reportSelected, description): Promise<Report> => {
        return new Promise((resolve) => {
            setIsSavingReport(true);
            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: "budget-report/report/createReport",
                    params: {
                        description,
                        organizationId,
                        businessUnitId,
                        reportType: reportSelected,
                    },
                },
                (data) => {
                    setReport(data);
                    setIsSavingReport(false);
                    resolve(data);
                }
            );
        });
    };

    return (
        <NewReportContext.Provider
            value={{
                reportType,
                setReportType,
                isPlanningReport,
                resetReportType,
                report,
                setReport,
                selectedRowKey,
                setSelectedRowKey,
                selectedRowKeys,
                setSelectedRowKeys,
                selectedRowFormula,
                setSelectedRowFormula,
                onSaveReport,
                isSavingReport,
                isNewProjection,
                setIsNewProjection,
                isFetching,
                setIsFetching,
                tableData,
                setTableData,
                idReportForEdit,
                setIdReportForEdit,
                isEditingReport,
                setIsEditingReport,
                current,
                setCurrent,
                getTableData,
            }}
        >
            {children}
        </NewReportContext.Provider>
    );
};

export const useNewReportContext = () => {
    const newReportContext = useContext(NewReportContext);
    return newReportContext;
};
