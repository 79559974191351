import { Col } from "antd";
import LineFilter from "../../../formula/LineFilter";
import OptionsList from "../../../formula/OptionsList";
import FormulaContent from "../../../formula/FormulaContent";

const FormulaWrapper = () => {
    return (
        <>
            <Col span={8}>
                <OptionsList />
            </Col>
            <Col span={16}>
                <div>
                    <LineFilter />
                    <FormulaContent />
                </div>
            </Col>
        </>
    );
};

export default FormulaWrapper;
