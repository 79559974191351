enum MessageType {

	REQUEST = "REQUEST",

	HEIGHT_CHANGE = "HEIGHT_CHANGE",

	USER_LOCALE = "USER_LOCALE",

	OPEN_INITIAL_TOUR = "OPEN_INITIAL_TOUR",

	REFRESH_SCREEN = "REFRESH_SCREEN",

	OPEN_MENU = "OPEN_MENU",

	CLOSE_MENU = "CLOSE_MENU",

	REDIRECT_TO = "REDIRECT_TO",

	REDIRECT_TO_BLANK = "REDIRECT_TO_BLANK",

	OPEN_USER_MENU = "OPEN_USER_MENU",

	CLOSE_USER_MENU = "CLOSE_USER_MENU",

	CHANGE_SCENERY = "CHANGE_SCENERY",

	CLOSE_FLOAT_MENU = "CLOSE_FLOAT_MENU",

	OPEN_COACHMARK = "OPEN_COACHMARK",

	OPEN_NOTIFICATION_MENU = "OPEN_NOTIFICATION_MENU",

	REFRESH_NOTIFICATION_COUNTER = "REFRESH_NOTIFICATION_COUNTER",

	OPEN_ABOUT_MODAL = "OPEN_ABOUT_MODAL",

	CLIENT_ID = "CLIENT_ID",

	HIDE_FLOAT_MENU = "HIDE_FLOAT_MENU",

	REQUEST_FILE = "REQUEST_FILE",

	REPLACE_URL = "REPLACE_URL",

	EXPORT_ACCOUNTING = "EXPORT_ACCOUNTING",

	EXPORT_CONTRACT_PROJECTION = "EXPORT_CONTRACT_PROJECTION",

	CHANGE_ENVIRONMENT_MODAL = "CHANGE_ENVIRONMENT_MODAL",

	CHANGE_STEP_MODAL = "CHANGE_STEP_MODAL",

	SET_LEASE_ENVIRONMENT = "SET_LEASE_ENVIRONMENT",

	EXPORT_DOCUMENTATION_IMPORT = "EXPORT_DOCUMENTATION_IMPORT",

	REQUEST_NPS = "REQUEST_NPS",
}

enum RequestType {

	GET = "GET",

	PUT = "PUT",

	POST = "POST",

	PATCH = "PATCH",

	DELETE = "DELETE"

}

enum ResponseStatus {

	SUCCESS = "SUCCESS",

	ERROR = "ERROR"

}

interface IErrorResponse {

	exception: string

	message: string

	path: string

	timestamp: string

}

interface IMessage {

	callerUID?: number

	messageType?: MessageType

	params?: any

}

interface IRequest extends IMessage {

	url: string

	getPermissions?: boolean

	type: RequestType

	params?: any

	useProxy?: boolean

	isFile?: boolean

}

interface ICallerResponseData {

	callerUID: number

	status?: ResponseStatus

	response: any

}

interface ICallerResponse {

	origin: string

	data: ICallerResponseData

}

export {

	IMessage,

	IRequest,

	MessageType,

	RequestType,

	ResponseStatus,

	IErrorResponse,

	ICallerResponse

};
