import { Button, Steps } from "antd";
import { ErrorFallback } from "components/error/ErrorFallback";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { RuleConditionOperations } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { stringToSignalType } from "../../components/constants";
import { useNewReportContext } from "../../context/ReportRegistrationContext";
import { FilterType, LevelFilter, LevelFormula, LevelReport, Report } from "../../IReportRegistration";

interface HeaderNewReportProps {
    handleSetIsOnNewReport: (isOnNewReport: boolean) => void;
}

export const HeaderNewReport: React.FC<HeaderNewReportProps> = (props) => {
    const {
        report,
        setReport,
        reportType,
        isPlanningReport,
        idReportForEdit,
        isEditingReport,
        setIdReportForEdit,
        setIsEditingReport,
        current,
        setCurrent,
    } = useNewReportContext();

    const isNextButtonDisabled = !report?.levels?.length || report?.description === "";
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        userInfo: {
            selection: { businessUnitId, organizationId },
        },
    } = useUserContext();
    const steps = [
        {
            title: i18n.t<string>("levels_structure"),
            stepNumber: 1,
            stepIndex: 0,
            disabled: false,
        },
        {
            title: i18n.t<string>("add_value_to_row"),
            stepNumber: 2,
            stepIndex: 1,
            disabled: isNextButtonDisabled,
        },
        {
            title: i18n.t<string>("report_summary"),
            stepNumber: 3,
            stepIndex: 2,
            disabled: isNextButtonDisabled,
        },
    ];

    function onClickBackStep() {
        if (current === 0) {
            setReport(null);
            onCancel();
        } else {
            setCurrent(current - 1);
        }
    }

    function onClickNextStep() {
        if (isLoading) return;
        setIsLoading(true);
        if (current === 0) {
            if (hasDuplicateExternalCode(report.levels)) {
                Notification({
                    message: i18n.t("line_code_cannot_repeat"),
                    type: "warning",
                });
                setIsLoading(false);
                return;
            }
            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: `budget-report/report/${isEditingReport || report.id ? "updateReport" : "createReport"}`,
                    params: {
                        ...report,
                        levels: report.levels.map((line) => {
                            return {
                                ...line,
                                subLevels: line.children,
                            };
                        }),
                        organizationId: organizationId,
                        businessUnitId: businessUnitId,
                        reportType,
                    },
                },
                onSaveLevels
            );
        } else if (current === 1) {
            setIsLoading(false);
            let formatedFormulas: LevelFormula[] = [];
            report.levels.forEach((level) => {
                if (level.children?.length) {
                    level.children.forEach((child) => {
                        formatedFormulas.push(child.levelFormula);
                    });
                } else {
                    formatedFormulas.push(level.levelFormula);
                }
            });

            if (formatedFormulas.length) {
                ServiceCaller.doRequest(
                    {
                        type: RequestType.POST,
                        url: `budget-report/report/${isEditingReport ? "updateFormulas" : "createFormulas"}`,
                        params: formatedFormulas,
                    },
                    null,
                    ErrorFallback
                );
            }

            let formatedFilters: LevelFilter[] = [];
            report.levels.forEach(({ levelFilter, children }) => {
                let filtersToReturn: LevelFilter[] = [];
                if (children?.length) {
                    children.forEach((child) => {
                        child.levelFilter.filters.forEach((filter) => {
                            filter.id = null;
                        });
                        filtersToReturn.push(child.levelFilter);
                    });
                } else if (levelFilter) {
                    levelFilter.filters?.forEach((filter) => {
                        filter.id = null;
                        if (isPlanningReport()) {
                            filter.filterType = FilterType.FROM_TO;
                            filter.operation = "FROM_TO" as RuleConditionOperations;
                        } else {
                            filter.filterType = FilterType.FLEX_FIELD;
                        }
                    });
                    filtersToReturn.push(levelFilter);
                }
                formatedFilters = [...formatedFilters.concat(filtersToReturn.filter((value) => value))];
            });

            if (formatedFilters.length > 0) {
                const isForEdit: boolean = !!(
                    formatedFilters?.some(({ filters }) => filters.some(({ id }) => id)) || idReportForEdit
                );
                ServiceCaller.doRequest(
                    {
                        type: RequestType.POST,
                        url: `budget-report/report/${isForEdit ? "updateFilters" : "createFilters"}`,
                        params: formatedFilters,
                    },
                    null,
                    ErrorFallback
                );
            }
            setCurrent(2);
        } else {
            setReport(null);
            onCancel();
            setIsLoading(false);
        }
    }

    function hasDuplicateExternalCode(levels: LevelReport[]): boolean {
        const externalCodeSet = new Set<string>();

        function verify({ externalCode, children }: LevelReport): boolean {
            if (externalCodeSet.has(externalCode)) {
                return true;
            }

            externalCodeSet.add(externalCode);

            for (const child of children || []) {
                if (verify(child)) {
                    return true;
                }
            }

            return false;
        }

        for (const root of levels) {
            if (verify(root)) {
                return true;
            }
        }

        return false;
    }

    function onCancel() {
        setIdReportForEdit(0);
        setIsEditingReport(false);
        props.handleSetIsOnNewReport(false);
    }

    function onSaveLevels(data: Report) {
        setIsLoading(false);
        setReport({
            ...data,
            levels: sortLevels(data.levels),
        });
        setCurrent(1);
    }

    function sortLevels(levels: LevelReport[]): LevelReport[] {
        return levels
            .map((line) => {
                if (line.subLevels.length) {
                    line.children = sortLevels(line.subLevels);
                }
                line.isSubLevel = line.upperLevelId ? true : false;
                line.levelFormula = {
                    belongsToId: line.id,
                    formulas: line.formulas,
                };
                line.levelStyle = { ...line.levelStyle, signalType: stringToSignalType[line.levelStyle.signalType] };
                line.isSubLevel = line.upperLevelId ? true : false;

                return { ...line, key: line.ordination };
            })
            .sort((a, b) => (a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1));
    }

    return (
        <>
            <div className="page-title-content">
                <h1>{i18n.t<string>("sales_report_registration")}</h1>
                <Steps
                    type="navigation"
                    size="small"
                    current={current}
                    onChange={(value) => setCurrent(value)}
                    className="site-navigation-steps"
                >
                    {steps.map(({ stepIndex, stepNumber, title, disabled }) => {
                        return (
                            <Steps.Step
                                disabled={disabled}
                                key={stepIndex}
                                title={title}
                                stepNumber={stepNumber}
                                stepIndex={stepIndex}
                            />
                        );
                    })}
                </Steps>
            </div>
            <div className="button-steps">
                <Button type="link" onClick={onClickBackStep}>
                    {i18n.t(current === 0 ? "cancel" : "back")}
                </Button>
                <Button
                    type="primary"
                    className="gs-main-button"
                    disabled={isNextButtonDisabled}
                    onClick={onClickNextStep}
                    loading={isLoading}
                >
                    {i18n.t(current + 1 < steps.length ? "next" : "conclude")}
                </Button>
            </div>
        </>
    );
};
